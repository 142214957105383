import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import './assets/custom.scss';
import mixin from './components/Mixin.vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import loading from './components/Loading.vue';
import moment from 'moment';


/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(faUserSecret)

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.component('loading', loading);
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
Vue.mixin(mixin);
Vue.use(VueAxios, axios);
Vue.prototype.$axios = axios;

Vue.config.productionTip = false;
Vue.filter('datefmt', function (val, format) {
    return moment(val).format(format);
});

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');
