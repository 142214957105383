<template>
    <div class="user">
        <div class="headerbg">
            <b-container>
                <h1>{{ title }}</h1>
            </b-container>
        </div>
        <b-container>
            <b-row>
                <b-col cols="12">
                    <nacUser></nacUser>
                </b-col>
                <b-col cols="12">
                    <b-card class="mb-3" title="基本資料">
                        <b-form>
                            <b-form-group id="old-password" label-for="AC_USERNAME">
                                <template #label> <b-badge variant="danger  mt-1 mr-2">必填</b-badge>姓名 </template>
                                <InputV1
                                    :type="form1.data['AC_USERNAME'].type"
                                    :data_in="form1.data['AC_USERNAME']"
                                ></InputV1>
                            </b-form-group>

                            <b-form-group id="old-password" label-for="AC_SEX">
                                <template #label> <b-badge variant="danger  mt-1 mr-2">必填</b-badge>性別 </template>

                                <InputV1 :type="form1.data['AC_SEX'].type" :data_in="form1.data['AC_SEX']"></InputV1>
                            </b-form-group>

                            <b-form-group id="old-password" label="身份證號" label-for="AC_IDNUM">
                                <InputV1
                                    :type="form1.data['AC_IDNUM'].type"
                                    :data_in="form1.data['AC_IDNUM']"
                                ></InputV1>
                            </b-form-group>

                            <b-form-group id="old-password" label-for="AC_BIRTHDAY">
                                <template #label>
                                    <b-badge variant="danger  mt-1 mr-2">必填</b-badge>出生日期
                                </template>
                                <InputV1
                                    :type="form1.data['AC_BIRTHDAY'].type"
                                    :data_in="form1.data['AC_BIRTHDAY']"
                                ></InputV1>
                            </b-form-group>

                            <b-form-group id="old-password" label-for="AC_TEL">
                                <template #label>
                                    <b-badge variant="danger  mt-1 mr-2">必填</b-badge>行動電話
                                </template>

                                <InputV1 :type="form1.data['AC_TEL'].type" :data_in="form1.data['AC_TEL']"></InputV1>
                            </b-form-group>

                            <b-form-group id="old-password" label="電子信箱" label-for="AC_EMAIL">
                                <InputV1
                                    :type="form1.data['AC_EMAIL'].type"
                                    :data_in="form1.data['AC_EMAIL']"
                                ></InputV1>
                            </b-form-group>

                            <b-form-group id="old-password" label-for="AC_ADDRESS">
                                <template #label>
                                    <b-badge variant="danger  mt-1 mr-2">必填</b-badge>通訊地址
                                </template>

                                <b-row>
                                    <b-col cols="3">
                                        <InputV1
                                            :type="form1.data['AC_CITYID'].type"
                                            :data_in="form1.data['AC_CITYID']"
                                        ></InputV1>
                                    </b-col>
                                    <b-col cols="3">
                                        <InputV1
                                            :type="form1.data['AC_TOWNID'].type"
                                            :data_in="form1.data['AC_TOWNID']"
                                        ></InputV1>
                                    </b-col>
                                    <b-col cols="6">
                                        <InputV1
                                            :type="form1.data['AC_ADDRESS'].type"
                                            :data_in="form1.data['AC_ADDRESS']"
                                        ></InputV1>
                                    </b-col>
                                </b-row>
                            </b-form-group>

                            <b-button variant="primary" @click="form1Save()">更新</b-button>
                        </b-form>
                    </b-card>
                    <b-card class="mb-3" title="密碼變更">
                        <b-form>
                            <b-form-group id="old-password" label-for="AC_PWD">
                                <template #label> <b-badge variant="danger  mt-1 mr-2">必填</b-badge>新密碼 </template>
                                <InputV1 :type="form2.data['AC_PWD'].type" :data_in="form2.data['AC_PWD']"></InputV1>
                            </b-form-group>

                            <b-form-group id="old-password" label-for="AC_PWD2">
                                <template #label>
                                    <b-badge variant="danger  mt-1 mr-2">必填</b-badge>確認新密碼
                                </template>
                                <InputV1 :type="form2.data['AC_PWD2'].type" :data_in="form2.data['AC_PWD2']"></InputV1>
                            </b-form-group>

                            <b-button variant="primary" @click="form2Save()">更新</b-button>
                        </b-form>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
    import nacUser from '@/components/nacUser.vue';
    import InputV1 from '../../components/InputV1.vue';
    export default {
        name: 'user-profile',
        components: {
            nacUser,
            InputV1,
        },
        data() {
            return {
                title: '帳號姓名',
                accountData: {},
                cityName: {},
                townName: {},
                form1: {
                    title: '基本資料',
                    data: {
                        AC_USERNAME: {
                            type: 'text',
                            id: 'AC_USERNAME',
                            val: '',
                            text: '請填寫姓名',
                            only: false,
                            msg: '',
                        },
                        AC_SEX: {
                            type: 'b-radio-group',
                            id: 'AC_SEX',
                            val: '',
                            text: '請填選性別',
                            only: false,
                            options: [
                                { text: '男', value: 0 },
                                { text: '女', value: 1 },
                            ],
                            msg: '',
                            show: false,
                        },
                        AC_IDNUM: {
                            type: 'text',
                            id: 'AC_IDNUM',
                            val: '',
                            text: '請填寫身分證號',
                            only: false,
                            msg: '',
                        },
                        AC_BIRTHDAY: {
                            type: 'date',
                            id: 'AC_BIRTHDAY',
                            val: '',
                            text: '請填寫出生日期',
                            only: false,
                            msg: '',
                        },
                        AC_TEL: { type: 'text', id: 'AC_TEL', val: '', text: '請填寫行動電話', only: false, msg: '' },
                        AC_EMAIL: { type: 'text', id: 'AC_EMAIL', val: '', text: '', only: true, msg: '' },
                        AC_ADDRESS: {
                            type: 'text',
                            id: 'AC_ADDRESS',
                            val: '',
                            text: '請填寫通訊地址',
                            only: false,
                            msg: '',
                        },
                        AC_CITYID: {
                            type: 'select2',
                            val: '',
                            text: '縣市',
                            only: false,
                            options: {},
                            msg: '',
                            show: true,
                        },
                        AC_TOWNID: {
                            type: 'select2',
                            val: '',
                            text: '鄉鎮',
                            only: false,
                            options: {},
                            msg: '',
                            show: true,
                        },
                    },
                },
                form2: {
                    title: '密碼變更',
                    data: {
                        AC_PWD: { type: 'password', id: 'AC_PWD', val: '', text: '請填寫新密碼', only: false, msg: '' },
                        AC_PWD2: {
                            type: 'password',
                            id: 'AC_PWD2',
                            val: '',
                            text: '請填寫確認新密碼',
                            only: false,
                            msg: '',
                        },
                    },
                },
            };
        },
        mounted() {
            if (this.IsNull(this._sessionData('TokenID')) == '') {
                alert('尚未登入');
                this._go('/');
            }
            this.FunctionToken(this.FunctionMyACCOUNT, {});
            this.FunctionToken(this.FunctionSelectCity, {});
            this.FunctionToken(this.FunctionSelectTown, {});
            this.formCheckSet();
        },
        methods: {
            formCheckSet() {
                for (let i in this.form1.data) {
                    let item = this.form1.data[i];
                    if (i == 'AC_CITYID') {
                        item.change = (data) => {
                            this.form1Check(i);
                            this.form1.data['AC_TOWNID'].val = '';
                            if (data.val == '') {
                                this.form1.data['AC_TOWNID'].options = {};
                            } else {
                                this.FunctionToken(this.FunctionSelectTown, {
                                    TW_CITY_ID: data.val,
                                });
                            }
                            this.form1Check('AC_TOWNID');
                        };
                    } else {
                        item.change = () => {
                            this.form1Check(i);
                        };
                    }
                }

                for (let i in this.form2.data) {
                    let item = this.form2.data[i];
                    item.change = () => {
                        this.form2Check(i);
                    };
                }
            },

            form1Clear() {
                for (let i in this.form1.data) {
                    let item = this.form1.data[i];
                    if (i == 'AC_SEX') {
                        item.val = 0;
                    } else {
                        item.val = '';
                    }
                    item.msg = '';
                }
            },
            form1Check(id) {
                let msg = '';
                if (id == 'AC_USERNAME') {
                    this.form1.data['AC_USERNAME'].msg = '';
                    if (this.IsNull(this.form1.data['AC_USERNAME'].val) == '') {
                        msg += '未填寫姓名\n';
                        this.form1.data['AC_USERNAME'].msg = {
                            type: false,
                            texts: ['未填寫姓名'],
                        };
                    }
                } else if (id == 'AC_SEX') {
                    this.form1.data['AC_SEX'].msg = '';
                    if (this.IsNull(this.form1.data['AC_SEX'].val) == '' && this.form1.data['AC_SEX'].val !== 0) {
                        msg += '未點選性別\n';
                        this.form1.data['AC_SEX'].msg = {
                            type: false,
                            texts: ['未點選性別'],
                        };
                    }
                } else if (id == 'AC_IDNUM') {
                    this.form1.data['AC_IDNUM'].msg = '';
                    if (this.IsNull(this.form1.data['AC_IDNUM'].val) == '') {
                        msg += '未填寫身分證號\n';
                        this.form1.data['AC_IDNUM'].msg = {
                            type: false,
                            texts: ['未填寫身分證號'],
                        };
                    }
                } else if (id == 'AC_BIRTHDAY') {
                    this.form1.data['AC_BIRTHDAY'].msg = '';
                    if (this.IsNull(this.form1.data['AC_BIRTHDAY'].val) == '') {
                        msg += '未填選出生日期\n';
                        this.form1.data['AC_BIRTHDAY'].msg = {
                            type: false,
                            texts: ['未填選出生日期'],
                        };
                    }
                } else if (id == 'AC_TEL') {
                    this.form1.data['AC_TEL'].msg = '';
                    if (this.IsNull(this.form1.data['AC_TEL'].val) == '') {
                        msg += '未填寫行動電話\n';
                        this.form1.data['AC_TEL'].msg = {
                            type: false,
                            texts: ['未填寫行動電話'],
                        };
                    } else if (this.VerifyModel()['phone'](this.form1.data['AC_TEL'].val) == false) {
                        msg += '行動電話格式不符\n';
                        this.form1.data['AC_TEL'].msg = {
                            type: false,
                            texts: ['行動電話格式不符'],
                        };
                    }
                } else if (id == 'AC_ADDRESS') {
                    this.form1.data['AC_ADDRESS'].msg = '';
                    if (this.IsNull(this.form1.data['AC_ADDRESS'].val) == '') {
                        msg += '未填寫通訊地址\n';
                        this.form1.data['AC_ADDRESS'].msg = {
                            type: false,
                            texts: ['未填寫通訊地址'],
                        };
                    }
                } else if (id == 'AC_CITYID') {
                    this.form1.data['AC_CITYID'].msg = '';
                    if (this.IsNull(this.form1.data['AC_CITYID'].val) == '') {
                        msg += '未填選縣市\n';
                        this.form1.data['AC_CITYID'].msg = {
                            type: false,
                            texts: ['未填選縣市'],
                        };
                    }
                } else if (id == 'AC_TOWNID') {
                    this.form1.data['AC_TOWNID'].msg = '';
                    if (this.IsNull(this.form1.data['AC_TOWNID'].val) == '') {
                        msg += '未填選鄉鎮\n';
                        this.form1.data['AC_TOWNID'].msg = {
                            type: false,
                            texts: ['未填選鄉鎮'],
                        };
                    }
                }
                return msg;
            },
            form1Save() {
                let msg = '';
                for (let i in this.form1.data) {
                    msg += this.form1Check(i);
                }
                if (msg != '') {
                    this.Clog(msg);
                    alert('尚有錯誤未修正');
                    return;
                }
                let json1 = {};
                json1['AC_USERNAME'] = this.form1.data['AC_USERNAME'].val;
                if (this.IsNull(this.form1.data['AC_SEX'].val) != '' || this.form1.data['AC_SEX'].val === 0) {
                    json1['AC_SEX'] = this.form1.data['AC_SEX'].val;
                }
                json1['AC_IDNUM'] = this.form1.data['AC_IDNUM'].val;
                json1['AC_BIRTHDAY'] = this.form1.data['AC_BIRTHDAY'].val;
                json1['AC_ADDRESS'] = this.form1.data['AC_ADDRESS'].val;
                json1['AC_TEL'] = this.form1.data['AC_TEL'].val;
                json1['AC_CITYID'] = this.form1.data['AC_CITYID'].val;
                json1['AC_TOWNID'] = this.form1.data['AC_TOWNID'].val;

                this.FunctionToken(this.FunctionUpdateAccountInfo, json1);
            },
            form2Clear() {
                for (let i in this.form2.data) {
                    let item = this.form2.data[i];
                    item.val = '';
                    item.msg = '';
                }
            },
            form2Check(id) {
                let msg = '';
                if (id == 'AC_PWD') {
                    this.form2.data['AC_PWD'].msg = '';
                    if (this.IsNull(this.form2.data['AC_PWD'].val) == '') {
                        msg += '未填寫新密碼\n';
                        this.form2.data['AC_PWD'].msg = {
                            type: false,
                            texts: ['未填寫新密碼'],
                        };
                    }
                } else if (id == 'AC_PWD2') {
                    this.form2.data['AC_PWD2'].msg = '';
                    if (this.IsNull(this.form2.data['AC_PWD2'].val) == '') {
                        msg += '未填寫確認新密碼\n';
                        this.form2.data['AC_PWD2'].msg = {
                            type: false,
                            texts: ['未填寫確認新密碼'],
                        };
                    }

                    if (msg == '') {
                        this.form2.data['AC_PWD2'].msg = '';
                        if (this.form2.data['AC_PWD'].val != this.form2.data['AC_PWD2'].val) {
                            msg += '新密碼與確認新密碼不符\n';
                            this.form2.data['AC_PWD2'].msg = {
                                type: false,
                                texts: ['新密碼與確認新密碼不符'],
                            };
                        }
                    }
                }

                return msg;
            },

            form2Save() {
                let msg = '';
                for (let i in this.form2.data) {
                    msg += this.form2Check(i);
                }
                if (msg != '') {
                    this.Clog(msg);
                    alert('尚有錯誤未修正');
                    return;
                }

                let json1 = {};
                json1['DecryptionPass'] = this.form2.data['AC_PWD'].val;
                this.FunctionToken(this.FunctionUpdateAccountPass, json1);
            },

            //=========================================================
            //api

            FunctionMyACCOUNT(data_in) {
                this.apiMyACCOUNT(data_in)
                    .then((res) => {
                        let json = JSON.parse(res.data);
                        if (json.Status) {
                            let data1 = JSON.parse(json.Data);
                            this.Clog(data1);
                            this.accountData = data1;
                            this._accountData(data1);
                            this.title = this.IsNull(this.accountData['AC_USERNAME']);
                            for (let i in this.form1.data) {
                                let item = this.form1.data[i];
                                if (this.IsNull(data1[i]) != '' || data1[i] === 0) {
                                    if (i == 'AC_BIRTHDAY') {
                                        item.val = this.Str_Replace(this.str2date3(data1[i]), '/', '-');
                                    } else if (i == 'AC_SEX') {
                                        item.val = data1[i];
                                    } else {
                                        item.val = this.IsNull(data1[i]);
                                    }
                                }
                            }
                        }
                    })
                    .catch((err) => {
                        this.Elog(err);
                    });
            },
            FunctionUpdateAccountInfo(data_in) {
                this.apiUpdateAccountInfo(data_in)
                    .then((res) => {
                        let json = JSON.parse(res.data);
                        if (json.Status) {
                            alert('基本資料更新成功');
                            this.FunctionToken(this.FunctionMyACCOUNT, {});
                        }
                    })
                    .catch((err) => {
                        this.Elog(err);
                    });
            },
            FunctionUpdateAccountPass(data_in) {
                this.apiUpdateAccountPass(data_in)
                    .then((res) => {
                        let json = JSON.parse(res.data);
                        if (json.Status) {
                            alert('密碼變更成功');
                            this._sessionData('TokenID', '');
                            this._go('/');
                        } else {
                            alert(json.Data);
                        }
                    })
                    .catch((err) => {
                        this.Elog(err);
                    });
            },
            FunctionSelectCity(data_in) {
                this.apiSelectCity(data_in)
                    .then((res) => {
                        let json = JSON.parse(res.data);
                        if (json.Status) {
                            let data1 = JSON.parse(json.Data);
                            this.Clog(data1);
                            let options = {};
                            for (let i in data1) {
                                let item = data1[i];
                                this.cityName[item.CT_CITY_ID] = item;
                                options[item.CT_CITY_ID] = {
                                    text: this.IsNull(item.CT_NAME),
                                    val: item.CT_CITY_ID,
                                };
                            }
                            this.form1.data['AC_CITYID'].options = options;
                        }
                    })
                    .catch((err) => {
                        this.Elog(err);
                    });
            },
            FunctionSelectTown(data_in) {
                this.apiSelectTown(data_in)
                    .then((res) => {
                        let json = JSON.parse(res.data);
                        if (json.Status) {
                            let data1 = JSON.parse(json.Data);
                            this.Clog(data1);
                            let options = {};
                            for (let i in data1) {
                                let item = data1[i];
                                this.townName[item.TW_TOWN_ID] = item;
                                options[item.TW_TOWN_ID] = {
                                    text: this.IsNull(item.TW_TOWN_NAME),
                                    val: item.TW_TOWN_ID,
                                };
                            }
                            this.form1.data['AC_TOWNID'].options = options;
                        }
                    })
                    .catch((err) => {
                        this.Elog(err);
                    });
            },
        },
    };
</script>
