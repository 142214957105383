<template>
    <div id="Cart">
        <div class="headerbg">
            <b-container>
                <h1>購物車列表</h1>
            </b-container>
        </div>
        <b-container v-if="Step == 1">
            <b-row class="mb-3 ">
                <b-col cols="12">
                    <b-card class="mb-3" title="">
                        <b-col cols="12" class="d-flex flex-wrap">
                            <b-col md="7">課程名稱</b-col>
                            <b-col md="2">數量</b-col>
                            <b-col md="3">價格</b-col>
                            <b-col cols="12">
                                <hr />
                            </b-col>
                        </b-col>
                        <b-col cols="12" v-for="(item, index) in List" :key="index" class="d-flex flex-wrap">
                            <b-col md="7">{{ item.ProductName }}</b-col>
                            <b-col md="2">{{ item.Quantity }}</b-col>
                            <b-col md="3">{{ item.DiscountPrice }}</b-col>
                            <b-col cols="12" md="12" v-if="index + 1 != List.length">
                                <hr />
                            </b-col>
                        </b-col>

                        <b-alert show v-if="List.length == 0" variant="primary">購物車目前沒有東西唷！</b-alert>
                    </b-card>
                </b-col>
            </b-row>
            <b-row v-if="List.length > 0">
                <b-col cols="12" class="d-flex justify-content-end mb-5">
                    <b-button variant="primary " @click="ToCheckOut">下一步</b-button>
                </b-col>
            </b-row>
        </b-container>
        <b-container v-if="Step == 2">
            <b-row class="mb-3 ">
                <b-col cols="12">
                    <b-card class="mb-3" title="">
                        <b-col cols="12" class="d-flex flex-wrap">
                            <b-col cols="7">課程名稱</b-col>
                            <!-- <b-col cols="3">上課時間</b-col> -->
                            <b-col cols="2">數量</b-col>
                            <b-col cols="2">價格</b-col>
                            <b-col cols="1">操作</b-col>
                            <b-col cols="12">
                                <hr />
                            </b-col>
                        </b-col>
                        <b-col
                            cols="12"
                            v-for="(item, index) in CkeckOutItem.CartDto"
                            :key="index"
                            class="d-flex flex-wrap"
                        >
                            <b-col cols="7">
                                {{ item.ProductName }}
                            </b-col>
                            <!-- <b-col cols="3">
                                {{ item.Start | datefmt('YYYY/MM/DD') }}~ {{ item.End | datefmt('YYYY/MM/DD') }}</b-col
                            > -->
                            <b-col cols="2">{{ item.Quantity }}</b-col>
                            <b-col cols="2">{{ item.DiscountPrice }}</b-col>
                            <b-col cols="1">
                                <b-icon-trash-fill
                                    variant="danger"
                                    class="btn"
                                    @click="DeleteCart(item.Id)"
                                ></b-icon-trash-fill>
                            </b-col>
                            <b-col cols="12" md="12" v-if="index + 1 != List.length">
                                <hr />
                            </b-col>
                        </b-col>

                        <b-col cols="12" style="height: 2px;background:#000" class="my-3"> </b-col>
                        <div v-if="CkeckOutItem.PromoteDto.length > 0">
                            <b-col cols="12" class="d-flex justify-content-end pr-5 flex-wrap">
                                <b-col md="12" class="text-right">原價：$ {{ CkeckOutItem.ProductPrice }} </b-col>
                            </b-col>
                            <b-col cols="12" style="height: 1px;background:#000" class="my-3"> </b-col>
                        </div>
                        <div v-if="CkeckOutItem.PromoteDto.length > 0">
                            <b-col
                                cols="12"
                                v-for="(item, index) in CkeckOutItem.PromoteDto"
                                :key="index"
                                class="d-flex flex-wrap  pr-5"
                            >
                                <b-col md="7">折扣活動：{{ item.PromotionName }}</b-col>
                                <!-- <b-col md="3">上課時間</b-col> -->
                                <b-col md="2"></b-col>
                                <b-col md="3" class=" text-right"
                                    >折扣金額：
                                    <span class=" text-danger " style="font-weight: 700;">
                                        {{ item.Discount }}</span
                                    ></b-col
                                >
                                <b-col cols="12" v-if="index + 1 < CkeckOutItem.PromoteDto.length">
                                    <hr />
                                </b-col>
                            </b-col>
                            <b-col cols="12" style="height: 2px;background:#000" class="my-3"> </b-col>
                        </div>
                        <b-col cols="12" class="d-flex justify-content-end">
                            <b-col md="12" class="text-right">總計：$ {{ CkeckOutItem.TotalPrice }} </b-col>
                        </b-col>
                    </b-card>
                </b-col>
            </b-row>
            <b-row class="mb-3 ">
                <b-col cols="12">
                    <b-card class="mb-3" title="">
                        <b-form>
                            <b-form-group>
                                <template #label> <b-badge variant="danger  mt-1 mr-2">必填</b-badge>姓名 </template>
                                <b-row>
                                    <b-col>
                                        <b-form-input
                                            id="input-1"
                                            v-model="CkeckOutItem.MemberInfo.FirstName"
                                            type="text"
                                            placeholder="姓"
                                            required
                                        ></b-form-input
                                    ></b-col>
                                    <b-col>
                                        <b-form-input
                                            id="input-1"
                                            v-model="CkeckOutItem.MemberInfo.LastName"
                                            type="text"
                                            placeholder="名"
                                            required
                                        ></b-form-input> </b-col
                                ></b-row>
                            </b-form-group>
                            <b-form-group>
                                <template #label>
                                    <b-badge variant="danger  mt-1 mr-2">必填 </b-badge>
                                    性別
                                </template>
                                <b-row>
                                    <b-col>
                                        <b-form-radio
                                            v-model="CkeckOutItem.MemberInfo.Gender"
                                            name="some-radios"
                                            value="0"
                                            >男</b-form-radio
                                        ></b-col
                                    >
                                    <b-col>
                                        <b-form-radio
                                            v-model="CkeckOutItem.MemberInfo.Gender"
                                            name="some-radios"
                                            value="1"
                                            >女</b-form-radio
                                        >
                                    </b-col></b-row
                                >
                            </b-form-group>
                            <b-form-group>
                                <template #label>
                                    <b-badge variant="danger  mt-1 mr-2">必填</b-badge>
                                    電話
                                </template>
                                <b-row>
                                    <b-col>
                                        <b-form-input
                                            id="input-1"
                                            v-model="CkeckOutItem.MemberInfo.Phone"
                                            placeholder="電話"
                                            required
                                        ></b-form-input>
                                    </b-col>
                                </b-row>
                            </b-form-group>
                            <b-form-group>
                                <template #label>
                                    <b-badge variant="danger  mt-1 mr-2">必填</b-badge>
                                    Email
                                </template>
                                <b-row>
                                    <b-col>
                                        <b-form-input
                                            id="input-1"
                                            v-model="CkeckOutItem.MemberInfo.Email"
                                            placeholder="Email"
                                            required
                                        ></b-form-input>
                                    </b-col>
                                </b-row>
                            </b-form-group>
                            <b-form-group>
                                <template #label>
                                    <b-badge variant="danger  mt-1 mr-2">必填</b-badge>
                                    地址
                                </template>
                                <b-row class="mb-3">
                                    <b-col>
                                        <b-form-select id="input-3" v-model="CkeckOutItem.MemberInfo.City" required>
                                            <b-form-select-option
                                                :value="item.Name"
                                                v-for="(item, index) in CityList"
                                                :key="index"
                                            >
                                                {{ item.Name }}
                                            </b-form-select-option></b-form-select
                                        >
                                    </b-col>
                                    <b-col>
                                        <b-form-select id="input-3" v-model="Town" required>
                                            <b-form-select-option
                                                :value="item.Name"
                                                v-for="(item, index) in TownList"
                                                :key="index"
                                            >
                                                {{ item.Name }}
                                            </b-form-select-option>
                                        </b-form-select>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12">
                                        <b-form-input
                                            id="input-1"
                                            v-model="CkeckOutItem.MemberInfo.Address"
                                            placeholder="地址"
                                            required
                                        ></b-form-input>
                                    </b-col>
                                </b-row>
                            </b-form-group>
                        </b-form>
                    </b-card>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" class="d-flex justify-content-end mb-5">
                    <b-button variant="primary " @click="ToOrder">訂單確定</b-button>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
    import {
        apiCheckMember,
        apiGetCart,
        apiToCheckOut,
        apiDeleteCart,
        // apiDictionary,
        apiDictionarySelector,
        apiToOrder,
        // apiGetMember,
        apiGetCityTown,
    } from '@/api/API.js';
    // import axios from 'axios';

    export default {
        name: 'Cart',

        data() {
            return {
                Step: 1,
                List: [],
                CkeckOutItem: {},
                CityList: [],
                City: '',
                TownList: [{ text: '請先選擇縣市', value: '' }],
                Town: '',
                PromoteList: '',
            };
        },

        computed: {},
        mounted() {
            apiCheckMember()
                .then(() => {
                    this.Init();
                })
                .catch((err) => {
                    alert('尚未登入');
                    console.log(err);
                    this._go('/');
                });
            this.modal_init(this.modal_set);
            let navBgSwitch = this._storeData('navBgSwitch');
            if (this.IsNull(navBgSwitch) != '') {
                navBgSwitch(false);
            }
            // this.sessionF1();
        },
        watch: {
            'CkeckOutItem.MemberInfo.City'(newVal) {
                this.Town = '';
                var id = this.CityList.filter((item) => item.text == newVal)[0].Id;
                apiDictionarySelector(id)
                    .then((res) => {
                        this.TownList = res.data;
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
        },
        methods: {
            Init() {
                this.Step = 1;
                apiGetCityTown({
                    Paging: {
                        pageSize: 999,
                        pageNumber: 1,
                        totalCount: 0,
                        totalPages: 0,
                        previousPage: true,
                        nextPage: true,
                    },
                    Search: {
                        type: 'city',
                    },
                })
                    .then((res) => {
                        this.CityList = res.data;
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                apiGetCityTown({
                    Paging: {
                        pageSize: 999,
                        pageNumber: 1,
                        totalCount: 0,
                        totalPages: 0,
                        previousPage: true,
                        nextPage: true,
                    },
                    Search: {
                        type: 'town',
                    },
                })
                    .then((res) => {
                        this.TownList = res.data;
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                // apiDictionary('address', 'city');

                // apiDictionary('address', 'town')
                //     .then((res) => {
                //         this.TownList = res.data;
                //     })
                //     .catch((err) => {
                //         console.log(err);
                //     });
                apiGetCart()
                    .then((res) => {
                        this.List = [];
                        this.List = res.data[0].CartDto;
                        // this.CkeckOutItem = res.data[0];
                        // apiGetMember()
                        //     .then((res) => {
                        //         this.CkeckOutItem.MemberInfo = {
                        //             UserId: res.data.UserId,
                        //             FirstName: res.data.User.FirstName,
                        //             LastName: res.data.User.LastName,
                        //             EnglishName: res.data.User.NickName,
                        //             Gender: res.data.Gender,
                        //             Phone: res.data.Phone,
                        //             Address: res.data.Address,
                        //             Email: res.data.Email,
                        //             City: res.data.City,
                        //             Town: res.data.Town,
                        //         };
                        //     })
                        //     .catch((err) => {
                        //         console.log(err);
                        //     });
                        console.log(this.CkeckOutItem);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            async ToCheckOut() {
                apiToCheckOut(this.List[0].ProductDep)
                    .then(async (res) => {
                        this.CkeckOutItem = res.data;
                        this.Town = await this.TownList.filter((item) => {
                            return item.Name == this.CkeckOutItem.MemberInfo.Town;
                        });
                        this.Town = this.Town[0].Name;
                        this.Step = 2;
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            DeleteCart(id) {
                var yes = confirm('確定移除此項目？');
                if (yes) {
                    apiDeleteCart(id)
                        .then(() => {
                            this.CkeckOutItem = {};
                            alert('刪除成功');
                            apiToCheckOut(this.List[0].ProductDep)
                                .then((res) => {
                                    if (this.CkeckOutItem.CartDto.length == 0) {
                                        this.Init();
                                    } else {
                                        this.CkeckOutItem = res.data;
                                    }
                                })
                                .catch((err) => {
                                    console.log(err);
                                    this.Init();
                                });
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }
            },
            ToOrder() {
                this.CkeckOutItem.MemberInfo.Town = this.Town;
                apiToOrder(this.CkeckOutItem)
                    .then((res) => {
                        console.log(res);
                        alert('加入訂單成功');
                        this.Init();
                        this._go('/order');
                    })
                    .catch((err) => {
                        alert(err.response.data);
                        console.log(err);
                    });
            },
        },
    };
</script>
<style lang="scss">
    #Cart {
        .custom-control-label {
            width: 100% !important;
        }
    }
</style>
