<script>
    //import API from "../api/API";
    // import axios from 'axios';
    export default {
        name: 'Mixin',
        data() {
            return {
                //userContent:null,
                userSignInData: {
                    user_account: '',
                    user_pwd: '',
                },
                modal: {},
                checkModel: {
                    email(val) {
                        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        return re.test(String(val).toLowerCase());
                    },
                    userId(val) {
                        const re = /^[a-zA-Z][0-9]{9}$/;
                        return re.test(String(val).toUpperCase());
                    },
                },
                OnlyData: {
                    CAT: [{ CAT_ID: 'CATyyun5zLWMESnHqlKQ6GUew', CAT_NAME: '全部' }],
                    ID: [
                        { ID_ID: 'IDTbz4MbLkeTqWjQClnGQ', ID_NAME: '在校生', Identity: 1 },
                        { ID_ID: 'IDO72yWi2fEO8SRQvn4JdUg', ID_NAME: '教職員', Identity: 2 },
                        { ID_ID: 'IDWPsOEoc6ek7XrPMjNsnFA', ID_NAME: '校友', Identity: 3 },
                        { ID_ID: 'ID61DF3BXvy0SqAduFLHmBQ', ID_NAME: '舊生', Identity: -1 },
                    ],
                },
                LocaPath: {
                    href: 'javascript:void(0)',
                    // default: './#/',
                    default: './',
                },
                loadSet: {
                    show: false,
                    fullPage: true,
                    type: 'dots',
                    color: 'rgba(0,0,0,0.7)',
                    showIf: 0,
                    showNum: 0,
                    if_show(th1) {
                        if (th1.showIf == 0) {
                            return;
                        }
                        th1.show = true;
                        th1.showNum += 1;
                        if (th1.showIf == th1.showNum) {
                            th1.showIf = 0;
                            th1.showNum = 0;
                            th1.show = false;
                        }
                    },
                    if_set(th1, If) {
                        th1.showIf = If;
                        th1.showNum = 0;
                        th1.show = false;
                        return th1;
                    },
                    if_err(th1) {
                        th1.showIf = 0;
                        th1.showNum = 0;
                        th1.show = false;
                    },
                },
                weekDATA: ['日', '一', '二', '三', '四', '五', '六'],
                userInfo: null,
                TokenID: '',
                Token: '',
                SignUp_url: 'https://extension.fcu.edu.tw/FcuPay/FcuPay.aspx',
                // userRequest: this.$axios.create({ baseURL: "../api" }), // 本機端
                userRequest: this.$axios.create({
                    // 正式
                    baseURL: '/API/',
                    // 測試機
                    // baseURL: '/',
                }),
                apiToken: (data) => this.userRequest.post('API/Default/Token', data),
                apiAddRegistration: (data) => this.userRequest.post('API/Default/AddRegistration', data),
                apiGetCourseFeriodList: (data) => this.userRequest.post('API/Default/GetCourseFeriodList', data),
                apiGetCategoryListByIndex: (data) => this.userRequest.post('API/Default/GetCategoryListByIndex', data),
                apiGetArchive: (data) => this.userRequest.post('API/Default/GetArchive', data),
                apiGetCourseFeriodListByIndex: (data) =>
                    this.userRequest.post('API/Default/GetCourseFeriodListByIndex', data),
                apiGetImageCode: (data) => this.userRequest.post('API/Default/GetImageCode', data),
                apiACCOUNTInsertByIndex: (data) => this.userRequest.post('API/Default/ACCOUNTInsertByIndex', data),
                apiLogin: (data) => this.userRequest.post('API/Default/Login', data),
                apiRegistrationSingle: (data) => this.userRequest.post('API/Default/RegistrationSingle', data),
                apiMyACCOUNT: (data) => this.userRequest.post('API/Default/MyACCOUNT', data),
                apiGetStudentPaymentList: (data) => this.userRequest.post('API/Default/GetStudentPaymentList', data),
                apiGetHashtgList: (data) => this.userRequest.post('API/Default/GetHashtgList', data),
                apiRegistrationAll: (data) => this.userRequest.post('API/Default/RegistrationAll', data),
                apiUpdateRegistration: (data) => this.userRequest.post('API/Default/UpdateRegistration', data),
                apiGetCourseFeriodSingleByIndex: (data) =>
                    this.userRequest.post('API/Default/GetCourseFeriodSingleByIndex', data),
                apiGetNewsIndex: (data) => this.userRequest.post('API/Default/GetNewsIndex', data),
                apiCancelRegistration: (data) => this.userRequest.post('API/Default/CancelRegistration', data),
                apiGetNewsSingle: (data) => this.userRequest.post('API/Default/GetNewsSingle', data),
                apiGetListMainPholo: (data) => this.userRequest.post('API/Default/GetListMainPholo', data),
                apiGetListSeries: (data) => this.userRequest.post('API/Default/GetListSeries', data),
                apiGetListSeriesCourse: (data) => this.userRequest.post('API/Default/GetListSeriesCourse', data),
                apiGetListSeriesCourseIndex: (data) =>
                    this.userRequest.post('API/Default/GetListSeriesCourseIndex', data),
                apiGetMail: (data) => this.userRequest.post('API/Default/GetMail', data),
                apiMailLogin: (data) => this.userRequest.post('API/Default/MailLogin', data),
                apiMailLogin2: (data) => this.userRequest.post('API/Default/MailLogin2', data),
                apiLastPass: (data) => this.userRequest.post('API/Default/LastPass', data),
                apiCheckPassToken: (data) => this.userRequest.post('API/Default/CheckPassToken', data),
                apiUpdateLastPass: (data) => this.userRequest.post('API/Default/UpdateLastPass', data),
                apiUpdateAccountInfo: (data) => this.userRequest.post('API/Default/UpdateAccountInfo', data),
                apiUpdateAccountPass: (data) => this.userRequest.post('API/Default/UpdateAccountPass', data),
                apiDelectRegistration: (data) => this.userRequest.post('API/Default/DelectRegistration', data),
                apiSelectCity: (data) => this.userRequest.post('API/Default/SelectCity', data),
                apiSelectTown: (data) => this.userRequest.post('API/Default/SelectTown', data),
                apiDropDownForm: (data) => this.userRequest.post('API/Default/DropDownForm', data),
                apiUpdateStudentPayment: (data) => this.userRequest.post('API/Default/UpdateStudentPayment', data),
                apiCheckFcuAuth: (data) => this.userRequest.post('API/Default/CheckFcuAuth', data),
                apiGetCourseFeriodListByIndex_mini: (data) =>
                    this.userRequest.post('API/Default/GetCourseFeriodListByIndex_mini', data),
                apiGetListSeriesCourseIndex_mini: (data) =>
                    this.userRequest.post('API/Default/GetListSeriesCourseIndex_mini', data),
            };
        },
        created() {
            // this.getTokenID();
            // this.getToken();
        },
        mounted() {
            //this.userLogin();
            // this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
            // if (localStorage.getItem("TokenID") != "") {
            //   this.TokenID = localStorage.getItem("TokenID");
            // }
        },
        computed: {
            userData() {
                return this.$store.state.userData;
            },
        },
        methods: {
            FunctionToken: function(EXfunction, data_in) {
                this.apiToken()
                    .then((res) => {
                        // data_in.TokenID = this.TokenID;
                        let TokenID = this._sessionData('TokenID');
                        let TokenID2 =
                            'A9207nM9qUOQMqKmaFNxmwdLAxc3WN+W54GRH9EIzjVMNT1kuJoXLzXOnqhYmRu32KRMzdcbanDLYGjTNfEOcw==';
                        if (this.IsNull(TokenID) == '') {
                            data_in.TokenID = TokenID2;
                        } else {
                            data_in.TokenID = TokenID;
                        }
                        data_in.Token = res.data;
                        data_in.Page = '';
                        EXfunction(data_in);
                    })
                    .catch((error) => {
                        // alert('發生錯誤!' + error);
                        console.log(error);
                    });
            },
            // GoToPathIndex() {
            //   this.$router.push({ path: "/farmland-management" });
            // },
            // ROCDate(type) {
            //   var today = new Date(type);

            //   return (
            //     today.getFullYear() -
            //     1911 +
            //     "-" +
            //     (today.getMonth() + 1) +
            //     "-" +
            //     today.getDate()
            //   );
            // },
            // getNowYear() {
            //   var today = new Date();
            //   return today.getFullYear() - 1911;
            // },
            GoToIndex() {
                this.$router.push({ path: '/' });
            },
            getTokenID() {
                this.$axios
                    .post('API/Default/Token')
                    .then((res) => {
                        this.TokenID = res.data;
                        console.log(res.data);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            },
            //=====================================================
            //await
            async awaitFunList(list_fun) {
                /*
                [
                    {type:'',fun:this.Clog,data_in:'aaa',data:'',if_fun:''},
                    {type:'await',fun:this.FunctionToken4,data_in:{},data:'',if_fun:''},
                    {type:'',fun:this.Clog,data_in:'bbb',data:'',if_fun:''},
                ]
                */
                for (let i in list_fun) {
                    let item = list_fun[i];
                    if (this.IsNull(item.if_fun) != '') {
                        if (item.if_fun(list_fun) != true) {
                            continue;
                        }
                    }
                    item.data_in.TokenID = this.IsNull(this.TokenID);
                    item.data_in.Token = this.IsNull(this.Token);
                    item.data_in.Page = this.IsNull(this.page);
                    if (item.type == 'await') {
                        item.data = await this.awaitFun(item);
                    } else {
                        item.data = item.fun(item.data_in);
                    }
                }
            },
            async awaitFun(item) {
                return new Promise((resolve) => {
                    item.fun(item.data_in, resolve);
                });
            },
            FunctionToken3: function(data_in, resolve) {
                this.apiToken()
                    .then((res) => {
                        let TokenID = this._sessionData('TokenID');
                        let TokenID2 =
                            'A9207nM9qUOQMqKmaFNxmwdLAxc3WN+W54GRH9EIzjVMNT1kuJoXLzXOnqhYmRu32KRMzdcbanDLYGjTNfEOcw==';
                        if (this.IsNull(TokenID) == '') {
                            this.TokenID = TokenID2;
                        } else {
                            this.TokenID = TokenID;
                        }
                        this.Token = res.data;
                        this.Page = '';
                        if (this.IsNull(resolve) != '') {
                            resolve('ok');
                        }
                    })
                    .catch((error) => {
                        console.log('發生錯誤!' + error);
                        this.loadSet.if_err(this.loadSet);
                    });
            },

            //=====================================================
            _sessionData(key, val = undefined) {
                if (val == undefined) {
                    return localStorage.getItem(key);
                } else if (val == '') {
                    localStorage.removeItem(key);
                } else {
                    localStorage.setItem(key, val);
                }
            },
            IsNull(data, err = '') {
                if (data == '' || data == null || data == undefined) {
                    return err;
                } else {
                    return data;
                }
            },
            IsNull2(data, err = '') {
                if (data === '' || data === null || data === undefined) {
                    return err;
                } else {
                    return data;
                }
            },
            _Vupdate(type = 'w') {
                if (type == 'w') {
                    this._watcher.run();
                } else if (type == 'f') {
                    this.$forceUpdate();
                }
            },
            getToken() {
                this.$axios
                    .post('API/Default/Token')
                    .then((res) => {
                        this.Token = '8g/ib6VPKgPF2HywezfhHV/B+Vn9+38yXTKX9bJqPXo=';
                        console.log(res.data);
                    })
                    .catch((error) => {
                        this.Token = '8g/ib6VPKgPF2HywezfhHV/B+Vn9+38yXTKX9bJqPXo=';
                        console.log(error);
                    });
            },
            //==================================================================
            modal_init(modals) {
                let th1 = this;
                let modal = {};
                for (let i in modals) {
                    let item = modals[i];
                    modal[item.name] = {
                        show: function() {
                            th1.$bvModal.show(item.id);
                        },
                        hide: function() {
                            th1.$bvModal.hide(item.id);
                        },
                    };
                }
                this.modal = modal;
            },
            hasSlot(name = 'default') {
                return !!this.$slots[name] || !!this.$scopedSlots[name];
            },
            Str_Replace(str, if1, if2) {
                return (str + '').replace(new RegExp(if1, 'g'), if2);
            },
            Str_Replace2(str, list1) {
                let out1 = str + '';
                list1.forEach((x) => {
                    out1 = out1.replace(new RegExp(x['old'], 'g'), x['new']);
                });
                return out1;
            },
            StrCap(str1, n1, n2) {
                if (n2 < 0) {
                    return str1.substr(n1, str1.length + n2);
                } else {
                    return str1.substr(n1, n2);
                }
            },
            str2date(str) {
                let str1 = this.Str_Replace(this.Str_Replace(this.Str_Replace(str, ' ', ''), '/', ''), '-', '');
                return this.StrCap(str1, 0, 4) + '/' + this.StrCap(str1, 4, 2) + '/' + this.StrCap(str1, 6, 2);
            },
            str2date2(str) {
                let str1 = this.Str_Replace(this.Str_Replace(this.Str_Replace(str, ' ', ''), '/', ''), '-', '');
                if (str1.length == 6) {
                    return (
                        Number(this.StrCap(str1, 0, 2)) +
                        1911 +
                        '/' +
                        this.StrCap(str1, 2, 2) +
                        '/' +
                        this.StrCap(str1, 4, 2)
                    );
                } else {
                    return (
                        Number(this.StrCap(str1, 0, 3)) +
                        1911 +
                        '/' +
                        this.StrCap(str1, 3, 2) +
                        '/' +
                        this.StrCap(str1, 5, 2)
                    );
                }
            },
            str2date3(str) {
                // 民國轉西元
                let str1 = this.Str_Replace(this.Str_Replace(this.Str_Replace(str, ' ', ''), '/', ''), '-', '');
                if (str1.length >= 8) {
                    return this.str2date(str1);
                } else if (str1.length >= 6) {
                    return this.str2date2(str1);
                } else {
                    return '';
                }
            },
            date2str(date) {
                //西元轉民國

                return [
                    date.getFullYear(),
                    date.getMonth() + 1 > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1),
                    date.getDate() > 9 ? date.getDate() : '0' + date.getDate(),
                ];
            },
            date2str2(date) {
                return {
                    date: [
                        date.getFullYear(),
                        date.getMonth() + 1 > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1),
                        date.getDate() > 9 ? date.getDate() : '0' + date.getDate(),
                    ],
                    time: [
                        date.getHours() > 9 ? date.getHours() : '0' + date.getHours(),
                        date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes(),
                        date.getSeconds() > 9 ? date.getSeconds() : '0' + date.getSeconds(),
                    ],
                };
            },
            DeepCopy(data, if1 = 0) {
                if (if1 == 0) {
                    return JSON.parse(JSON.stringify(data));
                }
                /*
            else if(if1==1){
                return $.extend(true, {}, data);;
            }
            */
            },
            _sleep(fun, time) {
                setTimeout(fun, time);
            },
            _imgRead(e, fun) {
                let d1 = new FileReader();
                d1.readAsDataURL(e.target.files[0]);
                d1.onloadend = function() {
                    fun(this.result);
                };
            },
            json2Fdata(json) {
                let Fdata = new FormData();
                for (let i in json) {
                    Fdata.append(i, this.IsNull(json[i]));
                }
                return Fdata;
            },
            Clog(data) {
                //console.log(data)
                return data;
            },
            Elog(data) {
                console.log(data);
            },
            IsNum(data) {
                if (isNaN(Number(data))) {
                    return 0;
                } else {
                    return Number(data);
                }
            },
            IsNullKeys(data, keys, err = '') {
                let val = data;
                for (let i in keys) {
                    let key = keys[i];
                    if (this.IsNull(val[key]) == '') {
                        return err;
                    } else {
                        val = val[key];
                    }
                }
                return val;
            },
            InRange(data_in) {
                /*
            {
              min:'',
              max:'',
              val:'',
            }
            */
                let if1 = 0;
                if (this.IsNull(data_in.min) == '' || data_in.val >= data_in.min) {
                    if1 += 1;
                }
                if (this.IsNull(data_in.max) == '' || data_in.val <= data_in.max) {
                    if1 += 1;
                }
                return if1 == 2;
            },
            CourseIf(data) {
                let dates = {
                    curr: Date.parse(new Date().toDateString()),
                    begin: Date.parse(data.CA_BEGIN_TIME).valueOf(),
                    end: Date.parse(data.CA_END_TIME).valueOf(),
                    beginSignin: Date.parse(data.CA_BEGIN_DATE).valueOf(),
                    endSignin: Date.parse(data.CA_END_DATE).valueOf(),
                };
                data.courseStatus = [];
                data.courseType = [];
                data.canSignin = false;

                if (data.CA_IS_ENTRUS == 1) {
                    data.courseType.push('委訓合作');
                }

                if (data.CS_IS_CREDIT == 1) {
                    data.courseType.push('學分班課程');
                } else {
                    data.courseType.push('非學分班課程');
                }
                //-----------------------------------------------------------------
                if (dates['curr'] < dates['begin'] && data.CA_OPEN == '0') {
                    // 未達開課時間、未開放報名
                    data.courseStatus.push('規劃中');
                } else if (dates['curr'] < dates['begin']) {
                    // 未達開課時間
                    if (data.CA_IS_END_DATE && dates['curr'] > dates['endSignin']) {
                        data.courseStatus.push('已截止');
                    } else {
                        // 課程未開始招生中
                        if (data.CA_IS_BEGIN_DATE && dates['curr'] > dates['beginSignin']) {
                            data.courseStatus.push('招生中');
                            data.canSignin = true;
                        } else if (data.CA_IS_BEGIN_DATE && dates['curr'] < dates['beginSignin']) {
                            data.courseStatus.push('規劃中');
                        } else {
                            data.courseStatus.push('招生中');
                            data.canSignin = true;
                        }
                    }
                    //早鳥訊息
                    if (this.IsNull(data.CA_IS_EARLY) && dates['curr'] < data.CA_EARLY_END_DATE) {
                        data.courseStatus.push('早鳥優惠');
                    }
                    //已成班
                    if (this.IsNull(data.CA_IS_DEFINITE)) {
                        data.courseStatus.push('已成班');
                    }
                } else if (dates['curr'] > dates['begin'] && dates['curr'] < dates['end']) {
                    // 課程進行中
                    data.courseStatus.push('開課中');
                    // 仍可報名
                    if (this.IsNull(data.CA_EXTEND)) {
                        data.courseStatus.push('仍可報名');
                        data.canSignin = true;
                    }
                } else {
                    data.courseStatus.push('已結束');
                }
            },

            CourseIf2(data) {
                let dates = {
                    curr: Number(new Date()), //目前時間(毫秒)
                    begin: Date.parse(data.CA_BEGIN_TIME).valueOf(), //課程開始時間(毫秒)
                    end: Date.parse(data.CA_END_TIME).valueOf(), //課程結束時間(毫秒)
                    beginSignin: Date.parse(data.CA_BEGIN_DATE).valueOf(), //報名開始時間(毫秒)
                    endSignin: Date.parse(data.CA_END_DATE).valueOf(), //報名結束時間(毫秒)
                    earlyend: Date.parse(data.CA_EARLY_END_DATE).valueOf(), //早鳥優惠截止時間(毫秒)
                    is_beginSignin: data.CA_IS_BEGIN_DATE, //是否啟用報名開始時間(0,1)
                    is_endSignin: data.CA_IS_END_DATE, //是否啟用報名結束時間(0,1)
                    is_beginend: data.CA_EXTEND, //是否開課後仍可報名(0,1)
                    is_open: data.CA_OPEN, //是否開放報名(0,1)
                };

                let ReDate = (date) => {
                    let date1 = this.date2str(date);
                    return Number(new Date(date1.join('/') + ' 00:00:00'));
                };

                ['begin', 'end', 'beginSignin', 'endSignin', 'earlyend'].forEach((x) => {
                    dates[x] = ReDate(new Date(dates[x]));
                });

                data.courseStatus = [];
                data.courseType = [];
                data.canSignin = false;

                if (data.CA_IS_ENTRUS == 1) {
                    data.courseType.push('委訓班');
                }

                if (data.CS_IS_CREDIT == 1) {
                    data.courseType.push('學分班課程');
                }
                //else{
                //data.courseType.push("非學分班課程");
                //}
                //-----------------------------------------------------------------
                if (dates['is_open'] == false) {
                    if (this.InRange({ max: dates['begin'], val: dates['curr'] })) {
                        data.courseStatus.push('規劃中');
                    } else if (this.InRange({ min: dates['end'], val: dates['curr'] })) {
                        data.courseStatus.push('已結束');
                    }
                } else if (this.InRange({ min: dates['end'], val: dates['curr'] })) {
                    data.courseStatus.push('已結束');
                } else {
                    //=========================================================================================
                    if (dates['is_beginend'] && dates['is_beginSignin'] && dates['is_endSignin']) {
                        if (this.InRange({ max: dates['beginSignin'], val: dates['curr'] })) {
                            data.courseStatus.push('規劃中');
                        } else if (this.InRange({ min: dates['beginSignin'], max: dates['end'], val: dates['curr'] })) {
                            if (this.InRange({ min: dates['beginSignin'], max: dates['begin'], val: dates['curr'] })) {
                                data.courseStatus.push('招生中');
                                data.canSignin = true;
                            } else {
                                data.courseStatus.push('仍可報名');
                                data.canSignin = true;
                            }
                        } else {
                            data.courseStatus.push('已結束');
                        }
                        //=========================================================================================
                    } else if (dates['is_beginend'] && dates['is_beginSignin'] && dates['is_endSignin'] == false) {
                        if (this.InRange({ max: dates['beginSignin'], val: dates['curr'] })) {
                            data.courseStatus.push('規劃中');
                        } else if (this.InRange({ min: dates['beginSignin'], max: dates['end'], val: dates['curr'] })) {
                            if (this.InRange({ min: dates['beginSignin'], max: dates['begin'], val: dates['curr'] })) {
                                data.courseStatus.push('招生中');
                                data.canSignin = true;
                            } else {
                                data.courseStatus.push('仍可報名');
                                data.canSignin = true;
                            }
                        } else {
                            data.courseStatus.push('已結束');
                        }
                        //=========================================================================================
                    } else if (dates['is_beginend'] && dates['is_beginSignin'] == false && dates['is_endSignin']) {
                        if (this.InRange({ max: dates['endSignin'], val: dates['curr'] })) {
                            data.courseStatus.push('招生中');
                            data.canSignin = true;
                        } else if (this.InRange({ min: dates['endSignin'], max: dates['end'], val: dates['curr'] })) {
                            if (this.InRange({ min: dates['endSignin'], max: dates['begin'], val: dates['curr'] })) {
                                data.courseStatus.push('招生中');
                                data.canSignin = true;
                            } else {
                                data.courseStatus.push('仍可報名');
                                data.canSignin = true;
                            }
                        } else {
                            data.courseStatus.push('已結束');
                        }
                        //=========================================================================================
                    } else if (
                        dates['is_beginend'] &&
                        dates['is_beginSignin'] == false &&
                        dates['is_endSignin'] == false
                    ) {
                        if (this.InRange({ max: dates['begin'], val: dates['curr'] })) {
                            data.courseStatus.push('招生中');
                            data.canSignin = true;
                        } else {
                            data.courseStatus.push('已截止');
                        }
                        //=========================================================================================
                    } else if (dates['is_beginend'] == false && dates['is_beginSignin'] && dates['is_endSignin']) {
                        if (this.InRange({ max: dates['beginSignin'], val: dates['curr'] })) {
                            data.courseStatus.push('規劃中');
                        } else if (
                            this.InRange({ min: dates['beginSignin'], max: dates['endSignin'], val: dates['curr'] })
                        ) {
                            data.courseStatus.push('招生中');
                            data.canSignin = true;
                        } else {
                            data.courseStatus.push('已截止');
                        }
                        //=========================================================================================
                    } else if (
                        dates['is_beginend'] == false &&
                        dates['is_beginSignin'] &&
                        dates['is_endSignin'] == false
                    ) {
                        if (this.InRange({ max: dates['beginSignin'], val: dates['curr'] })) {
                            data.courseStatus.push('規劃中');
                        } else if (
                            this.InRange({ min: dates['beginSignin'], max: dates['begin'], val: dates['curr'] })
                        ) {
                            data.courseStatus.push('招生中');
                            data.canSignin = true;
                        } else {
                            data.courseStatus.push('已截止');
                        }
                        //=========================================================================================
                    } else if (
                        dates['is_beginend'] == false &&
                        dates['is_beginSignin'] == false &&
                        dates['is_endSignin']
                    ) {
                        if (this.InRange({ max: dates['endSignin'], val: dates['curr'] })) {
                            data.courseStatus.push('招生中');
                            data.canSignin = true;
                        } else {
                            data.courseStatus.push('已截止');
                        }
                        //=========================================================================================
                    } else if (
                        dates['is_beginend'] == false &&
                        dates['is_beginSignin'] == false &&
                        dates['is_endSignin'] == false
                    ) {
                        if (this.InRange({ max: dates['begin'], val: dates['curr'] })) {
                            data.courseStatus.push('招生中');
                            data.canSignin = true;
                        } else {
                            data.courseStatus.push('已截止');
                        }
                    }
                    //=========================================================================================
                }

                if (this.InRange({ min: dates['begin'], max: dates['end'], val: dates['curr'] })) {
                    data.courseStatus.push('開課中');
                }
                if (this.InRange({ max: dates['begin'], val: dates['curr'] })) {
                    if (this.IsNull(data.CA_IS_EARLY) && this.InRange({ max: dates['earlyend'], val: dates['curr'] })) {
                        data.courseStatus.push('早鳥優惠');
                    }
                }
                if (this.InRange({ max: dates['begin'], val: dates['curr'] }) && this.IsNull(data.CA_IS_DEFINITE)) {
                    data.courseStatus.push('已成班');
                }

                if (this.IsNull(data.CA_ISNEW) == '1') {
                    data.courseStatus.push('最新課程');
                }
                if (this.IsNull(data.CA_ISNOT) == '1') {
                    data.courseStatus.push('熱門課程');
                }
            },

            VerifyModel() {
                let out = {};
                out['num'] = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
                out['str1'] = [
                    'a',
                    'b',
                    'c',
                    'd',
                    'e',
                    'f',
                    'g',
                    'h',
                    'i',
                    'j',
                    'k',
                    'l',
                    'm',
                    'n',
                    'o',
                    'p',
                    'q',
                    'r',
                    's',
                    't',
                    'u',
                    'v',
                    'w',
                    'x',
                    'y',
                    'z',
                ];
                out['str2'] = [];
                for (let i in out['str1']) {
                    out['str2'].push(out['str1'][i].toUpperCase());
                }

                out['email'] = (val) => {
                    if (this.IsNull(val) == '' || val.length < 3 || val.length > 255) {
                        return false;
                    }
                    if ([].concat(out['num'], out['str1'], out['str2']).indexOf(val[0]) == -1) {
                        return false;
                    }
                    if (val.indexOf('@') == -1 || val.split('').filter((x) => x == '@').length != 1) {
                        return false;
                    }

                    let vals = [this.StrCap(val, 0, val.indexOf('@')), this.StrCap(val, val.indexOf('@') + 1, -1)];
                    if (vals[0].length > 64 || vals[1].split('.').length < 2) {
                        return false;
                    }
                    for (let i in vals[0]) {
                        let item = vals[0][i];
                        let if1 = [].concat(out['num'], out['str1'], out['str2'], ['_', '.']);
                        if (if1.indexOf(item) == -1) {
                            return false;
                        }
                    }
                    for (let i in vals[1]) {
                        let item = vals[1][i];
                        let if1 = [].concat(out['num'], out['str1'], out['str2'], ['-', '.']);
                        if (if1.indexOf(item) == -1) {
                            return false;
                        }
                    }
                    return true;
                };

                out['phone'] = (val) => {
                    val = (val + '').split('');
                    let val2 = val.filter((x) => out['num'].indexOf(x) != -1);
                    return val2.length == 10;
                };

                out['tel'] = (val) => {
                    val = (val + '').split('');
                    let val2 = val.filter((x) => out['num'].indexOf(x) != -1);
                    if (val2.length > 10 || val2.length < 8) {
                        return false;
                    }
                    return true;
                };

                out['nid'] = (val) => {
                    if (val.length != 10) {
                        return false;
                    }
                    val = val.toUpperCase();
                    let vals = [val[0], this.StrCap(val, 1, -1)];
                    if (out['str2'].indexOf(vals[0]) == -1) {
                        return false;
                    }
                    for (let i in vals[1]) {
                        let item = vals[1][i];
                        if (out['num'].indexOf(item) == -1) {
                            return false;
                        }
                    }

                    let nidName = {
                        A: 10,
                        B: 11,
                        C: 12,
                        D: 13,
                        E: 14,
                        F: 15,
                        G: 16,
                        H: 17,
                        I: 34,
                        J: 18,
                        K: 19,
                        L: 20,
                        M: 21,
                        N: 22,
                        O: 35,
                        P: 23,
                        Q: 24,
                        R: 25,
                        S: 26,
                        T: 27,
                        U: 28,
                        V: 29,
                        W: 32,
                        X: 30,
                        Y: 31,
                        Z: 33,
                    };
                    let nidNum = [1, 9, 8, 7, 6, 5, 4, 3, 2, 1, 1];
                    let val2 = [].concat((nidName[vals[0]] + '').split(''), (vals[1] + '').split(''));

                    let sum = 0;
                    for (let i in val2) {
                        sum += Number(val2[i]) * nidNum[i];
                    }

                    return sum % 10 == 0;
                };

                return out;
            },
            //==================================================================
            _go(url) {
                if (isNaN(url)) {
                    this.$router.push(url);
                } else {
                    this.$router.go(url);
                }
            },
            _go1(url, if1 = 0, name = '') {
                if (if1 == 0) {
                    window.location.href = url;
                } else if (if1 == 1) {
                    window.open(url);
                } else if (if1 == 2) {
                    window.open(url, name, 'height=768,width=1024');
                } else if (if1 == 3) {
                    if (this.windowOpen != '') {
                        this.windowOpen.opener = null;
                        this.windowOpen.close();
                    }
                    this.windowOpen = window.open(url, 'test', 'height=768,width=1024');
                }
            },
            _go2(json, type = 'params') {
                /*
            {
                name:'test1',
                path:'/test1',
                data:{},
                key:'www',
            }
            */
                if (type == 'params') {
                    //post(可傳函數)
                    if (this.IsNull(json.key) != '') {
                        return this.$route.params[json.key];
                    } else {
                        this.$router.push({
                            name: json.name,
                            params: json.data,
                        });
                    }
                } else if (type == 'query') {
                    //get
                    if (this.IsNull(json.key) != '') {
                        return this.$route.query[json.key];
                    } else {
                        this.$router.push({
                            path: json.path,
                            query: json.data,
                        });
                    }
                }
            },
            Str_Blank(str) {
                return this.Str_Replace(this.Str_Replace(this.Str_Replace(str, ' ', ''), '\t', ''), '\r', '');
            },
            _listdel(list, index) {
                list.splice(index, 1);
            },
            date2enday(date) {
                return new Date(date.getFullYear(), date.getMonth() + 1, 0);
            },
            _random(json) {
                /* 亂數排序
                {min:'',max:'',type:''}
                */
                let list1 = [];
                for (let i = json['min']; i <= json['max']; i++) {
                    list1.push(i);
                }
                if (list1.length == 0 || list1.length == 1) {
                    return list1;
                }
                if (this.IsNull(json['type']) == '' || json['type'] == 's') {
                    list1.sort(() => {
                        return Math.random() - 0.5;
                    });
                } else if (json['type'] == 'r') {
                    for (let i = list1.length - 1; i > 0; i--) {
                        let j = Math.floor(Math.random() * (i + 1));
                        [list1[i], list1[j]] = [list1[j], list1[i]];
                    }
                }
                return list1;
            },

            _Base64(type, val) {
                if (this.IsNull(val) == '') {
                    return '';
                }
                if (type == 'str') {
                    return btoa(val);
                } else if (type == 'base64') {
                    return atob(val);
                }
            },
            //==================================================================

            _localData(key, val = undefined) {
                if (val == undefined) {
                    return localStorage.getItem(key);
                } else if (val == '') {
                    localStorage.removeItem(key);
                } else {
                    localStorage.setItem(key, val);
                }
            },
            //==============================================
            //store
            _accountData(data = undefined) {
                if (data == undefined) {
                    return this.$store.state.accountData;
                } else {
                    this.$store.commit('setaccountData', data);
                }
            },
            _storeData(key, val = undefined) {
                let data1 = this.$store.state.datas;
                if (val == undefined) {
                    return this.IsNull(data1[key]);
                } else if (val == '') {
                    delete data1[key];
                } else {
                    data1[key] = val;
                    this.$store.commit('_datas', data1);
                }
            },
        },
    };
</script>
