<template>
    <div class="user">
        <div class="headerbg">
            <b-container>
                <h1>{{ title }}</h1>
            </b-container>
        </div>
        <b-container>
            <b-row>
                <b-col cols="12">
                    <nacUser></nacUser>
                </b-col>
                <b-col cols="12">
                    <b-card class="mb-3" title="訂單管理">
                        <div class="responsive">
                            <BTableV1 :TablePage="TablePage" :cell="['Create', 'tool']">
                                <template #Create="{row}">
                                    {{ row.item.Create | datefmt('YYYY-MM-DD HH:mm:ss') }}
                                </template>

                                <template #tool="{row}">
                                    <b-button variant="primary" @click="TableGo(row.item.OrderNo)" size="sm"
                                        >詳細資料</b-button
                                    >
                                </template>
                            </BTableV1>
                        </div>
                        <template v-if="TablePage.items.length > 0">
                            <div class="overflow-auto mt-5">
                                <b-pagination
                                    align="center"
                                    v-model="Param.Paging.pageNumber"
                                    :total-rows="rows"
                                    :per-page="Param.Paging.pageSize"
                                ></b-pagination>
                            </div>
                        </template>
                        <!--
               <b-table striped hover :items="items" :fields="fields">
                  <template #cell(regist_05)= 'data'>
                     <b-link to="course" target="_blank">{{data.item.regist_05}}</b-link>
                  </template>
                  <template #cell(regist_03)= 'data'>
                     <b-link to="myRegistrationDetailed" target="_blank"> {{data.item.regist_03}}</b-link>
                  </template>
                  <template #cell(operation)= 'data'>
                     <b-button variant="primary" to="myRegistrationDetailed" size="sm">詳細資料</b-button>
                     <b-button v-if="data.item.regist_06 == '未付款'" variant="success" size="sm" class="ml-1">付款</b-button>
                     <b-button v-if="data.item.regist_04 != '已取消'" variant="danger" size="sm" class="ml-1">取消</b-button>
                  </template>
               </b-table>
               <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" first-text="第一頁"
                  prev-text="上一頁" next-text="下一頁" last-text="最後一頁" align="center" pills></b-pagination>
               -->
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
    import nacUser from '@/components/nacUser.vue';
    import BTableV1 from '../../components/BTableV1.vue';
    import { apiOrderList } from '@/api/API.js';
    export default {
        name: 'user-signin',
        components: {
            nacUser,
            BTableV1,
        },
        data() {
            return {
                Param: {
                    Paging: {
                        pageNumber: 1,
                        pageSize: 10,
                    },
                    Search: {},
                },
                pageHeader: '',

                title: '帳號姓名',
                TablePage: {
                    title: '',
                    class: '',
                    BT_style: { striped: true, hover: true },
                    PG_style: {
                        pills: true,
                        align: 'center',
                        'first-text': '第一頁',
                        'prev-text': '上一頁',
                        'next-text': '下一頁',
                        'last-text': '最後一頁',
                    },
                    itemsAll: [],
                    items: [],
                    id: 'Table1',
                    currentPage: 1,
                    rows: 100,
                    page: 10,
                    sortBy: '',
                    sortDesc: false,
                    sortDirection: 'asc',
                    filter: null,
                    filterOn: [],
                    fields: [
                        { key: 'Create', label: '訂單日期' },
                        { key: 'OrderNo', label: '訂單編號', sortable: false },
                        { key: 'TotalPrice', label: '訂單總額', sortable: false },
                        { key: 'tool', label: '操作', sortable: false },
                    ],

                    TIndex(th1, index) {
                        return (th1.currentPage - 1) * th1.page + index;
                    },
                    clear(th1) {
                        th1.itemsAll = [];
                        th1.items = [];
                        th1.rows = 0;
                        th1.currentPage = 1;
                    },
                    set(th1, data) {
                        th1.itemsAll = JSON.parse(JSON.stringify(data));
                        th1.items = JSON.parse(JSON.stringify(data));
                        th1.rows = data.length;
                    },
                    set2(th1, data) {
                        th1.items = JSON.parse(JSON.stringify(data));
                        th1.rows = data.length;
                        th1.currentPage = 1;
                    },
                    set3(th1, data) {
                        th1.items = JSON.parse(JSON.stringify(data));
                        th1.rows = data.length;
                        th1.currentPage = 1;
                    },
                },
            };
        },
        watch: {
            'Param.Paging.pageNumber'(newVal) {
                this.FunctionRegistrationSingle(newVal);
            },
        },
        mounted() {
            if (this.IsNull(this._sessionData('TokenID')) == '') {
                alert('尚未登入');
                this._go('/');
            }
            this.FunctionToken(this.FunctionMyACCOUNT, {});

            this.FunctionToken(this.FunctionRegistrationSingle(1), {});
        },
        methods: {
            TableSet() {
                let data1 = [
                    {
                        regist_01: '2020/05/05',
                        regist_03: 'A1234567890-1',
                        regist_04: '未報名',
                        regist_05: '無人機實務飛行特訓班',
                        regist_06: '未付款',
                    },
                    {
                        regist_01: '2020/05/05',
                        regist_03: 'A1234567890-1',
                        regist_04: '已報名',
                        regist_05: '無人機實務飛行特訓班',
                        regist_06: '已付款',
                    },
                    {
                        regist_01: '2020/05/05',
                        regist_03: 'A1234567890-1',
                        regist_04: '已取消',
                        regist_05: '無人機實務飛行特訓班',
                        regist_06: '已付款',
                    },
                    {
                        regist_01: '2020/05/05',
                        regist_03: 'A1234567890-1',
                        regist_04: '已取消',
                        regist_05: '無人機實務飛行特訓班',
                        regist_06: '已退款',
                    },
                ];
                this.TablePage.set(this.TablePage, data1);
            },
            sessionF1(url, data) {
                if (url == 'myRegistrationDetailed') {
                    this._sessionData(url, JSON.stringify(data));
                    this._go(url);
                } else if (url == 'course') {
                    let url = this.LocaPath.default + 'course?CP_ID={CP_ID}';
                    this._go1(this.Str_Replace(url, '{CP_ID}', data), 1);
                }
            },
            TableGo(data) {
                this.$store.commit('setOrderNo', data);

                this._go('myRegistration');
                // this.sessionF1('myRegistrationDetailed', data);
            },
            TableGo2(data) {
                if (this.IsNull(data.RF_ID) == '') {
                    return;
                }
                if (
                    (data.PAY_STATUS !== 0 && data.PAY_STATUS !== 3) ||
                    data.RF_STATUS !== 0 ||
                    data.RF_IS_GROUP !== 0 ||
                    data.PAY_TYPE !== 0
                ) {
                    return;
                }
                let url = this.SignUp_url + '?RF_ID=';
                this._go1(url + data.RF_ID);
            },
            TableGo3(data) {
                this.sessionF1('course', data.CP_ID);
            },
            TableDel(data) {
                this.Clog(data);
                if (data.RF_STATUS !== 0 || (data.PAY_STATUS !== 0 && data.PAY_STATUS !== 3)) {
                    return;
                }
                if (confirm('確定要取消報名?') == false) {
                    return;
                }
                this.FunctionToken(this.FunctionCancelRegistration, {
                    RF_CP_ID: data.RF_CP_ID,
                    RF_GROUP_ID: data.RF_GROUP_ID,
                    RF_STATUS: '2',
                });
            },

            //===============================================================
            //api
            FunctionRegistrationSingle(val) {
                this.TablePage.items = [];
                this.loadSet.show = true;
                this.Param.Paging.pageNumber = val;
                apiOrderList(this.Param)
                    .then((res) => {
                        this.TablePage.items = res.data;
                        this.pageHeader = JSON.parse(res.headers['list-header']).Paging;
                        this.rows = this.pageHeader.totalCount;
                        this.loadSet.show = false;
                    })
                    .catch((err) => {
                        this.loadSet.show = false;
                        console.log(err.response);
                    });
                // this.apiRegistrationSingle(data_in)
                // .then(res=>{
                //    let json=JSON.parse(res.data)
                //    if(json.Status){
                //       let data1=JSON.parse(json.Data)
                //       this.Clog(data1)
                //       if(data1.length!=0){
                //          for(let i in data1){
                //             let item=data1[i]
                //             if(item.list.length==0){
                //                item.RF_ID=''
                //             }else{
                //                item.RF_ID=item.list[0].RF_ID
                //             }
                //          }
                //          let th1=this
                //          data1.sort((a,b)=>{
                //             return -1*(Number(new Date(th1.str2date3(a.RF_DATE)))-Number(new Date(th1.str2date3(b.RF_DATE))))
                //          })

                //          this.TablePage.set(this.TablePage,data1)
                //          let cps=[]
                //          let pays=[]
                //          for(let i in data1){
                //             cps.push(data1[i].RF_CP_ID)
                //             pays.push(data1[i].RF_PAY_ID)
                //          }
                //          this.FunctionToken(this.FunctionGetCourseFeriodList,{
                //             TYPE:'BATH',
                //             CP_IDS:cps
                //          })

                //          this.FunctionToken(this.FunctionGetStudentPaymentList,{
                //             TYPE:'BATH',
                //             PAY_IDS:pays
                //          })

                //       }
                //    }
                // })
                // .catch(err=>{
                //    this.Elog(err)
                // })
            },
            FunctionGetCourseFeriodList(data_in) {
                this.apiGetCourseFeriodList(data_in)
                    .then((res) => {
                        let json = JSON.parse(res.data);

                        if (json.Status) {
                            let data1 = JSON.parse(json.Data);
                            this.Clog(data1);
                            for (let i in data1) {
                                let data2 = this.TablePage.items.filter((x) => x.RF_CP_ID == data1[i].CP_ID);
                                for (let j in data2) {
                                    for (let k in data1[i]) {
                                        data2[j][k] = data1[i][k];
                                    }
                                }
                            }
                            this.TablePage.set(this.TablePage, this.TablePage.items);
                        }
                    })
                    .catch((err) => {
                        this.Elog(err);
                    });
            },
            FunctionGetStudentPaymentList(data_in) {
                this.apiGetStudentPaymentList(data_in)
                    .then((res) => {
                        let json = JSON.parse(res.data);
                        if (json.Status) {
                            let data1 = JSON.parse(json.Data);
                            this.Clog(data1);
                            for (let i in data1) {
                                let data2 = this.TablePage.items.filter((x) => x.RF_PAY_ID == data1[i].PAY_ID);
                                for (let j in data2) {
                                    for (let k in data1[i]) {
                                        data2[j][k] = data1[i][k];
                                    }
                                }
                            }
                            this.TablePage.set(this.TablePage, this.TablePage.items);
                        }
                    })
                    .catch((err) => {
                        this.Elog(err);
                    });
            },
            FunctionCancelRegistration(data_in) {
                this.apiCancelRegistration(data_in)
                    .then((res) => {
                        let json = JSON.parse(res.data);
                        if (json.Status) {
                            this.FunctionToken(this.FunctionRegistrationSingle(1), {});
                        }
                    })
                    .catch((err) => {
                        this.Elog(err);
                    });
            },
            FunctionMyACCOUNT(data_in) {
                this.apiMyACCOUNT(data_in)
                    .then((res) => {
                        let json = JSON.parse(res.data);
                        if (json.Status) {
                            let data1 = JSON.parse(json.Data);
                            this.Clog(data1);
                            this.accountData = data1;
                            this._accountData(data1);
                            this.title = this.IsNull(this.accountData['AC_USERNAME']);
                        }
                    })
                    .catch((err) => {
                        this.Elog(err);
                    });
            },
        },
    };
</script>
