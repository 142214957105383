<template>
    <div>
        <b-container class="course">
            <b-link class="back" to="courses"
                ><b-icon-arrow-left-circle-fill></b-icon-arrow-left-circle-fill> 返回列表</b-link
            >
            <h1 class="title">
                {{ course.Title }}
            </h1>
            <div class="state2">
                <b-badge class="mr-1" v-for="(item, index) in course.Tags" :key="index">{{ item }}</b-badge>
            </div>
            <b-row class="mb-3">
                <b-col order="2" order-md="1">
                    <div class="course-attr border-bottom">
                        <b-row align-v="stretch">
                            <b-col>
                                <div class="attr-box ">
                                    <span class="title">上課地點</span>
                                    <div class="attr">
                                        {{ course.ClassRoomTitle }}
                                        <br />
                                        {{ course.ClassRoomName }}
                                    </div>
                                </div>
                            </b-col>
                            <b-col>
                                <div class="attr-box ">
                                    <span class="title">上課日期</span>
                                    <div class="attr time" v-if="course.Start !== ''">
                                        {{ course.Start | datefmt('YYYY/MM/DD') }}
                                        <br />～<br />
                                        {{ course.End | datefmt('YYYY/MM/DD') }}
                                    </div>
                                    <div class="attr time" v-else>
                                        無課程時間
                                    </div>
                                </div>
                            </b-col>
                            <b-col v-if="course.IsCredit">
                                <div class="attr-box ">
                                    <span class="title">學分數</span>
                                    <div class="attr">
                                        <span>
                                            <span class="number">{{ course.Credit }} </span>學分
                                        </span>
                                    </div>
                                </div>
                            </b-col>

                            <b-col>
                                <div class="attr-box ">
                                    <span class="title">招生名額</span>
                                    <div class="attr">
                                        <span>
                                            剩餘<span class="number">{{ course.Inventory }} </span>人
                                        </span>
                                    </div>
                                </div>
                            </b-col>
                            <b-col>
                                <div class="attr-box ">
                                    <span class="title">上課時數</span>
                                    <div class="attr">
                                        <span>
                                            <span class="number">{{ course.TotalHours }} </span>小時
                                        </span>
                                    </div>
                                </div>
                            </b-col>
                            <b-col>
                                <div class="attr-box ">
                                    <span class="title">上課時間</span>
                                    <div class="attr time">
                                        {{ course.TimeMemo }}
                                    </div>
                                </div>
                            </b-col>
                        </b-row>
                    </div>

                    <div class="bg-white border-bottom p-3 p-md-5" v-if="IsNull(course.Description)">
                        <h3 class="title">課程介紹</h3>
                        <div style="overflow: hidden;" v-html="IsNull(course.Description)"></div>
                    </div>

                    <div class="bg-white border-bottom p-3 p-md-5" v-if="IsNull(course.ClassSchedules)">
                        <h3 class="title mb-4"><span>課程大綱</span></h3>
                        <ul>
                            <li class="mb-2" v-for="(curriculum, index) in course.ClassSchedules" :key="index">
                                {{ curriculum.Start | datefmt('YYYY-MM-DD') }}
                                {{ curriculum.Start | datefmt('HH:mm') }} ~ {{ curriculum.End | datefmt('HH:mm') }}
                                <div v-html="curriculum.Description"></div>
                            </li>
                        </ul>
                    </div>

                    <div class="bg-white border-bottom p-3 p-md-5">
                        <h3 class="title">適合對象</h3>
                        <div v-html="course.Suitable"></div>
                    </div>

                    <div class="bg-white border-bottom p-3 p-md-5">
                        <h3 class="title">報名資格</h3>
                        <div v-html="course.Notice"></div>
                    </div>
                </b-col>
                <b-col class="mb-3" order="1" order-md="2" cols="auto">
                    <div class="external-signin">
                        <div class="signup shadow-sm">
                            <b-img
                                v-if="course.Cover != ''"
                                :src="IsNull(this.$store.state.FilePath + '/files' + course.Cover)"
                                fluid
                                style="width:400px"
                                class="shadow-sm rounded-lg"
                            ></b-img>
                            <b-img
                                v-else
                                src="./asset/no-image.png"
                                fluid
                                style="width:400px"
                                class="shadow-sm rounded-lg"
                            ></b-img>
                            <div class="signup-body">
                                <p class="text-danger">
                                    報名日期：<br />
                                    {{ course.ApplyStart | datefmt('YYYY/MM/DD') }}
                                    ~
                                    {{ course.ApplyEnd | datefmt('YYYY/MM/DD') }}
                                </p>
                                <ul class="list-unstyled">
                                    <li>
                                        <span class="text-black-50">課程費用</span>
                                        <span class="price">
                                            NT$<span class="number">{{ course.Price }}</span></span
                                        ><br />
                                        <span class="text-black-50">
                                            <span v-if="course.EarlyBird">早鳥</span>
                                            優惠價</span
                                        >
                                        <span class="price">
                                            NT$<span class="number">{{ course.Price }}</span></span
                                        >
                                    </li>
                                </ul>
                                <div v-show="CheckDate(course)">
                                    <b-button variant="primary" class="px-5" size="lg" block @click="SignUp()"
                                        >我要報名</b-button
                                    >
                                </div>

                                <b-alert
                                    :show="CheckDate(course) == false"
                                    variant="secondary"
                                    class="text-center"
                                    style="font-weight: bold;"
                                    >新班次規劃中</b-alert
                                >
                            </div>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
    import { apiCheckMember, apiAddCart, apiGetCourse } from '@/api/API.js';
    import moment from 'moment';

    export default {
        name: 'Course',

        data() {
            return {
                Id: '',
                //

                course: {},
            };
        },

        computed: {},

        mounted() {
            // this.modal_init(this.modal_set);
            // let navBgSwitch = this._storeData('navBgSwitch');
            // if (this.IsNull(navBgSwitch) != '') {
            //     navBgSwitch(false);
            // }
            // this.sessionF1();
            this.Init();
        },
        methods: {
            AddCart() {
                apiAddCart(this.course.ProductId, {})
                    .then((res) => {
                        console.log(res);
                        alert('成功加入購物車');
                    })
                    .catch((err) => {
                        alert(err.response.data);
                        console.log(err);
                    });
            },
            Init() {
                this.Id = this.$route.query.Id;
                apiGetCourse(this.Id)
                    .then((res) => {
                        this.course = res.data;
                        this.CheckDate(this.course);
                    })
                    .catch((err) => {
                        console.log(err);
                        // alert('查無此課程');
                        // this.$router.push('/');
                    });
            },
            CheckDate(data) {
                var Now = moment(new Date());
                var Start = moment(data.ApplyStart);
                var End = moment(data.ApplyEnd);
                return (Now.isAfter(Start) || Now.isSame(Start)) && (Now.isBefore(End) || Now.isSame(End));
            },

            SignUp() {
                apiCheckMember()
                    .then(() => {
                        this.AddCart();
                    })
                    .catch((err) => {
                        let fun1 = this._storeData('LoginF1');
                        // this._storeData('LoginFUN', '');
                        console.log(err);
                        fun1();
                    });
            },
        },
    };
</script>
