<template>
    <div class="page">
        <header>
            <b-container>
                <!-- <div class="sec-title">
                    <b-row class="pb-2" style="padding-top:80px" align-v="center">
                        <b-col cols="auto">
                            <h4 class="mb-0" v-for="(item, index) in CategoryList" :key="index">{{ item.text }}</h4>
                        </b-col>
                        <b-col cols="auto">
                            <b-button @click="cFilter = !cFilter"> <b-icon icon="filter"></b-icon> 過濾</b-button>
                        </b-col>
                    </b-row>
                </div> -->
                <div class=" d-block mb-5">
                    <b-row align-v="center" v-show="filter2['categories'].show">
                        <b-col cols="12" md="9">
                            <b-nav pills class="categories atcourses">
                                <b-nav-item
                                    class="catalog"
                                    v-for="(item, index) in CategoryList"
                                    :key="index"
                                    @click="CategoryClick(item)"
                                    :active="CategoryId == item.Id"
                                >
                                    <div
                                        class="icon"
                                        :style="{
                                            'background-image':
                                                'url(' +
                                                $store.state.WebSite +
                                                'files/_category/' +
                                                item.Id +
                                                '/cover.png' +
                                                ')',
                                        }"
                                    ></div>
                                    <span style="display:block">{{ item.text }}</span>
                                </b-nav-item>
                            </b-nav>
                        </b-col>
                        <b-col class="d-none d-sm-block" md="3">
                            <b-img
                                :src="$store.state.WebSite + 'files/_category/' + CategoryId + '/cover.png'"
                                fluid
                            ></b-img>
                        </b-col>
                    </b-row>
                </div>
            </b-container>
        </header>
        <div class="box image2 ">
            <b-container>
                <b-row class="justify-content-center">
                    <b-col cols="auto">
                        <h4 class="mb-4" style="font-weight: bold;" @click="filter2.show = !filter2.show">進階搜尋</h4>
                    </b-col>
                </b-row>

                <b-row class="mb-2">
                    <b-col cols="12" class="mb-1" md="auto">課程標籤</b-col>
                    <b-col>
                        <b-form-group class="filterItem">
                            <b-form-checkbox-group
                                @change="filterF1()"
                                v-model="Tags"
                                :options="TagList"
                                name="buttons-1"
                                button-variant="outline-primary"
                                buttons
                            ></b-form-checkbox-group>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row class="mb-2">
                    <b-col cols="12" class="mb-1" md="auto">課程類型</b-col>
                    <b-col>
                        <b-form-group class="filterItem">
                            <b-form-checkbox-group
                                @change="filterF1()"
                                v-model="Type"
                                name="buttons-1"
                                button-variant="outline-primary"
                                buttons
                            >
                                <b-form-checkbox value="Credit">學分班課程</b-form-checkbox>
                                <b-form-checkbox value="Support">委訓班</b-form-checkbox>
                            </b-form-checkbox-group>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row class="mb-2">
                    <b-col cols="12" class="mb-1" md="auto">課程狀態</b-col>
                    <b-col>
                        <b-form-group class="filterItem">
                            <b-form-checkbox-group
                                @change="filterF1()"
                                v-model="State"
                                name="buttons-1"
                                button-variant="outline-primary"
                                buttons
                            >
                                <b-form-checkbox value="EnableApply">開放報名中</b-form-checkbox>
                                <b-form-checkbox value="new">最新課程</b-form-checkbox>
                                <b-form-checkbox value="hot">熱門課程</b-form-checkbox>
                            </b-form-checkbox-group>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-container>
        </div>

        <!--*********************************************************************************-->
        <b-container class="py-3">
            <b-form-group class="mb-0">
                <b-input-group class="course-search">
                    <b-form-input
                        id="input-1"
                        v-model="keywords"
                        type="text"
                        placeholder="想學什麼？"
                        required
                    ></b-form-input>
                </b-input-group>
            </b-form-group>
        </b-container>
        <b-container class="pt-3 pt-md-5">
            <template v-if="Courses.load == false">
                <div class="loading">
                    <b-icon icon="three-dots" animation="cylon" font-scale="2" variant="white"></b-icon>
                </div>
            </template>

            <template v-else-if="Courses.data.length == 0">
                <b-alert variant="warning" class="text-center" show>您查詢的類別或關鍵字沒有可顯示的課程。</b-alert>
            </template>
            <template v-else-if="Courses.data.length > 0">
                <b-row cols-lg="3" class="courses">
                    <b-col @click="Detail(course.Id)" class="mb-5" v-for="(course, index) in Courses.data" :key="index">
                        <b-card img-alt="Image" tag="article" class="course" no-body>
                            <figure>
                                <b-card-img
                                    v-if="course.Cover != ''"
                                    :src="$store.state.FilePath + 'files/_course/' + course.Id + '/cover.png'"
                                ></b-card-img>
                                <b-card-img v-else src="./asset/no-image.png"></b-card-img>
                            </figure>

                            <b-card-body>
                                <div class="mb-2">
                                    <b-badge class="mr-1" v-for="(tag, index) in course.Tags" :key="index">
                                        {{ tag }}
                                    </b-badge>
                                </div>
                                <b-card-title title-tag="h5">
                                    {{ course.Name }}<br />
                                    <small
                                        style="color: #888;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 3;overflow: hidden;"
                                        v-html="course.Introduction"
                                    ></small>
                                </b-card-title>
                            </b-card-body>

                            <b-card-footer class="text-right">
                                <div class="signin">
                                    <ul class="list-unstyled">
                                        <li>
                                            原價
                                            <span class="price" style="text-decoration:line-through;color: #888;">
                                                NT${{ course.OrginalPrice }}</span
                                            >
                                            <span class="price">
                                                <span class="number">NT${{ course.Price }}</span></span
                                            >
                                        </li>
                                    </ul>
                                    <b-button
                                        v-if="course.canSignin"
                                        block
                                        variant="primary"
                                        class="mb-2"
                                        @click="butGo(0, course)"
                                        >我要報名</b-button
                                    >
                                </div>
                            </b-card-footer>
                        </b-card>
                    </b-col>
                </b-row>
                <b-row v-show="page.count <= Courses.data.length">
                    <b-col>
                        <b-pagination
                            v-model="page.val"
                            :total-rows="page.rows"
                            :per-page="page.perPage"
                        ></b-pagination>
                    </b-col>
                </b-row>
            </template>
        </b-container>
    </div>
</template>

<script>
    import { apiDictionary, apiCourseList } from '@/api/API.js';
    export default {
        name: 'coursesPa',

        data() {
            return {
                keywords: '',
                AllParam: this.$store.state.AllParam,
                CategoryList: [],
                CategoryId: '',
                Tags: [],
                Type: [],
                State: [],
                TagList: [],
                //

                filter2: {
                    show: true,
                    categories: {
                        categoriesBg: '',
                        val: '',
                        data: [],
                        show: true,
                    },
                    hashtags: {
                        val: [],
                        options: [],
                        optionsAll: [],
                        set2(th1, if_list) {
                            let options = [];
                            //let val=[]
                            for (let i in th1.optionsAll) {
                                let item = th1.optionsAll[i];
                                if (if_list.indexOf(item.value) != -1) {
                                    options.push(item);
                                }
                            }
                            th1.options = options;
                        },
                    },
                    courseType: {
                        val: [],
                        options: [],
                        optionsAll: [
                            { text: '非學分班課程', value: '非學分班課程' },
                            { text: '學分班課程', value: '學分班課程' },
                            { text: '委訓班', value: '委訓班' },
                        ],
                        set2(th1, if_list) {
                            let options = [];
                            for (let i in th1.optionsAll) {
                                let item = th1.optionsAll[i];
                                if (if_list.indexOf(item.value) != -1) {
                                    options.push(item);
                                }
                            }
                            th1.options = options;
                        },
                    },
                    courseStatus: {
                        val: [],
                        options: [],
                        optionsAll: [
                            { text: '規劃中', value: '規劃中' },
                            { text: '已截止', value: '已截止' },
                            { text: '招生中', value: '招生中' },
                            { text: '已成班', value: '已成班' },
                            { text: '開課中', value: '開課中' },
                            { text: '早鳥優惠', value: '早鳥優惠' },
                            { text: '仍可報名', value: '仍可報名' },
                            { text: '已結束', value: '已結束' },
                            { text: '最新課程', value: '最新課程' },
                            { text: '熱門課程', value: '熱門課程' },
                        ],
                        set2(th1, if_list) {
                            let options = [];
                            //let val=[]
                            for (let i in th1.optionsAll) {
                                let item = th1.optionsAll[i];
                                if (if_list.indexOf(item.value) != -1) {
                                    options.push(item);
                                }
                            }
                            th1.options = options;
                        },
                    },
                    keyword: '',
                },
                Courses: {
                    title: '課程資料',
                    load: false,
                    displayType: 'grid',
                    dataAll: [],
                    data: [],
                },
                page: {
                    val: 1,
                    rows: 3,
                    perPage: 1,
                    count: 21,
                    reData(th1, data) {
                        let list = [];
                        let n1 = th1.count * (th1.val - 1);
                        for (let i = n1; i < n1 + th1.count; i++) {
                            if (i > data.length - 1) {
                                break;
                            }
                            list.push(data[i]);
                        }
                        return list;
                    },
                    set(th1, length, count) {
                        th1.val = 1;
                        th1.rows = Math.ceil(length / count);
                        th1.count = count;
                    },
                },
            };
        },
        created() {
            apiDictionary('class', 'category')
                .then((res) => {
                    this.CategoryList = res.data;
                    if (this.AllParam.Search.category == '') {
                        for (var i = 0; i < this.CategoryList.length; i++) {
                            if (this.CategoryList[i].text == '全部課程') {
                                this.CategoryId = this.CategoryList[i].Id;
                            }
                        }
                    }
                })
                .catch((err) => {
                    console.log(err);
                    alert(err.response.data);
                });
        },
        mounted() {
            this.AllParam.Search.category = '';
            if (this.$route.query.C_Id && this.$route.query.C_Text !== '全部課程') {
                this.CategoryId = this.$route.query.C_Id;
                this.AllParam.Search.category = this.$route.query.C_Text;
                this.GetList();
            } else {
                this.GetList();
            }
            if (this.$route.query.hot) {
                this.State.push('hot');
                this.AllParam.Search.hot = true;
                this.GetList();
            }
            if (this.$route.query.new) {
                this.State.push('new');
                this.AllParam.Search.new = true;
                this.GetList();
            }
            if (this.$route.query.keywords) {
                this.keywords = this.$route.query.keywords;
                this.AllParam.Search.keywords = encodeURI(this.$route.query.keywords);
                this.GetList();
            }
        },
        watch: {
            Tags(newVal) {
                this.Courses.load = false;
                if (newVal.length > 0) {
                    if (this.keywords != '') {
                        this.AllParam.Search.keywords = encodeURI(this.keywords) + ',' + encodeURI(newVal);
                        this.GetList();
                    } else {
                        this.AllParam.Search.keywords = encodeURI(newVal);
                        this.GetList();
                    }
                } else {
                    this.AllParam.Search.keywords = encodeURI(this.keywords);
                    this.GetList();
                }
            },
            Type(newVal) {
                this.Courses.load = false;
                if (newVal.includes('Credit')) {
                    this.AllParam.Search.Credit = true;
                } else {
                    this.AllParam.Search.Credit = false;
                }
                if (newVal.includes('Support')) {
                    this.AllParam.Search.Support = true;
                } else {
                    this.AllParam.Search.Support = false;
                }
                this.GetList();
            },
            State(newVal) {
                this.Courses.load = false;
                if (newVal.includes('hot')) {
                    this.AllParam.Search.hot = true;
                } else {
                    this.AllParam.Search.hot = false;
                }
                if (newVal.includes('new')) {
                    this.AllParam.Search.new = true;
                } else {
                    this.AllParam.Search.new = false;
                }
                if (newVal.includes('EnableApply')) {
                    this.AllParam.Search.EnableApply = true;
                } else {
                    this.AllParam.Search.EnableApply = false;
                }
                this.GetList();
            },
            keywords(newVal) {
                if (newVal != '') {
                    if (this.Tags.length > 0) {
                        this.AllParam.Search.keywords = encodeURI(newVal) + ',' + encodeURI(this.Tags);
                        this.GetList();
                    } else {
                        console.log(this.Tags.length);
                        this.AllParam.Search.keywords = encodeURI(newVal);
                        this.GetList();
                    }
                } else {
                    this.AllParam.Search.keywords = encodeURI(this.Tags);
                    this.GetList();
                }
            },
        },
        methods: {
            GetList() {
                apiCourseList(this.AllParam)
                    .then((res) => {
                        this.Courses.data = res.data;
                        for (let i = 0; i < this.Courses.data.length; i++) {
                            this.Courses.data[i].Tags = JSON.parse(this.Courses.data[i].Tags);
                            for (let a = 0; a < this.Courses.data[i].Tags.length; a++) {
                                if (!this.TagList.includes(this.Courses.data[i].Tags[a])) {
                                    this.TagList.push(this.Courses.data[i].Tags[a]);
                                }
                            }
                        }
                        this.Courses.load = true;
                    })
                    .catch((err) => {
                        console.log(err);
                        alert(err.response.data);
                    });
            },
            CategoryClick(data) {
                this.CategoryId = data.Id;
                if (data.text == '全部課程') {
                    this.AllParam.Search.category = '';
                    this.GetList();
                } else {
                    this.AllParam.Search.category = data.text;
                    this.GetList();
                }
            },
            Detail(id) {
                this._go(this.LocaPath.default + 'course?Id=' + id);
            },
            filterF1() {},
            // session
            sessionF1(url, data) {
                if (this.IsNull(url) == '') {
                    //let data1 = this._go2({ key: "CAT_ID" }, "query");
                    let data1 = this._sessionData('CAT_ID');
                    if (this.IsNull(data1) != '') {
                        this.data_in['CAT_ID'] = data1;
                    }
                    //let data2=this._go2({ key: "keyword" }, "query");
                    let data2 = this._sessionData('keyword');
                    if (this.IsNull(data2) != '') {
                        this.data_in['keyword'] = data2;
                        this.filter2['keyword'] = data2;
                        this.categoryClose();
                    }
                    let data3 = this._sessionData('courseStatus');
                    if (this.IsNull(data3) != '') {
                        this.data_in['courseStatus'] = data3;
                        this.filter2['courseStatus'].val.push(data3);
                    }
                    this._sessionData('CAT_ID', '');
                    this._sessionData('keyword', '');
                    this._sessionData('courseStatus', '');
                } else if (url == 'course') {
                    let url = this.LocaPath.default + 'course?CP_ID={CP_ID}';
                    this._go1(this.Str_Replace(url, '{CP_ID}', data), 1);
                } else if (url == 'courseSignup') {
                    this._go2(
                        {
                            path: '/courseSignup',
                            data: { CP_ID: data.CP_ID, RF_IS_GROUP: data.RF_IS_GROUP },
                        },
                        'query',
                    );
                }
            },

            // 日期樣態調整
            turnDate: function(date) {
                if (this.IsNull(date) == '') {
                    return '';
                }
                let newDate = new Date(date);
                return newDate.getFullYear() + '年' + (newDate.getMonth() + 1) + '月' + newDate.getDate() + '日';
            },

            categoryShow() {
                this.filter2['keyword'] = '';
                this.filter2['categories'].show = true;
                this.filterF1();
            },
            categoryClose() {
                this.filter2['categories'].show = false;
            },

            // 來去報名
            butGo(type, data) {
                if (data.canSignin == false) {
                    alert('未開放報名');
                    return;
                }
                if (this.IsNull(this._sessionData('TokenID')) == '') {
                    //alert("尚未登入");
                    let fun1 = this._storeData('LoginF1');
                    if (this.IsNull(fun1) != '') {
                        let path1 = this.$route.path;
                        this._storeData('LoginFUN', (path2, err) => {
                            if (path1 == path2) {
                                this.sessionF1('courseSignup', {
                                    CP_ID: data.CP_ID,
                                    RF_IS_GROUP: type,
                                });
                            } else if (this.IsNull(err) != '') {
                                err();
                            }
                            this._storeData('LoginFUN', '');
                        });
                        fun1();
                    }
                    return;
                }
                this.sessionF1('courseSignup', {
                    CP_ID: data.CP_ID,
                    RF_IS_GROUP: type,
                });
            },

            // 來去看課程詳細資訊
            TableGo(data) {
                this.sessionF1('course', data);
            },

            Re_html(val) {
                if (this.IsNull(val) === '') {
                    return '';
                }

                return this.Str_Replace2(val, [
                    { old: '\r\n', new: '<br>' },
                    { old: '\n', new: '<br>' },
                ]);
            },
            //===============================================================================
            // API
            // 取得課程
            FunctionGetCourseFeriodListByIndex_mini(data_in) {
                this.apiGetCourseFeriodListByIndex_mini(data_in)
                    .then((res) => {
                        let json = JSON.parse(res.data);
                        if (json.Status) {
                            let courses = JSON.parse(json.Data);
                            if (this.IsNull(courses) != '' && courses.length != 0) {
                                //let curr = Date.parse(new Date().toDateString());
                                let imgUrl = [];
                                for (let i in courses) {
                                    let course = courses[i];

                                    if (this.IsNull(course.CA_IMAGE_URL) != '') {
                                        imgUrl.push(course.CA_IMAGE_URL);
                                    }
                                    if (this.IsNull(course.CA_HASHTAG_ID) != '') {
                                        let item2 = course.CA_HASHTAG_ID.split(',');

                                        for (let j in item2) {
                                            if (this.hashtagsName.indexOf(item2[j]) == -1) {
                                                this.hashtagsName.push(item2[j]);
                                                this.filter2['hashtags'].optionsAll.push({
                                                    value: item2[j],
                                                    text: item2[j],
                                                });
                                            }
                                        }
                                    }
                                    this.CourseIf2(course);
                                    /*
                   let begin = Date.parse(course.CA_BEGIN_TIME).valueOf();
                   let end = Date.parse(course.CA_END_TIME).valueOf();
                   let beginSignin = Date.parse(course.CA_BEGIN_DATE).valueOf();
                   let endSignin = Date.parse(course.CA_END_DATE).valueOf();

                   course.courseStatus = [];
                   course.courseType = [];
                   course.canSignin = false;





                   if (course.CA_IS_ENTRUS == 1) {
                     course.courseType.push("委訓合作");
                   }
                   if (course.CS_IS_CREDIT == 1) {
                     course.courseType.push("學分班課程");
                   } else {
                     course.courseType.push("非學分班課程");
                   }

                   //-----------------------------------------------------------------
                   if( curr < begin && course.CA_OPEN =="0" ) {
                     // 未達開課時間、未開放報名
                     course.courseStatus.push('規劃中')
                   } else if (curr < begin) {
                     // 未達開課時間
                     if (course.CA_IS_END_DATE && curr > endSignin) {
                       course.courseStatus.push("已截止");
                     } else {
                       // 課程未開始招生中
                       if (course.CA_IS_BEGIN_DATE && curr > beginSignin) {
                         course.courseStatus.push("招生中");
                         course.canSignin = true;
                       } else if (course.CA_IS_BEGIN_DATE && curr < beginSignin) {
                         course.courseStatus.push("規劃中");
                       } else {
                         course.courseStatus.push("招生中");
                         course.canSignin = true;
                       }
                     }
                     // 早鳥訊息
                     if ( this.IsNull(course.CA_IS_EARLY) && curr < course.CA_EARLY_END_DATE) {
                       course.courseStatus.push("早鳥優惠");
                     }
                     // 已成班
                     if (this.IsNull(course.CA_IS_DEFINITE)) {
                       course.courseStatus.push("已成班");
                     }
                   } else if (curr > begin && curr < end) {
                     // 課程進行中
                     course.courseStatus.push("開課中");
                     // 仍可報名
                     if (this.IsNull(course.CA_EXTEND)) {
                       course.courseStatus.push("仍可報名");
                       course.canSignin = true;
                     }
                   } else {
                     course.courseStatus.push("已結束");
                   }
                   //-----------------------------------------------------------------
                   */
                                    if (imgUrl.length != 0) {
                                        this.FunctionToken(this.FunctionGetArchive, {
                                            MC_ID: imgUrl,
                                        });
                                    }
                                }
                                this.page.set(this.page, courses.length, this.page.count);

                                this.Courses.data = courses;
                                this.Courses.dataAll = courses;
                                this.filterF1();

                                if (imgUrl.length != 0) {
                                    this.FunctionToken(this.FunctionGetArchive, {
                                        MC_ID: imgUrl,
                                    });
                                }
                            }
                            this.Courses.load = true;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            // 取得課程顯示類別
            FunctionGetCategoryListByIndex(data_in) {
                console.log(data_in);
                this.apiGetCategoryListByIndex(data_in)
                    .then((res) => {
                        let json = JSON.parse(res.data);
                        if (json.Status) {
                            let categories = JSON.parse(json.Data);
                            let iconUrl = [];
                            this.filter2['categories'].data = categories;
                            for (let i = 0; i < categories.length; i++) {
                                let item = categories[i];
                                this.categoriesName[item.CAT_ID] = item;
                                if (this.IsNull(categories[i].CAT_ICON_URL) != '') {
                                    iconUrl.push(categories[i].CAT_ICON_URL);
                                }
                                if (this.IsNull(categories[i].CAT_IMAGE_URL) != '') {
                                    iconUrl.push(categories[i].CAT_IMAGE_URL);
                                }

                                if (
                                    this.IsNull(this.data_in.CAT_ID) != '' &&
                                    this.data_in.CAT_ID == categories[i].CAT_ID
                                ) {
                                    this.filter2['categories'].val = this.data_in.CAT_ID;
                                }
                            }

                            if (iconUrl.length != 0) {
                                this.FunctionToken(this.FunctionGetArchive, {
                                    MC_ID: iconUrl,
                                });
                            }
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            // 取得圖片
            FunctionGetArchive(data_in) {
                this.apiGetArchive(data_in)
                    .then((res) => {
                        let json = JSON.parse(res.data);
                        if (json.Status) {
                            let data1 = JSON.parse(json.Data);
                            for (let i in data1) {
                                let item = data1[i];
                                this.images[item.MC_ID] = encodeURI([item.SP_PATH_WEB, item.MC_FILE].join(''));
                            }
                            this._watcher.run();
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
        },
    };
</script>

<style lang="scss" scoped>
    .page {
        min-height: 100vh;
    }
    .loading {
        background-color: rgba($color: #000000, $alpha: 0.65);
        justify-content: center;
        align-items: center;
        position: fixed;
        display: flex;
        left: 0;
        top: 0;
        z-index: 1005;
        min-width: 100vw;
        min-height: 100vh;
    }
    header {
        // background-color: #e5f1f8;
        // border-bottom: 1px solid #cbe4f2;
        > .container {
            // padding-top: 7rem;
            // padding-bottom: 2rem;
            background-size: auto 90%;
            background-repeat: no-repeat;
            background-position: right center;
        }
    }
</style>
