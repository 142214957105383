import axios from 'axios';

const Default = axios.create({
    baseURL: 'http://demo.artinuro.com:5001/api/',
    // baseURL: HostURL(),
});

// const DefaultFile = axios.create({
//     baseURL: 'http://demo.artinuro.com/',
//     responseType: 'blob',
// });

Default.interceptors.request.use(
    (config) => {
        // 统一添加请求头
        const token = localStorage.getItem('NewToken');

        if (token) {
            // 已经登录成功，统一添加token
            config.headers.Authorization = localStorage.getItem('NewToken');
        }
        return config;
    },
    (err) => {
        return Promise.reject(err);
    },
);
// const DefaultFile = axios.create({
//     baseURL: 'http://demo.artinuro.com/',
//     responseType: 'blob',
// });

//
export const apiLogin = (data) => Default.post('User/member/login', data);
export const apiCheckMember = () => Default.get('User/CheckMember');
export const apiGetMember = () => Default.get('User/Member');
// Cart
export const apiAddCart = (id, data) => Default.post('Cart/' + id, data);
export const apiGetCart = () => Default.get('Cart/');
// CheckOut
export const apiToCheckOut = (id) => Default.get('CheckOut/' + id);
// Order
export const apiToOrder = (data) => Default.post('CheckOut/', data);
export const apiDeleteCart = (id) => Default.delete('CheckOut/' + id);
export const apiOrderList = (data) => Default.post('Order/member/List', data);
export const apiOrderDetailList = (data) => Default.post('OrderDetail/member', data);
export const apiOrderDetail = (data) => Default.get('OrderDetail/member/' + data);
export const apiOrderDetailTransfer = (data) => Default.put('OrderDetail/member', data);
//Dictionary
export const apiDictionary = (category, type) => Default.get('Dictionary/Selector/' + category + '/' + type);
export const apiDictionarySeries = (category, type) => Default.get('Dictionary/View/' + category + '/' + type);
export const apiDictionarySelector = (id) => Default.get('Dictionary/Selector/' + id);
export const apiGetDictionary = (data) => Default.get('Dictionary/' + data);
//Course
export const apiCourseList = (data) => Default.post('Course/View/list', data);
export const apiGetCourse = (data) => Default.get('Course/View/' + data);
export const apiGetCourseSeries = (data) => Default.get('Course/View/Series/' + data);

// Information
export const apiGetInformation = (data) => Default.get('Information/GetByType/' + data);

// Photo
export const apiGetPhoto = (data) => Default.post('Photo/View/List', data);
// Faq
export const apiGetFaq = (data) => Default.post('Faq/View/List', data);
// Questionnaire
export const apiGetQuestion = (data) => Default.get('Questionnaire/Title/' + data);
export const apiSaveQuestion = (data) => Default.post('Questionnaire/Save/', data);
// City Town
export const apiGetCityTown = (data) => Default.post('City/List', data);
