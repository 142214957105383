<template>
    <div class="footer">
        <footer>
            <b-container fluid>
                <b-row align-h="between">
                    <b-col>
                        <b-row align-v="stretch">
                            <b-col cols="12" sm="auto" v-html="info"> </b-col>
                            <b-col cols="12" sm="auto">
                                <div class="line">
                                    <h6>關於</h6>
                                    <b-nav vertical>
                                        <b-nav-item @click="_go1('./#/about', 1)">關於我們</b-nav-item>
                                        <b-nav-item target="_blank" href="https://www.fcu.edu.tw/privacy/"
                                            >個資保護政策</b-nav-item
                                        >
                                    </b-nav>
                                </div>
                            </b-col>
                            <b-col cols="12" sm="auto">
                                <div class="line">
                                    <h6>幫助</h6>
                                    <b-nav vertical>
                                        <b-nav-item to="/news">最新消息</b-nav-item>
                                        <b-nav-item to="/qanda">常見問題</b-nav-item>
                                    </b-nav>
                                </div>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col sm="3">
                        <b-row>
                            <b-col>
                                <div
                                    v-html="social"
                                    style="display: flex;justify-content: center;padding-left:3rem"
                                ></div>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
                <b-row class="d-none"></b-row>
            </b-container>
        </footer>
        <div class="footer-nav">
            <b-container fluid>
                Copyright©逢甲大學Feng Chia University
            </b-container>
        </div>
    </div>
</template>
<script>
    import { apiGetInformation } from '@/api/API.js';
    export default {
        name: 'page-footer',
        components: {},
        data() {
            return {
                info: '',
                social: '',
            };
        },
        created() {
            apiGetInformation('footer_info')
                .then((res) => {
                    this.info = res.data.Content;
                })
                .catch((err) => {
                    console.log(err);
                    alert(err.respone.data);
                });
            apiGetInformation('footer_social')
                .then((res) => {
                    this.social = res.data.Content;
                })
                .catch((err) => {
                    console.log(err);
                    alert(err.respone.data);
                });
        },
        mounted() {},
        methods: {},
    };
</script>
