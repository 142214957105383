<template>
    <div class="user">
        <div class="headerbg">
            <b-container>
                <h1>{{ title }}</h1>
            </b-container>
        </div>
        <b-container>
            <b-row>
                <b-col cols="12">
                    <nacUser></nacUser>
                </b-col>
                <b-col cols="12">
                    <b-card class="mb-3" title="報名表">
                        <div class="responsive">
                            <div>
                                <b-form-input v-model="Param.Search.keywords" placeholder="訂單編號搜尋"></b-form-input>
                            </div>
                            <BTableV1 :TablePage="TablePage" :cell="['Create', 'ProductName', 'Date', 'tool']">
                                <template #Create="{row}">
                                    {{ row.item.Create | datefmt('YYYY-MM-DD') }}
                                    <br />
                                    {{ row.item.Create | datefmt('HH:mm:ss') }}
                                </template>
                                <template #ProductName="{row}">
                                    <div style="max-width: 200px; white-space: pre-wrap;">
                                        {{ row.item.ProductName }}
                                        <br />
                                        <b-badge v-if="row.item.Department == '10'">
                                            產才
                                        </b-badge>
                                        <b-badge v-else variant="danger">
                                            非產才
                                        </b-badge>
                                    </div>
                                </template>
                                <!-- <template #Date="{row}">
                                    {{ row.item.Start | datefmt('YYY/YMM/DD') }}~
                                    {{ row.item.End | datefmt('YYYY/MM/DD') }}
                                </template> -->
                                <template #tool="{row}">
                                    <b-button
                                        variant="primary"
                                        v-if="row.item.PaymentState != '已付款' && row.item.OrderStateNum == '20'"
                                        @click="Pay(row.item)"
                                        size="sm"
                                    >
                                        <span v-if="row.item.PaymentState == '未付款'">付款</span>
                                        <span v-if="row.item.PaymentState == '審核中'">修改資料</span>
                                        <!-- <b-icon-cash></b-icon-cash> -->
                                    </b-button>
                                    <b-button
                                        class="ml-3"
                                        variant="primary"
                                        v-if="row.item.PaymentState != '已報名'"
                                        @click="Question(row.item)"
                                        size="sm"
                                    >
                                        <span>問卷</span>
                                    </b-button>
                                </template>
                            </BTableV1>
                        </div>
                        <template v-if="TablePage.items.length > 0">
                            <div class="overflow-auto mt-5">
                                <b-pagination
                                    align="center"
                                    v-model="Param.Paging.pageNumber"
                                    :total-rows="rows"
                                    :per-page="Param.Paging.pageSize"
                                ></b-pagination>
                            </div>
                        </template>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
        <b-modal id="modalQuestion" ref="modalQuestion" size="lg" hide-footer :title="ProductName + ' 問卷調查'">
            <div class="d-block text-start" v-if="QuestionList.dictionary.length > 0">
                <b-form>
                    <div class="item">
                        <b-col cols="12" v-for="(item, index) in QuestionList.dictionary" :key="index">
                            <div class="d-flex" v-if="item.Type == 'text'">
                                <b-form-group :label="item.Name" label-cols="12" class="w-100">
                                    <b-form-input
                                        :placeholder="'請填寫' + item.Name"
                                        v-model="Answer[index]"
                                    ></b-form-input>
                                </b-form-group>
                            </div>
                            <div class="d-flex" v-if="item.Type == 'textarea'">
                                <b-form-group :label="item.Name" label-cols="12" class="w-100">
                                    <b-form-textarea
                                        :placeholder="'請填寫' + item.Name"
                                        v-model="Answer[index]"
                                    ></b-form-textarea>
                                </b-form-group>
                            </div>
                            <div class="d-flex" v-else-if="item.Type == 'select'">
                                <b-form-group :label="item.Name" label-cols="12" class="w-100">
                                    <select class="custom-select" v-model="Answer[index]">
                                        <option
                                            :value="detail"
                                            v-for="(detail, index) in item.Description"
                                            :key="index"
                                            >{{ detail }}</option
                                        >
                                    </select>
                                </b-form-group>
                            </div>
                            <div class="d-flex" v-else-if="item.Type == 'radio'">
                                <b-form-group :label="item.Name" label-cols="12" class="w-100">
                                    <div
                                        :id="'radio-group-' + index"
                                        role="radiogroup"
                                        tabindex="-1"
                                        class="bv-no-focus-ring"
                                    >
                                        <div
                                            v-for="(detail, index2) in item.Description"
                                            :key="index2"
                                            class="custom-control custom-control-inline custom-radio"
                                        >
                                            <input
                                                type="radio"
                                                v-model="Answer[index]"
                                                name="radio-sub-component"
                                                :value="detail"
                                                :id="'radio' + index + index2"
                                                class="custom-control-input"
                                            />
                                            <label :for="'radio' + index + index2" class="custom-control-label">
                                                {{ detail }}
                                            </label>
                                        </div>
                                    </div>
                                </b-form-group>
                            </div>
                        </b-col>
                    </div>
                </b-form>
            </div>
            <b-row class="mt-5">
                <b-col>
                    <b-button class="" variant="outline-danger" block @click="$bvModal.hide('modalQuestion')"
                        >關閉</b-button
                    >
                </b-col>
                <b-col
                    ><b-button class="" variant="outline-primary" block @click="SaveQuestion()">儲存</b-button>
                </b-col>
            </b-row>
        </b-modal>
        <b-modal id="modalPay" ref="modalPay" hide-footer title="匯款資訊填寫">
            <div class="d-block text-start">
                <b-form>
                    <b-form-group>
                        <template #label> <b-badge variant="danger  mt-1 mr-2">必填</b-badge>匯款帳號後五碼 </template>
                        <b-row>
                            <b-col>
                                <b-form-input
                                    id="input-1"
                                    v-model="PayMent.Number"
                                    type="number"
                                    placeholder="匯款帳號後五碼"
                                    max-length="5"
                                    required
                                ></b-form-input
                            ></b-col>
                        </b-row>
                    </b-form-group>
                    <b-form-group>
                        <template #label> 發票統編 </template>
                        <b-row>
                            <b-col>
                                <b-form-input
                                    id="input-1"
                                    v-model="PayMent.InvoiceNum"
                                    type="number"
                                    placeholder="發票統編"
                                    required
                                ></b-form-input
                            ></b-col>
                        </b-row>
                    </b-form-group>
                    <b-form-group>
                        <template #label> 發票抬頭 </template>
                        <b-row>
                            <b-col>
                                <b-form-input
                                    id="input-1"
                                    v-model="PayMent.InvoiceTitle"
                                    type="text"
                                    placeholder="發票抬頭"
                                    required
                                ></b-form-input
                            ></b-col>
                        </b-row>
                    </b-form-group>
                </b-form>
            </div>
            <b-row class="mt-5">
                <b-col>
                    <b-button class="" variant="outline-danger" block @click="$bvModal.hide('modalPay')">關閉</b-button>
                </b-col>
                <b-col><b-button class="" variant="outline-primary" block @click="Save()">儲存</b-button> </b-col>
            </b-row>
        </b-modal>
    </div>
</template>
<script>
    import nacUser from '@/components/nacUser.vue';
    import BTableV1 from '../../components/BTableV1.vue';
    import {
        apiOrderDetailList,
        apiOrderDetail,
        apiOrderDetailTransfer,
        apiGetQuestion,
        apiSaveQuestion,
    } from '@/api/API.js';
    export default {
        name: 'user-signin',
        components: {
            nacUser,
            BTableV1,
        },
        data() {
            return {
                Param: {
                    Paging: {
                        pageNumber: 1,
                        pageSize: 10,
                    },
                    Search: {
                        keywords: this.$store.state.OrderNo,
                    },
                },
                pageHeader: '',
                PayMent: {
                    Number: '',
                    InvoiceNum: '',
                    InvoiceTitle: '',
                },
                QuestionList: {
                    data: [],
                    dictionary: [],
                },
                ProductName: '',
                Answer: [],
                //
                title: '帳號姓名',
                type: {
                    '0': '處理中',
                    '1': '已成功',
                    '2': '已取消',
                },
                type2: {
                    '0': '未繳費',
                    '1': '已繳費',
                    '2': '已退款',
                    '3': '逾期未付款',
                },
                accountData: {},
                TablePage: {
                    title: '',
                    class: '',
                    BT_style: { striped: true, hover: true },
                    PG_style: {
                        pills: true,
                        align: 'center',
                        'first-text': '第一頁',
                        'prev-text': '上一頁',
                        'next-text': '下一頁',
                        'last-text': '最後一頁',
                    },
                    itemsAll: [],
                    items: [],
                    id: 'Table1',
                    currentPage: 1,
                    rows: 100,
                    page: 10,
                    sortBy: '',
                    sortDesc: false,
                    sortDirection: 'asc',
                    filter: null,
                    filterOn: [],
                    fields: [
                        { key: 'Create', label: '報名日期' },
                        { key: 'OrderNo', label: '訂單編號', sortable: false },
                        { key: 'ProductName', label: '課程名稱', sortable: false },
                        { key: 'OrderState', label: '報名狀態', sortable: false },
                        // { key: 'Date', label: '課程時間', sortable: false },
                        { key: 'Price', label: '金額', sortable: false },
                        { key: 'PaymentState', label: '付款狀態', sortable: false },
                        { key: 'tool', label: '操作', sortable: false },
                    ],

                    TIndex(th1, index) {
                        return (th1.currentPage - 1) * th1.page + index;
                    },
                    clear(th1) {
                        th1.itemsAll = [];
                        th1.items = [];
                        th1.rows = 0;
                        th1.currentPage = 1;
                    },
                    set(th1, data) {
                        th1.itemsAll = JSON.parse(JSON.stringify(data));
                        th1.items = JSON.parse(JSON.stringify(data));
                        th1.rows = data.length;
                    },
                    set2(th1, data) {
                        th1.items = JSON.parse(JSON.stringify(data));
                        th1.rows = data.length;
                        th1.currentPage = 1;
                    },
                    set3(th1, data) {
                        th1.items = JSON.parse(JSON.stringify(data));
                        th1.rows = data.length;
                        th1.currentPage = 1;
                    },
                },

                // Note 'isActive' is left out and will not appear in the rendered table
                fields: [
                    {
                        key: 'regist_01',
                        label: '報名日期',
                    },
                    {
                        key: 'regist_03',
                        label: '報名編號',
                        sortable: false,
                    },

                    {
                        key: 'regist_05',
                        label: '課程名稱',
                        sortable: false,
                    },
                    {
                        key: 'regist_04',
                        label: '報名狀態',
                        sortable: false,
                    },
                    {
                        key: 'regist_06',
                        label: '付款狀態',
                        sortable: false,
                    },
                    {
                        key: 'operation',
                        label: '操作',
                        sortable: false,
                    },
                ],
                items: [
                    {
                        regist_01: '2020/05/05',
                        regist_03: 'A1234567890-1',
                        regist_04: '未報名',
                        regist_05: '無人機實務飛行特訓班',
                        regist_06: '未付款',
                    },
                    {
                        regist_01: '2020/05/05',
                        regist_03: 'A1234567890-1',
                        regist_04: '已報名',
                        regist_05: '無人機實務飛行特訓班',
                        regist_06: '已付款',
                    },
                    {
                        regist_01: '2020/05/05',
                        regist_03: 'A1234567890-1',
                        regist_04: '已取消',
                        regist_05: '無人機實務飛行特訓班',
                        regist_06: '已付款',
                    },
                    {
                        regist_01: '2020/05/05',
                        regist_03: 'A1234567890-1',
                        regist_04: '已取消',
                        regist_05: '無人機實務飛行特訓班',
                        regist_06: '已退款',
                    },
                ],
            };
        },
        watch: {
            'Param.Paging.pageNumber'(newVal) {
                this.FunctionRegistrationSingle(newVal);
            },
            'Param.Search.keywords'(newVal) {
                this.$store.commit('setOrderNo', newVal);
                this.Param.Search.keywords = encodeURI(newVal);
                this.FunctionRegistrationSingle(1);
            },
        },
        created() {},
        mounted() {
            if (this.IsNull(this._sessionData('TokenID')) == '') {
                alert('尚未登入');
                this._go('/');
            }
            this.FunctionToken(this.FunctionMyACCOUNT, {});

            this.FunctionToken(this.FunctionRegistrationSingle(1), {});
        },
        methods: {
            Save() {
                if (this.PayMent.Number.length < 5 || this.PayMent.Number.length == '') {
                    alert('請輸入匯款帳號後五碼');
                } else {
                    apiOrderDetailTransfer(this.PayMent)
                        .then((res) => {
                            console.log(res);
                            alert('儲存成功');
                            this.FunctionRegistrationSingle();
                            this.hideModal();
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }
            },
            Pay(item) {
                // this.PayMent.Id = id;

                switch (item.Department) {
                    case '10':
                        apiOrderDetail(item.Id)
                            .then((res) => {
                                this.PayMent = res.data;
                                this.$refs['modalPay'].show();
                            })
                            .catch((err) => {
                                alert(err.response.data);
                            });
                        // this.PayMent.PaymentType = 'Counter';
                        break;

                    default:
                        // this.PayMent.PaymentType = 'FCU';
                        alert('fucpay');
                        break;
                }
            },
            Question(item) {
                this.QuestionList = {
                    data: [],
                    dictionary: [],
                };
                this.Answer = [];
                this.ProductName = item.ProductName;
                apiGetQuestion(item.ProductId)
                    .then((res) => {
                        this.QuestionList = res.data;
                        for (let i = 0; i < this.QuestionList.dictionary.length; i++) {
                            if (
                                this.QuestionList.dictionary[i].Type == 'radio' ||
                                this.QuestionList.dictionary[i].Type == 'select'
                            ) {
                                this.QuestionList.dictionary[i].Description = JSON.parse(
                                    this.QuestionList.dictionary[i].Description,
                                );
                            }
                        }
                        setTimeout(() => {
                            // this.$refs['modalQuestion'].show();
                            this.$bvModal.show('modalQuestion');
                        }, 500);
                    })
                    .catch((err) => {
                        alert('暫無問卷');
                        console.log(err);
                    });
            },
            SaveQuestion() {
                for (let i = 0; i < this.Answer.length; i++) {
                    this.QuestionList.data.問卷答案[i] = this.Answer[i];
                }
                apiSaveQuestion(this.QuestionList.data)
                    .then((res) => {
                        console.log(res);
                        alert('儲存成功');
                        this.$bvModal.hide('modalQuestion');
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            toggleModal() {
                this.hideModal();
            },
            hideModal() {
                this.$refs['modalPay'].hide();
            },
            TableSet() {
                let data1 = [
                    {
                        regist_01: '2020/05/05',
                        regist_03: 'A1234567890-1',
                        regist_04: '未報名',
                        regist_05: '無人機實務飛行特訓班',
                        regist_06: '未付款',
                    },
                    {
                        regist_01: '2020/05/05',
                        regist_03: 'A1234567890-1',
                        regist_04: '已報名',
                        regist_05: '無人機實務飛行特訓班',
                        regist_06: '已付款',
                    },
                    {
                        regist_01: '2020/05/05',
                        regist_03: 'A1234567890-1',
                        regist_04: '已取消',
                        regist_05: '無人機實務飛行特訓班',
                        regist_06: '已付款',
                    },
                    {
                        regist_01: '2020/05/05',
                        regist_03: 'A1234567890-1',
                        regist_04: '已取消',
                        regist_05: '無人機實務飛行特訓班',
                        regist_06: '已退款',
                    },
                ];
                this.TablePage.set(this.TablePage, data1);
            },
            sessionF1(url, data) {
                if (url == 'myRegistrationDetailed') {
                    console.log(data);
                    data.CP_ID = data.ProductId;
                    this._sessionData(url, JSON.stringify(data));
                    this._go(url);
                } else if (url == 'course') {
                    console.log(2);
                    let url = this.LocaPath.default + 'course?CP_ID={CP_ID}';
                    this._go1(this.Str_Replace(url, '{CP_ID}', data), 1);
                }
            },
            // TableGo(data) {

            //     // this.sessionF1('myRegistrationDetailed', data);
            // },

            TableGo2(data) {
                if (this.IsNull(data.RF_ID) == '') {
                    return;
                }
                if (
                    (data.PAY_STATUS !== 0 && data.PAY_STATUS !== 3) ||
                    data.RF_STATUS !== 0 ||
                    data.RF_IS_GROUP !== 0 ||
                    data.PAY_TYPE !== 0
                ) {
                    return;
                }
                let url = this.SignUp_url + '?RF_ID=';
                this._go1(url + data.RF_ID);
            },
            TableGo3(data) {
                this.sessionF1('course', data.CP_ID);
            },
            TableDel(data) {
                this.Clog(data);
                if (data.RF_STATUS !== 0 || (data.PAY_STATUS !== 0 && data.PAY_STATUS !== 3)) {
                    return;
                }
                if (confirm('確定要取消報名?') == false) {
                    return;
                }
                this.FunctionToken(this.FunctionCancelRegistration, {
                    RF_CP_ID: data.RF_CP_ID,
                    RF_GROUP_ID: data.RF_GROUP_ID,
                    RF_STATUS: '2',
                });
            },

            //===============================================================
            //api
            FunctionRegistrationSingle(val) {
                this.TablePage.items = [];
                this.loadSet.show = true;
                this.Param.Paging.pageNumber = val;
                apiOrderDetailList(this.Param)
                    .then((res) => {
                        this.TablePage.items = res.data;
                        this.pageHeader = JSON.parse(res.headers['list-header']).Paging;
                        this.rows = this.pageHeader.totalCount;
                        this.loadSet.show = false;
                    })
                    .catch((err) => {
                        this.loadSet.show = false;
                        console.log(err.response);
                    });
                // this.apiRegistrationSingle(data_in)
                // .then(res=>{
                //    let json=JSON.parse(res.data)
                //    if(json.Status){
                //       let data1=JSON.parse(json.Data)
                //       this.Clog(data1)
                //       if(data1.length!=0){
                //          for(let i in data1){
                //             let item=data1[i]
                //             if(item.list.length==0){
                //                item.RF_ID=''
                //             }else{
                //                item.RF_ID=item.list[0].RF_ID
                //             }
                //          }
                //          let th1=this
                //          data1.sort((a,b)=>{
                //             return -1*(Number(new Date(th1.str2date3(a.RF_DATE)))-Number(new Date(th1.str2date3(b.RF_DATE))))
                //          })

                //          this.TablePage.set(this.TablePage,data1)
                //          let cps=[]
                //          let pays=[]
                //          for(let i in data1){
                //             cps.push(data1[i].RF_CP_ID)
                //             pays.push(data1[i].RF_PAY_ID)
                //          }
                //          this.FunctionToken(this.FunctionGetCourseFeriodList,{
                //             TYPE:'BATH',
                //             CP_IDS:cps
                //          })

                //          this.FunctionToken(this.FunctionGetStudentPaymentList,{
                //             TYPE:'BATH',
                //             PAY_IDS:pays
                //          })

                //       }
                //    }
                // })
                // .catch(err=>{
                //    this.Elog(err)
                // })
            },
            FunctionGetCourseFeriodList(data_in) {
                this.apiGetCourseFeriodList(data_in)
                    .then((res) => {
                        let json = JSON.parse(res.data);

                        if (json.Status) {
                            let data1 = JSON.parse(json.Data);
                            this.Clog(data1);
                            for (let i in data1) {
                                let data2 = this.TablePage.items.filter((x) => x.RF_CP_ID == data1[i].CP_ID);
                                for (let j in data2) {
                                    for (let k in data1[i]) {
                                        data2[j][k] = data1[i][k];
                                    }
                                }
                            }
                            this.TablePage.set(this.TablePage, this.TablePage.items);
                        }
                    })
                    .catch((err) => {
                        this.Elog(err);
                    });
            },
            FunctionGetStudentPaymentList(data_in) {
                this.apiGetStudentPaymentList(data_in)
                    .then((res) => {
                        let json = JSON.parse(res.data);
                        if (json.Status) {
                            let data1 = JSON.parse(json.Data);
                            this.Clog(data1);
                            for (let i in data1) {
                                let data2 = this.TablePage.items.filter((x) => x.RF_PAY_ID == data1[i].PAY_ID);
                                for (let j in data2) {
                                    for (let k in data1[i]) {
                                        data2[j][k] = data1[i][k];
                                    }
                                }
                            }
                            this.TablePage.set(this.TablePage, this.TablePage.items);
                        }
                    })
                    .catch((err) => {
                        this.Elog(err);
                    });
            },
            FunctionCancelRegistration(data_in) {
                this.apiCancelRegistration(data_in)
                    .then((res) => {
                        let json = JSON.parse(res.data);
                        if (json.Status) {
                            this.FunctionToken(this.FunctionRegistrationSingle(1), {});
                        }
                    })
                    .catch((err) => {
                        this.Elog(err);
                    });
            },
            FunctionMyACCOUNT(data_in) {
                this.apiMyACCOUNT(data_in)
                    .then((res) => {
                        let json = JSON.parse(res.data);
                        if (json.Status) {
                            let data1 = JSON.parse(json.Data);
                            this.Clog(data1);
                            this.accountData = data1;
                            this._accountData(data1);
                            this.title = this.IsNull(this.accountData['AC_USERNAME']);
                        }
                    })
                    .catch((err) => {
                        this.Elog(err);
                    });
            },
        },
    };
</script>
