import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        FilePath: 'http://demo.artinuro.com:5001/',
        // FilePath: '',
        accountData: '',
        datas: {},
        OrderNo: '',
        Param: {
            Paging: {
                pageNumber: 1,
                pageSize: 10,
            },
            Search: {
                keywords: '',
            },
        },
        AllParam: {
            Paging: {
                pageNumber: 1,
                pageSize: 99999,
            },
            Search: {
                keywords: '',
            },
        },
        WebSite: 'http://demo.artinuro.com:5001/',
    },
    mutations: {
        setaccountData(state, data) {
            state.accountData = data;
        },
        _datas(state, data) {
            state.datas = data;
        },
        setOrderNo(state, data) {
            state.OrderNo = data;
        },
    },
    actions: {},
    modules: {},
});
