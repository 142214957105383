<template>
    <div>
        <header style="background-color:#f5f5f5">
            <b-container class="text-center">
                <b-img :src="$store.state.WebSite + 'files/_series/' + Item.Id + '/' + Item.Cover" fluid></b-img>
            </b-container>
        </header>

        <b-container>
            <div class="py-5" v-html="IsNull(Item.Description)"></div>
            <h4>課程列表</h4>
            <b-row cols="1" class="courses list" v-for="(item, index) in courses.data" :key="index">
                <b-col class="mb-4">
                    <b-card img-alt="Image" tag="article" class="course" img-left no-body>
                        <b-link class="mainImage" style="width:400px" @click="_go('./course?Id=' + item.Id)">
                            <b-card-img
                                v-if="item.Cover != ''"
                                :src="$store.state.FilePath + 'files/_course/' + item.Id + '/cover.png'"
                            ></b-card-img>
                            <b-card-img v-else src="./asset/no-image.png"></b-card-img>
                        </b-link>
                        <b-card-body>
                            <b-card-title>
                                {{ IsNull(item.Name) }}<br />
                                <small v-html="Re_html(item.Introduction)"></small>
                            </b-card-title>

                            <b-card-sub-title class="mb-2" style="position: static;">
                                <p v-show="false">
                                    <template>
                                        <b-badge
                                            v-for="(j2, j1) in item.Tags"
                                            :key="j1"
                                            v-bind="IsNull(courseStatusStyle[IsNull(j2)], courseStatusStyle[''])"
                                        >
                                            {{ IsNull(j2) }}</b-badge
                                        >
                                    </template>
                                </p>
                            </b-card-sub-title>
                            <ul class="list-unstyled price">
                                <li>
                                    課程原價
                                    <span class="number" style="text-decoration:line-through;">{{
                                        IsNull(item.OrginalPrice)
                                    }}</span>
                                    、課程費用 <span class="number">{{ IsNull(item.Price) }}</span>
                                </li>
                            </ul>
                        </b-card-body>
                        <div class="signin" v-if="item.canSignin">
                            <b-button block class="mr-1" @click="butGo(0, item)">我要報名</b-button>
                            <b-button v-if="item.CA_IS_GROUP" @click="butGo(1, item)" block>團體報名</b-button>
                        </div>
                    </b-card>
                </b-col>
            </b-row>
            <b-alert show v-if="courses.data.length == 0" variant="primary">尚無課程</b-alert>
        </b-container>
    </div>
</template>

<script>
    import { apiGetCourseSeries, apiGetDictionary } from '@/api/API.js';
    export default {
        name: 'CourseSeriesDetails',
        data() {
            return {
                Id: '',
                Item: '',

                //
                courseStatusStyle: {
                    '': { class: 'mr-1', variant: 'primary' },
                    規劃中: { class: 'mr-1', variant: 'primary' },
                    已截止: { class: 'mr-1', variant: 'primary' },
                    招生中: { class: 'mr-1', variant: 'primary' },
                    已成班: { class: 'mr-1', variant: 'primary' },
                    開課中: { class: 'mr-1', variant: 'primary' },
                    早鳥優惠: { class: 'mr-1', variant: 'primary' },
                    仍可報名: { class: 'mr-1', variant: 'primary' },
                    已結束: { class: 'mr-1', variant: 'primary' },
                    最新課程: { class: 'mr-1', variant: 'primary' },
                    熱門課程: { class: 'mr-1', variant: 'danger' },
                },
                files: {},
                data_in: {},
                data: {},
                courses: {
                    data: {},
                },
            };
        },
        mounted() {
            if (this.$route.query.id) {
                this.Id = this.$route.query.id;
                this.Init();
            } else {
                this._go('./');
            }
            // let navBgSwitch=this._storeData('navBgSwitch')
            // if(this.IsNull(navBgSwitch)!=''){
            //   navBgSwitch(false)
            // }
            // this.sessionF1()
            // this.FunctionToken(this.FunctionGetListSeries, {});
        },
        methods: {
            Init() {
                apiGetDictionary(this.Id)
                    .then((res) => {
                        this.Item = res.data;
                    })
                    .catch((err) => {
                        console.log(err);
                        alert(err.respone.data);
                    });
                apiGetCourseSeries(this.Id)
                    .then((res) => {
                        console.log(res);
                        this.courses.data = res.data;
                        // this.Item = res.data;
                    })
                    .catch((err) => {
                        console.log(err);
                        alert(err.respone.data);
                    });
            },
            //
            sessionF1(url, data) {
                if (this.IsNull(url) == '') {
                    let data1 = this._go2({ key: 'SS_ID' }, 'query');
                    if (this.IsNull(data1) != '') {
                        this.data_in.SS_ID = data1;
                        this.FunctionToken(this.FunctionGetListSeriesCourseIndex_mini, {
                            SC_SSID: this.data_in.SS_ID,
                        });
                    } else {
                        this._go('/');
                    }
                } else if (url == 'course') {
                    let url = this.LocaPath.default + 'course?CP_ID={CP_ID}';
                    this._go1(this.Str_Replace(url, '{CP_ID}', data), 1);
                } else if (url == 'courseSignup') {
                    this._go2(
                        {
                            path: '/courseSignup',
                            data: { CP_ID: data.CP_ID, RF_IS_GROUP: data.RF_IS_GROUP },
                        },
                        'query',
                    );
                }
            },
            // 日期樣態調整
            turnDate: function(date) {
                let newDate = new Date(date);
                return newDate.getFullYear() + '年' + (newDate.getMonth() + 1) + '月' + newDate.getDate() + '日';
            },
            // 來去報名
            butGo(type, data) {
                if (data.canSignin == false) {
                    alert('未開放報名');
                    return;
                }
                if (this.IsNull(this._sessionData('TokenID')) == '') {
                    //alert("尚未登入");
                    let fun1 = this._storeData('LoginF1');
                    if (this.IsNull(fun1) != '') {
                        let path1 = this.$route.path;
                        this._storeData('LoginFUN', (path2, err) => {
                            if (path1 == path2) {
                                this.sessionF1('courseSignup', {
                                    CP_ID: data.CP_ID,
                                    RF_IS_GROUP: type,
                                });
                            } else if (this.IsNull(err) != '') {
                                err();
                            }
                            this._storeData('LoginFUN', '');
                        });
                        fun1();
                    }
                    return;
                }

                this.sessionF1('courseSignup', {
                    CP_ID: data.CP_ID,
                    RF_IS_GROUP: type,
                });
            },
            // 來去看課程詳細資訊
            TableGo(data) {
                this.sessionF1('course', data);
            },

            Re_html(val) {
                if (this.IsNull(val) === '') {
                    return '';
                }

                return this.Str_Replace2(val, [
                    { old: '\r\n', new: '<br>' },
                    { old: '\n', new: '<br>' },
                ]);
            },

            //====================================================================
            //api

            FunctionGetListSeries(data_in) {
                this.apiGetListSeries(data_in)
                    .then((res) => {
                        let json = JSON.parse(res.data);
                        if (json.Status) {
                            let data1 = JSON.parse(json.Data);
                            let files = [];
                            for (let i in data1) {
                                let item = data1[i];
                                if (item.SS_ID == this.data_in.SS_ID) {
                                    this.data = item;
                                    if (this.IsNull(item.SS_MCID) != '') {
                                        files.push(item.SS_MCID);
                                    }
                                }
                            }
                            if (files.length != 0) {
                                this.FunctionToken(this.FunctionGetArchive, {
                                    MC_ID: files,
                                });
                            }
                        }
                    })
                    .catch((err) => {
                        this.Elog(err);
                    });
            },

            FunctionGetListSeriesCourseIndex_mini(data_in) {
                this.apiGetListSeriesCourseIndex_mini(data_in)
                    .then((res) => {
                        let json = JSON.parse(res.data);
                        if (json.Status) {
                            let data1 = JSON.parse(json.Data);
                            this.Clog('sss');
                            let imgUrl = [];
                            for (let i in data1) {
                                let item = data1[i];
                                if (this.IsNull(item.CA_IMAGE_URL) != '') {
                                    imgUrl.push(item.CA_IMAGE_URL);
                                }
                                this.CourseIf2(item);
                                item.JSteacher = [];
                                if (this.IsNull(item.PROFESSOR) != '') {
                                    for (let j in item.PROFESSOR) {
                                        item.JSteacher.push(this.IsNull(item.PROFESSOR[j].PROF_NAME));
                                    }
                                }
                                this.courses.data[item.CP_ID] = item;
                            }

                            if (imgUrl.length != 0) {
                                this.FunctionToken(this.FunctionGetArchive, {
                                    MC_ID: imgUrl,
                                });
                            }
                        }
                    })
                    .catch((err) => {
                        this.Elog(err);
                    });
            },

            FunctionGetListSeriesCourse(data_in) {
                this.apiGetListSeriesCourse(data_in)
                    .then((res) => {
                        let json = JSON.parse(res.data);
                        if (json.Status) {
                            let data1 = JSON.parse(json.Data);
                            this.Clog(data1);
                        }
                    })
                    .catch((err) => {
                        this.Elog(err);
                    });
            },

            FunctionGetArchive(data_in) {
                this.apiGetArchive(data_in)
                    .then((res) => {
                        let json = JSON.parse(res.data);
                        if (json.Status) {
                            let data1 = JSON.parse(json.Data);
                            for (let i in data1) {
                                let item = data1[i];
                                this.files[item.MC_ID] = [item.SP_PATH_WEB, item.MC_FILE].join('');
                            }

                            this._watcher.run();
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
        },
    };
</script>

<style lang="scss" scoped>
    .courses {
        align-content: stretch;
        .mainImage {
            .card-img {
                aspect-ratio: 16 / 9;
                max-width: 400px;
            }
        }
        .description {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            white-space: normal;
            margin-bottom: 1rem;
        }
        .price {
            display: flex;
            li {
                margin-right: 1rem;
                .number {
                    color: #e73827;
                    font-size: 1.3rem;
                    font-weight: bold;
                }
            }
        }

        &.list {
            .course {
                border-width: 0 0 1px 0;
                flex: 1 2 200px;
                .card-body {
                    padding: 0rem 2rem 1.5rem 2rem;
                }
            }
            .mainImage {
                .card-img {
                    width: 400px;
                }
            }
            .signin {
                white-space: nowrap;
            }
        }

        &.grid {
            .description {
                display: none;
            }
            .end {
                display: none;
            }
            .begin-signin {
                display: none;
            }
            .end-signin {
                display: none;
            }
            .signin {
                display: none;
            }
        }
        .course {
            height: 100%;
        }
    }

    .list {
        .card-img-left {
            height: 200px;
        }
    }
</style>
