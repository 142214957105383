<template>
    <div class="series">
        <div class="headerbg">
            <b-container>
                <h1>系列課程列表</h1>
            </b-container>
        </div>
        <b-container>
            <b-row cols="">
                <template>
                    <b-col v-for="(item, index) in data" :key="index">
                        <b-link @click="_go('./courseSeriesDetails?id=' + item.Id)">
                            <b-img
                                class="shadow mb-3"
                                v-if="item.Cover != ''"
                                :src="$store.state.WebSite + 'files/_series/' + item.Id + '/' + item.Cover"
                                fluid
                            ></b-img>
                            <b-img class="shadow mb-3" v-else src="./asset/no-image.png" fluid></b-img>
                            <h5 class="my-2">{{ item.Name }}</h5>
                        </b-link>
                    </b-col>
                </template>
            </b-row>
        </b-container>
    </div>
</template>
<script>
    import { apiDictionarySeries } from '@/api/API.js';
    export default {
        name: 'courseSeries',
        data() {
            return {
                files: {},
                data: {},
            };
        },
        mounted() {
            // let navBgSwitch=this._storeData('navBgSwitch')
            // if(this.IsNull(navBgSwitch)!=''){
            //   navBgSwitch(false)
            // }
            // this.FunctionToken(this.FunctionGetListSeries, {});
            this.Init();
        },
        methods: {
            Init() {
                apiDictionarySeries('class', 'series')
                    .then((res) => {
                        this.data = res.data;
                    })
                    .catch((err) => {
                        console.log(err);
                        alert(err.response.data);
                    });
            },
            //
            sessionF1(url, data) {
                if (url == 'courseSeriesDetails') {
                    this._go2(
                        {
                            path: '/courseSeriesDetails',
                            data: { SS_ID: this.IsNull(data.SS_ID) },
                        },
                        'query',
                    );
                }
            },
            //========================================================
            //api
            FunctionGetListSeries(data_in) {
                this.apiGetListSeries(data_in)
                    .then((res) => {
                        let json = JSON.parse(res.data);
                        if (json.Status) {
                            let data1 = JSON.parse(json.Data);
                            let files = [];
                            for (let i in data1) {
                                let item = data1[i];
                                if (this.IsNull(item.SS_MCID) != '') {
                                    files.push(item.SS_MCID);
                                }
                                this.data[item.SS_ID] = item;
                            }
                            if (files.length != 0) {
                                this.FunctionToken(this.FunctionGetArchive, {
                                    MC_ID: files,
                                });
                            }
                        }
                    })
                    .catch((err) => {
                        this.Elog(err);
                    });
            },
            FunctionGetArchive(data_in) {
                this.apiGetArchive(data_in)
                    .then((res) => {
                        let json = JSON.parse(res.data);
                        if (json.Status) {
                            let data1 = JSON.parse(json.Data);
                            for (let i in data1) {
                                let item = data1[i];
                                this.files[item.MC_ID] = [item.SP_PATH_WEB, item.MC_FILE].join('');
                            }

                            this._watcher.run();
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
        },
    };
</script>
