<template>
    <div>
        <Header></Header>
        <b-sidebar id="sidebar-1" width="100vw" right shadow>
            <div class="px-3 py-2 text-center">
                <b-img :src="require('@/assets/logo-h.png')" fluid></b-img>
                <b-nav vertical>
                    <b-nav-item @click="_go(i2.url)" v-for="(i2, i1) in links.data" :key="i1">{{ i2.text }}</b-nav-item>
                    <b-nav-item>最新消息</b-nav-item>
                    <b-nav-item>關於我們</b-nav-item>
                    <b-nav-item>聯絡我們</b-nav-item>
                </b-nav>
            </div>
        </b-sidebar>
        <router-view />
        <Footer></Footer>
    </div>
</template>

<script>
    // @ is an alias to /src
    import Header from '@/components/Header.vue';
    import Footer from '@/components/Footer.vue';

    export default {
        name: 'Home',
        components: {
            Header,
            Footer,
        },
        data() {
            return {
                links: {
                    title: '連結',
                    img: require('@/assets/logo-h.png'),
                    data: {
                        home: { text: '首頁', url: '/' },
                        s1: { text: '課程探索', url: '/courses' },
                        s2: { text: '最新消息', url: '/news' },
                    },
                },
            };
        },
        created() {},
    };
</script>
