<template>
    <div class="user">
        <div class="headerbg">
            <b-container>
                <h1>{{ title }}</h1>
            </b-container>
        </div>
        <b-container class="test">
            <p>
                <b-link to="myRegistration"> <b-icon-arrow-left-circle></b-icon-arrow-left-circle> 返回上頁</b-link>
            </p>

            <div class="Course p-5">
                <h2>報名詳細資料</h2>
                <!--***************************************************************************************-->

                <!--***************************************************************************************-->
                <!--
        <section>
          <h3 class="mb-3">課程資訊</h3>
          <b-table-simple bordered caption-top responsive>
            <b-thead head-variant="light">
              <b-tr>
                <b-th>{{Table1.col[0].text}}</b-th>
                <b-th>{{Table1.col[1].text}}</b-th>
                <b-th class="text-center">{{Table1.col[2].text}}</b-th>
                <b-th class="text-right">{{Table1.col[3].text}}</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-for="(i2,i1) in Table1.data" :key="i1">
                <b-td>
									<b-link @click="sessionF1('course',data_in.CP_ID)" >{{i2['s0']}}</b-link>
								</b-td>
                <b-td>{{i2['s1']}}</b-td>
                <b-td class="text-center">{{i2['s2']}}</b-td>
                <b-td class="text-right">{{i2['s3']}}</b-td>
              </b-tr>
            </b-tbody>
            <b-tfoot>
              <b-tr>
                <b-td colspan="4" variant="light" class="text-right">
                  {{Table1.sumtext}}<b>{{Table1.sum}}</b>
                </b-td>
              </b-tr>
            </b-tfoot>
          </b-table-simple>
        </section>
        -->
                <!--***************************************************************************************-->
                <section>
                    <h3 class="mb-3">課程資訊</h3>
                    <b-table-simple bordered caption-top responsive v-if="Table2.show">
                        <b-thead head-variant="light">
                            <b-tr>
                                <b-th>項目（個人報名用這表格）</b-th>
                                <b-th class="text-center">金額</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr>
                                <b-td>{{ IsNull(Table2.course) }}</b-td>
                                <b-td class="text-right" v-if="Table2.off.show">
                                    （早鳥優惠）NT${{ IsNull2(Table2.off.val) }}<br />
                                    <span style="text-decoration:line-through;"> NT${{ IsNull2(Table2.price) }} </span>
                                </b-td>
                                <b-td class="text-right" v-else> NT${{ IsNull2(Table2.price) }} </b-td>
                            </b-tr>

                            <b-tr v-if="Table2.off2.show && Table2.off2.type == 0">
                                <b-td>身份優惠-折扣（{{ IsNull2(Table2.off2.identity) }}）</b-td>
                                <b-td class="text-right">x {{ IsNull2(Table2.off2.val) }}</b-td>
                            </b-tr>

                            <b-tr v-if="Table2.off2.show && Table2.off2.type == 1">
                                <b-td>身份優惠-減免（{{ IsNull2(Table2.off2.identity) }}）</b-td>
                                <b-td class="text-right">- NT${{ IsNull2(Table2.off2.val) }}</b-td>
                            </b-tr>
                        </b-tbody>
                        <b-tfoot>
                            <b-tr>
                                <b-td colspan="3" variant="light" class="text-right">
                                    結帳總金額 <b>NT${{ Math.floor(Number(IsNull2(Table2.sum))) }}</b>
                                </b-td>
                            </b-tr>
                        </b-tfoot>
                    </b-table-simple>

                    <b-table-simple bordered caption-top responsive v-if="Table3.show">
                        <b-thead head-variant="light">
                            <b-tr>
                                <b-th>項目（團體報名用這表格）</b-th>
                                <!-- <b-th>{{Table1.col[0].text}}</b-th> -->
                                <b-th class="text-center">數量</b-th>
                                <b-th class="text-center">金額</b-th>
                                <b-th class="text-center">小計</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr>
                                <b-td>{{ IsNull(Table3.course) }}</b-td>
                                <b-td class="text-center">
                                    {{ IsNull2(Table3.num) }}
                                </b-td>
                                <b-td class="text-right" v-if="Table3.off.show">
                                    （團體優惠）NT${{ IsNull2(Table3.off.val) }}<br />
                                    <span style="text-decoration:line-through;"> NT${{ IsNull2(Table3.price) }} </span>
                                </b-td>
                                <b-td class="text-right" v-else> NT${{ IsNull2(Table3.price) }} </b-td>
                                <b-td class="text-right"> NT${{ IsNull2(Table3.sum) }} </b-td>
                            </b-tr>
                        </b-tbody>

                        <b-tfoot>
                            <b-tr>
                                <b-td colspan="4" variant="light" class="text-right">
                                    結帳總金額 <b>NT${{ Math.floor(Number(IsNull2(Table3.sum))) }}</b>
                                </b-td>
                            </b-tr>
                        </b-tfoot>
                    </b-table-simple>
                </section>

                <!--***************************************************************************************-->
                <section v-if="Table4.show && Object.keys(this.IsNull(Table4['RF_IDID'].options, {})).length != 0">
                    <h3 class="mb-3">身分優惠</h3>
                    <b-row>
                        <b-col>
                            <InputV1 :type="Table4['RF_IDID'].type" :data_in="Table4['RF_IDID']"> </InputV1>
                        </b-col>
                    </b-row>
                    <b-alert show variant="secondary">
                        身份優惠說明（後台身份優惠說明）
                        <hr />

                        <template v-if="Table4.type == '2'">
                            <p>
                                {{ Table4.text }}:
                                <span v-if="Table4.if1" class="text-success">成功</span>
                                <span v-else class="text-danger">尚未驗證</span>
                            </p>
                            <b-form inline>
                                <InputV1 :type="Table4['NID_val'].type" :data_in="Table4['NID_val']"></InputV1>

                                <b-button size="sm" class="mx-2" @click="Table4Obj['Save']()">驗證</b-button>
                            </b-form>
                        </template>

                        <template v-else-if="Table4.type == '0' || Table4.type == '1'">
                            <p>
                                {{ Table4.text }}:
                                <span v-if="Table4.if1" class="text-success">成功</span>
                                <span v-else class="text-danger">尚未驗證</span>
                            </p>
                        </template>
                    </b-alert>
                </section>
                <!--***************************************************************************************-->
                <section>
                    <h3 class="mb-3">學員資料 <small>單人報名</small></h3>
                    <b-col cols="12">
                        <b-form>
                            <b-form-group>
                                <template #label> <b-badge variant="danger  mt-1 mr-2">必填</b-badge>姓名 </template>
                                <b-row>
                                    <b-col>
                                        <b-form-input
                                            id="input-1"
                                            v-model="MemberInfo.User.FirstName"
                                            type="text"
                                            placeholder="姓"
                                            required
                                        ></b-form-input
                                    ></b-col>
                                    <b-col>
                                        <b-form-input
                                            id="input-1"
                                            v-model="MemberInfo.User.LastName"
                                            type="text"
                                            placeholder="名"
                                            required
                                        ></b-form-input> </b-col
                                ></b-row>
                            </b-form-group>
                            <b-form-group>
                                <template #label>
                                    <b-badge variant="danger  mt-1 mr-2">必填 </b-badge>
                                    性別
                                </template>
                                <b-row>
                                    <b-col>
                                        <b-form-radio v-model="MemberInfo.Gender" name="some-radios" value="0"
                                            >男</b-form-radio
                                        ></b-col
                                    >
                                    <b-col>
                                        <b-form-radio v-model="MemberInfo.Gender" name="some-radios" value="1"
                                            >女</b-form-radio
                                        >
                                    </b-col></b-row
                                >
                            </b-form-group>
                            <b-form-group>
                                <template #label>
                                    <b-badge variant="danger  mt-1 mr-2">必填</b-badge>
                                    電話
                                </template>
                                <b-row>
                                    <b-col>
                                        <b-form-input
                                            id="input-1"
                                            v-model="MemberInfo.Phone"
                                            placeholder="電話"
                                            required
                                        ></b-form-input>
                                    </b-col>
                                </b-row>
                            </b-form-group>
                            <b-form-group>
                                <template #label>
                                    <b-badge variant="danger  mt-1 mr-2">必填</b-badge>
                                    Email
                                </template>
                                <b-row>
                                    <b-col>
                                        <b-form-input
                                            id="input-1"
                                            v-model="MemberInfo.User.Mail"
                                            placeholder="Email"
                                            required
                                        ></b-form-input>
                                    </b-col>
                                </b-row>
                            </b-form-group>
                            <b-form-group>
                                <template #label>
                                    <b-badge variant="danger  mt-1 mr-2">必填</b-badge>
                                    地址
                                </template>
                                <b-row class="mb-3">
                                    <b-col>
                                        <b-form-select
                                            id="input-3"
                                            v-model="MemberInfo.City"
                                            :options="CityList"
                                            required
                                        ></b-form-select>
                                    </b-col>
                                    <b-col>
                                        <b-form-select
                                            id="input-3"
                                            v-model="Town"
                                            :options="TownList"
                                            required
                                        ></b-form-select>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12">
                                        <b-form-input
                                            id="input-1"
                                            v-model="MemberInfo.Address"
                                            placeholder="地址"
                                            required
                                        ></b-form-input>
                                    </b-col>
                                </b-row>
                            </b-form-group>
                        </b-form>
                    </b-col>
                </section>

                <!--***************************************************************************************-->

                <section v-show="TablePage.show">
                    <h3 class="mb-3">學員名單 <small>多人報名</small></h3>

                    <BTableV1 :TablePage="TablePage" :cell="['col', 'RF_SEX', 'RF_BDATE', 'RF_ADDRESS', 'tool']">
                        <template #col="{row}">{{ row.index + 1 }}</template>

                        <template #RF_SEX="{row}">
                            {{
                                row.item['RF_SEX'] === 0 || row.item['RF_SEX'] == '0'
                                    ? '男'
                                    : row.item['RF_SEX'] == 1
                                    ? '女'
                                    : IsNull(row.item['RF_SEX'])
                            }}
                        </template>

                        <template #RF_BDATE="{row}">{{ str2date3(row.item.RF_BDATE) }}</template>

                        <template #RF_ADDRESS="{row}">
                            {{
                                [
                                    IsNullKeys(cityName, [row.item.RF_CITYID, 'CT_NAME']),
                                    IsNull(
                                        townName.filter((x) => x.TW_TOWN_ID == row.item.RF_TOWNID),
                                        [{ TW_TOWN_NAME: '' }],
                                    )[0]['TW_TOWN_NAME'],
                                    IsNull(row.item.RF_ADDRESS),
                                ].join('')
                            }}
                        </template>

                        <template #tool="{row}">
                            <b-link @click="form2Obj['Show'](row.item, row.index)" class="mr-3">
                                <b-icon-pencil></b-icon-pencil> 編輯</b-link
                            >
                            <b-link v-if="row.index > 1" @click="form2Obj['Del'](row.item)"
                                ><b-icon-trash></b-icon-trash>刪除</b-link
                            >
                        </template>
                    </BTableV1>
                </section>

                <!--***************************************************************************************-->

                <section>
                    <h3 class="mb-3">付款方式</h3>

                    <div class="payment">
                        <b-row class="item" no-gutters v-show="form3.data['PAY_TYPE'].options[0].show">
                            <b-col cols="2">
                                <b-form-radio
                                    @change="viewObj['PayShow']()"
                                    name="test-radios"
                                    v-model="form3.data['PAY_TYPE'].val"
                                    :disabled="form3.data['PAY_TYPE'].only"
                                    :value="form3.data['PAY_TYPE'].options[0].value"
                                    >{{ form3.data['PAY_TYPE'].options[0].text }}
                                </b-form-radio>
                            </b-col>
                            <b-col>
                                {{ form3.data['PAY_TYPE'].options[0].text2 }}
                            </b-col>
                        </b-row>
                        <b-row class="item" no-gutters v-show="form3.data['PAY_TYPE'].options[1].show">
                            <b-col cols="2">
                                <b-form-radio
                                    @change="viewObj['PayShow']()"
                                    name="test-radios"
                                    v-model="form3.data['PAY_TYPE'].val"
                                    :disabled="form3.data['PAY_TYPE'].only"
                                    :value="form3.data['PAY_TYPE'].options[1].value"
                                    >{{ form3.data['PAY_TYPE'].options[1].text }}
                                </b-form-radio>
                            </b-col>
                            <b-col>
                                {{ form3.data['PAY_TYPE'].options[1].text2 }}
                            </b-col>
                        </b-row>
                    </div>
                </section>

                <!--***************************************************************************************-->
                <section>
                    <h3 class="mb-3">收據資訊</h3>
                    <b-row>
                        <b-col>
                            <b-form-group label="收據抬頭">
                                <InputV1
                                    :type="form3.data['PAY_RECEIPT_TITLE'].type"
                                    :data_in="form3.data['PAY_RECEIPT_TITLE']"
                                >
                                </InputV1>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group label="統一編號">
                                <InputV1
                                    :type="form3.data['PAY_UNICODE_NUMBER'].type"
                                    :data_in="form3.data['PAY_UNICODE_NUMBER']"
                                >
                                </InputV1>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </section>

                <!--***************************************************************************************-->
                <div class="text-center mb-5">
                    <template
                        v-if="
                            RegistrationData.RF_STATUS === 0 && (PAYData.PAY_STATUS === 0 || PAYData.PAY_STATUS === 3)
                        "
                    >
                        <b-button variant="danger" @click="viewObj['Del']()">取消報名</b-button>
                        <b-button variant="primary" class="ml-2" @click="viewObj['Save'](true)" v-if="btns['pay']"
                            >前往付款</b-button
                        >
                        <b-button variant="primary" class="ml-2" @click="viewObj['Save']()">儲存</b-button>
                    </template>

                    <template v-else>
                        <b-button variant="primary" class="ml-2" @click="_go('/myRegistration')">返回</b-button>
                    </template>
                </div>
                <!--***************************************************************************************-->
            </div>
        </b-container>
    </div>
</template>
<script>
    import InputV1 from '../../components/InputV1.vue';
    import BTableV1 from '../../components/BTableV1.vue';
    import { apiGetMember, apiDictionary, apiDictionarySelector } from '@/api/API.js';
    export default {
        name: 'RegistrationDetailed',
        components: {
            InputV1,
            BTableV1,
        },
        data() {
            return {
                CityList: [],
                City: '',
                TownList: [{ text: '請先選擇縣市', value: '' }],
                Town: '',
                MemberInfo: {},

                //
                modal_set: [{ id: 'modal-m1', name: 'form2' }],
                title: '帳號姓名',
                accountData: {},
                courseData: {},
                identityData: {},
                data_in: {},
                PAYData: {},
                RegistrationData: {},
                cityName: {},
                townName: [],
                typeName: {
                    RF_NAME: '學員姓名',
                    RF_SEX: '性別',
                    RF_NID: '身份證號',
                    RF_PHONE: '行動電話',
                    RF_TEL: '聯絡市話',
                    RF_MAIL: '電子信箱',
                    RF_BDATE: '出生日期',
                    RF_ADDRESS: '通訊地址',
                },
                typeName2: {
                    '0': '無驗證',
                    '1': '臨櫃驗證',
                    '2': 'NID驗證',
                },

                TempObj: {
                    data: {},
                    fun: {},
                },
                Table1: {
                    title: '課程資訊',
                    col: [
                        { key: 's0', text: '課程名稱' },
                        { key: 's1', text: '上課日期/時間' },
                        { key: 's2', text: '數量' },
                        { key: 's3', text: '課程費用' },
                    ],
                    data: [
                        {
                            //s1:'上課日期 2021-03-24週三 10:20~12:20',
                            s0: '',
                            s1: '',
                            s2: '1位',
                            s3: '2,000',
                        },
                    ],
                    sumtext: '總計:',
                    sum: '2,000',
                },
                Table1Obj: {},
                Table2: {
                    show: false,
                    title: '個人報名',
                    course: 'AI大數據',
                    price: 1,
                    off: {
                        text: '早鳥優惠',
                        show: false,
                        val: 1,
                    },
                    off2: {
                        text: '身分優惠',
                        show: false,
                        type: 1,
                        identity: '身分名稱',
                        val: 1,
                    },
                    sum: 0,
                },
                Table2Obj: {},

                Table3: {
                    show: false,
                    title: '團體報名',
                    course: 'AI大數據',
                    num: 1,
                    price: 1,
                    off: {
                        text: '團體優惠',
                        show: true,
                        val: 1,
                    },
                    sum: 1,
                },
                Table3Obj: {},
                Table4: {
                    show: false,
                    text: '',
                    type: '',
                    if1: false,
                    title: '身分優惠',
                    data: {},
                    RF_IDID: {
                        type: 'select2',
                        val: '',
                        text: '請選擇身份優惠',
                        show: '',
                        only: false,
                        options: {},
                        msg: '',
                        change: () => {},
                    },
                    nid: '',
                    NID_val: { type: 'text', val: '', text: '請輸入NID', only: false, msg: '', show: false },
                },
                Table4Obj: {},

                form1: {
                    data2: '',
                    title: '學員資料',
                    show: true,
                    data: {
                        RF_NAME: { type: 'text', val: '', text: '請輸入學員姓名', only: false, msg: '', show: false },
                        RF_SEX: {
                            type: 'b-radio-group',
                            val: '',
                            text: '請輸入性別',
                            only: false,
                            options: [
                                { text: '男', value: 0 },
                                { text: '女', value: 1 },
                            ],
                            msg: '',
                            show: false,
                        },
                        RF_NID: { type: 'text', val: '', text: '請輸入身份證號', only: false, msg: '', show: false },
                        RF_PHONE: { type: 'text', val: '', text: '請輸入行動電話', only: false, msg: '', show: false },
                        RF_TEL: { type: 'text', val: '', text: '請輸入聯絡電話', only: false, msg: '', show: false },
                        RF_MAIL: { type: 'text', val: '', text: '請輸入電子信箱', only: false, msg: '', show: false },
                        RF_BDATE: { type: 'date', val: '', only: false, msg: '', show: false },
                        RF_ADDRESS: { type: 'text', val: '', text: '請輸入地址', only: false, msg: '', show: false },
                        RF_CITYID: {
                            type: 'select2',
                            val: '',
                            text: '縣市',
                            only: false,
                            options: {},
                            msg: '',
                            show: true,
                        },
                        RF_TOWNID: {
                            type: 'select2',
                            val: '',
                            text: '鄉鎮',
                            only: false,
                            options: {},
                            msg: '',
                            show: true,
                        },
                    },
                },
                form1Obj: {},
                TablePage: {
                    title: '學員名單',
                    class: '',
                    show: false,
                    BT_style: { hover: true, outlined: true, striped: true, small: true },
                    PG_style: { pills: true, align: 'center', size: 'sm' },
                    itemsAll: [{}],
                    items: [{ RF_NAME: '123' }],
                    id: 'Table1',
                    currentPage: '',
                    rows: '',
                    page: '',
                    sortBy: '',
                    sortDesc: false,
                    sortDirection: 'asc',
                    filter: null,
                    filterOn: [],
                    fields: [
                        { key: 'col', label: '序號', tdClass: 'text-center' },
                        { key: 'RF_NAME', label: '姓名', thClass: 'd-none', tdClass: 'd-none' },
                        { key: 'RF_SEX', label: '性別', thClass: 'd-none', tdClass: 'd-none' },
                        { key: 'RF_NID', label: '身分證號', thClass: 'd-none', tdClass: 'd-none' },
                        { key: 'RF_PHONE', label: '行動電話', thClass: 'd-none', tdClass: 'd-none' },
                        { key: 'RF_TEL', label: '聯絡電話', thClass: 'd-none', tdClass: 'd-none' },
                        { key: 'RF_MAIL', label: '電子信箱', thClass: 'd-none', tdClass: 'd-none' },
                        { key: 'RF_BDATE', label: '出生年月日', thClass: 'd-none', tdClass: 'd-none' },
                        { key: 'RF_ADDRESS', label: '地址', thClass: 'd-none', tdClass: 'd-none' },
                        { key: 'tool', label: '操作' },
                    ],
                    TNum: [
                        { text: '列出近10筆記錄', val: 10 },
                        { text: '列出近20筆記錄', val: 20 },
                        { text: '列出近50筆記錄', val: 50 },
                        { text: '列出近100筆記錄', val: 100 },
                    ],
                    TIndex(th1, index) {
                        return (th1.currentPage - 1) * th1.page + index;
                    },
                    clear(th1) {
                        th1.itemsAll = [];
                        th1.items = [];
                        th1.rows = 0;
                        th1.currentPage = 1;
                    },
                    set(th1, data) {
                        th1.itemsAll = JSON.parse(JSON.stringify(data));
                        th1.items = JSON.parse(JSON.stringify(data));
                        th1.rows = data.length;
                    },
                    set2(th1, data) {
                        th1.items = JSON.parse(JSON.stringify(data));
                        th1.rows = data.length;
                        th1.currentPage = 1;
                    },
                    set3(th1, data) {
                        th1.items = JSON.parse(JSON.stringify(data));
                        th1.rows = data.length;
                        th1.currentPage = 1;
                    },
                },
                TablePageObj: {},
                form2: {
                    title: '學員資料編輯',
                    id: 'modal-m1',
                    index: '',
                    data2: '',
                    data: {
                        RF_NAME: { type: 'text', val: '', text: '請輸入學員姓名', only: false, msg: '', show: false },
                        RF_SEX: {
                            type: 'b-radio-group',
                            val: '',
                            text: '請輸入性別',
                            only: false,
                            options: [
                                { text: '男', value: 0 },
                                { text: '女', value: 1 },
                            ],
                            msg: '',
                            show: false,
                        },
                        RF_NID: { type: 'text', val: '', text: '請輸入身份證號', only: false, msg: '', show: false },
                        RF_PHONE: { type: 'text', val: '', text: '請輸入行動電話', only: false, msg: '', show: false },
                        RF_TEL: { type: 'text', val: '', text: '請輸入聯絡電話', only: false, msg: '', show: false },
                        RF_MAIL: { type: 'text', val: '', text: '請輸入電子信箱', only: false, msg: '', show: false },
                        RF_BDATE: { type: 'date', val: '', only: false, msg: '', show: false },
                        RF_ADDRESS: { type: 'text', val: '', text: '請輸入地址', only: false, msg: '', show: false },
                        RF_CITYID: {
                            type: 'select2',
                            val: '',
                            text: '縣市',
                            only: false,
                            options: {},
                            msg: '',
                            show: true,
                        },
                        RF_TOWNID: {
                            type: 'select2',
                            val: '',
                            text: '鄉鎮',
                            only: false,
                            options: {},
                            msg: '',
                            show: true,
                        },
                    },
                },
                form2Obj: {},
                form3: {
                    title: '付款方式',
                    show: true,
                    title2: '收據資訊',
                    title3: '報名規定辦法 / 轉班 / 退費規定',
                    text: '內容',
                    data: {
                        PAY_TYPE: {
                            val: '0',
                            only: false,
                            stacked: true,
                            options: [
                                {
                                    value: '0',
                                    text: '逢甲PAY',
                                    show: true,
                                    text2: '提供 ATM轉帳、LINE Pay、街口支付、台灣Pay、國泰KOKO及支付寶等方式付款',
                                },
                                {
                                    value: '1',
                                    text: '臨櫃付款',
                                    show: true,
                                    text2: '請於一周內至逢甲大學推廣教育處辦公室完成繳費程序',
                                },
                            ],
                            change: () => {},
                        },
                        PAY_RECEIPT_TITLE: { type: 'text', val: '', text: '請輸入收據抬頭', only: false, msg: '' },
                        PAY_UNICODE_NUMBER: { type: 'text', val: '', text: '請輸入統一編號', only: false, msg: '' },
                        if2: {
                            type: 'b-checkbox-group',
                            val: [],
                            only: false,
                            stacked: false,
                            options: [{ value: 'val1', text: '本人已詳細閱讀並同意本網站之相關條款' }],
                            msg: '',
                        },
                    },
                },
                form3Obj: {},

                viewObj: {},

                btns: {
                    pay: false,
                },
            };
        },
        watch: {
            watch: {
                'MemberInfo.City'(newVal) {
                    this.Town = '';
                    var id = this.CityList.filter((item) => item.text == newVal)[0].Id;
                    apiDictionarySelector(id)
                        .then((res) => {
                            this.TownList = res.data;
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                },
            },
        },
        mounted() {
            apiDictionary('address', 'city')
                .then((res) => {
                    this.CityList = res.data;
                })
                .catch((err) => {
                    console.log(err);
                });
            apiDictionary('address', 'town')
                .then((res) => {
                    this.TownList = res.data;
                })
                .catch((err) => {
                    console.log(err);
                });
            apiGetMember()
                .then(async (res) => {
                    this.MemberInfo = res.data;
                    this.Town = await this.TownList.filter((item) => item.text == this.MemberInfo.Town)[0].value;
                    console.log(res);
                })
                .catch((err) => {
                    console.log(err);
                });
            if (this.IsNull(this._sessionData('TokenID')) == '') {
                alert('尚未登入');
                this._go('/');
            }
            if (this.IsNull(this._accountData()) != '') {
                this.accountData = this._accountData();
                this.title = this.IsNull(this.accountData['AC_USERNAME']);
            }

            this.modal_init(this.modal_set);
            this.Table1Obj = this.Table1Obj_init();
            this.Table2Obj = this.Table2Obj_init();
            this.Table3Obj = this.Table3Obj_init();
            this.Table4Obj = this.Table4Obj_init();
            this.form1Obj = this.form1Obj_init();
            this.form2Obj = this.form2Obj_init();
            this.form3Obj = this.form3Obj_init();
            this.TablePageObj = this.TablePageObj_init();
            this.viewObj = this.viewObj_init();
            this.formCheckSet();
            this.sessionF1();
        },
        methods: {
            sessionF1(url, data) {
                if (this.IsNull(url) == '') {
                    let data1 = this._sessionData('myRegistrationDetailed');
                    if (this.IsNull(data1) == '') {
                        this._go('/');
                    }
                    this.data_in = JSON.parse(data1);
                    this.FunctionToken(this.FunctionGetCourseFeriodSingleByIndex, {
                        CP_ID: this.data_in.CP_ID,
                    });
                    this.FunctionToken(this.FunctionSelectCity, {});
                    this.FunctionToken(this.FunctionSelectTown, {});

                    if (this.data_in.RF_IS_GROUP == '1') {
                        this.form1.show = false;
                        this.TablePage.show = true;
                        this.Table3.show = true;
                    } else {
                        this.form1.show = true;
                        this.TablePage.show = false;
                        this.Table2.show = true;
                        this.Table4.show = true;
                    }

                    this.form3.data['PAY_TYPE'].val = this.data_in.PAY_TYPE + '';
                    this.form3.data['PAY_RECEIPT_TITLE'].val = this.IsNull(this.data_in.PAY_RECEIPT_TITLE);
                    this.form3.data['PAY_UNICODE_NUMBER'].val = this.IsNull(this.data_in.PAY_UNICODE_NUMBER);
                    if (this.data_in.RF_IS_GROUP == '1') {
                        this.form3.data['PAY_TYPE'].options[0].show = false;
                        this.form3.data['PAY_TYPE'].only = true;
                    }
                    this.viewObj['PayShow']();
                } else if (url == 'course') {
                    this._go2(
                        {
                            path: '/course',
                            data: { CP_ID: data },
                        },
                        'query',
                    );
                }
                this._watcher.run();
            },
            formCheckSet() {
                for (let i in this.form1.data) {
                    let item = this.form1.data[i];
                    if (i == 'RF_CITYID') {
                        item.change = (data) => {
                            this.form1Obj['Check'](i);
                            this.form1.data['RF_TOWNID'].val = '';
                            if (data.val == '') {
                                this.form1.data['RF_TOWNID'].options = {};
                            } else {
                                let data1 = this.townName.filter((x) => x.TW_CITY_ID == data.val);
                                let options = {};
                                for (let i in data1) {
                                    let item = data1[i];
                                    options[item.TW_TOWN_ID] = {
                                        text: this.IsNull(item.TW_TOWN_NAME),
                                        val: item.TW_TOWN_ID,
                                    };
                                }
                                this.form1.data['RF_TOWNID'].options = options;
                            }
                            this.form1Obj['Check']('RF_TOWNID');
                        };
                    } else if (item.type == 'text') {
                        this.$watch(
                            () => {
                                return item.val;
                            },
                            () => {
                                this.form1Obj['Check'](i);
                            },
                        );
                    } else {
                        item.change = () => {
                            this.form1Obj['Check'](i);
                        };
                    }
                }

                for (let i in this.form2.data) {
                    let item = this.form2.data[i];
                    if (i == 'RF_CITYID') {
                        item.change = (data) => {
                            this.form2Obj['Check'](i);
                            this.form2.data['RF_TOWNID'].val = '';
                            if (data.val == '') {
                                this.form2.data['RF_TOWNID'].options = {};
                            } else {
                                let data1 = this.townName.filter((x) => x.TW_CITY_ID == data.val);
                                let options = {};
                                for (let i in data1) {
                                    let item = data1[i];
                                    options[item.TW_TOWN_ID] = {
                                        text: this.IsNull(item.TW_TOWN_NAME),
                                        val: item.TW_TOWN_ID,
                                    };
                                }
                                this.form2.data['RF_TOWNID'].options = options;
                            }
                            this.form2Obj['Check']('RF_TOWNID');
                        };
                    } else if (item.type == 'text') {
                        this.$watch(
                            () => {
                                return item.val;
                            },
                            () => {
                                this.form2Obj['Check'](i);
                            },
                        );
                    } else {
                        item.change = () => {
                            this.form2Obj['Check'](i);
                        };
                    }
                }

                this.Table4['RF_IDID'].change = (data) => {
                    data.msg = '';
                    this.Table4.text = '';
                    this.Table4.type = '';
                    this.Table4.if1 = false;
                    this.Table4['NID_val'].val = '';
                    this.Table4['NID_val'].show = false;

                    if (this.IsNull(data.val) == '') {
                        this.Table4.text = '';
                        this.Table2.off2.show = false;
                        this.Table2Obj['Update']();
                        if (this.data_in.RF_IS_GROUP != 1) {
                            this.form3.data['PAY_TYPE'].only = false;
                            this.form3.data['PAY_TYPE'].options[0].show = true;
                        }
                    } else {
                        let data2 = this.Table4.data[data.val];
                        this.Table4.text = [this.IsNull(data2.IDCA__MEMO), '(', this.IsNull(data2.ID_NAME), ')'].join(
                            '',
                        );

                        if (
                            this.courseData['courseStatus'].indexOf('早鳥優惠') != -1 &&
                            this.courseData['CA_EARLY_SHARED'] != 1
                        ) {
                            console.log('未啟用優惠共用');
                        } else {
                            console.log(data2);
                            if (data2.IDCA__TYPE == 1) {
                                this.Table2.off2.type = 1;
                                this.Table2.off2.val = Number(data2.IDCA__DISCOUNT);
                            } else {
                                this.Table2.off2.type = 0;
                                this.Table2.off2.val = Number(data2.IDCA__DISCOUNT) * 0.01;
                            }

                            this.Table2.off2.identity = this.IsNull(data2.ID_NAME);
                            this.Table2.off2.show = true;
                            this.Table2Obj['Update']();
                        }

                        let data3 = Object.values(this.identityData);
                        let item = data3.filter((x) => x.IDCA_IDID == data.val);
                        if (item.length != 0) {
                            if (item[0].IDCA__ON_SPOT == 1) {
                                this.form3.data['PAY_TYPE'].val = 1;
                                this.form3.data['PAY_TYPE'].only = true;
                                this.form3.data['PAY_TYPE'].options[0].show = false;
                                this.viewObj['PayShow']();
                            } else {
                                if (this.data_in.RF_IS_GROUP != 1) {
                                    this.form3.data['PAY_TYPE'].only = false;
                                    this.form3.data['PAY_TYPE'].options[0].show = true;
                                }
                            }
                        }

                        let item2 = this.OnlyData['ID'].filter((x) => x.ID_ID == data.val);
                        if (item2.length != 0) {
                            this.Table4['NID_val'].text = item2[0]['Identity'] == 3 ? '請輸入ID' : '請輸入NID';
                            this.Table4.type = '2';
                        } else if (item.length != 0 && item[0].IDCA__ON_SPOT == 1) {
                            this.Table4.type = '1';
                        } else {
                            this.Table4.type = '0';
                            this.Table4.if1 = true;
                        }
                    }
                };

                this.$watch(
                    () => {
                        return this.Table4['NID_val'].val;
                    },
                    () => {
                        this.Table4['NID_val'].msg = '';
                        if (this.Table4['NID_val'].val == '') {
                            this.Table4['NID_val'].msg = { type: false, texts: [] };
                        }
                        this.Table4.if1 = false;

                        if (
                            this.IsNullKeys(this.form1, ['data2', 'RF_IDID']) != '' &&
                            this.IsNullKeys(this.form1, ['data2', 'RF_NID2']) != ''
                        ) {
                            if (
                                this.Table4['RF_IDID'].val == this.form1.data2['RF_IDID'] &&
                                this.Table4['NID_val'].val == this.form1.data2['RF_NID2']
                            ) {
                                this.Table4.if1 = true;
                                this.Table4.nid = this.form1.data2['RF_NID2'];
                            }
                        }
                    },
                );
            },

            Table1Obj_init() {
                let Obj = {};
                Obj['Table1'] = this.Table1;
                return Obj;
            },
            Table2Obj_init() {
                let Obj = {};
                Obj['Table2'] = this.Table2;

                Obj['Update'] = () => {
                    this.Table2.off.show = false;
                    this.Table2.sum = Number(this.Table2.price);
                    let sum = Number(this.Table2.price);
                    if (this.courseData['CA_IS_EARLY'] && this.courseData['courseStatus'].indexOf('早鳥優惠') != -1) {
                        this.Table2.off.show = true;
                        sum = Number(this.Table2.off.val);
                    }
                    if (this.Table2.off2.show) {
                        if (this.Table2.off2.type == 1) {
                            sum -= Number(this.Table2.off2.val);
                        } else {
                            sum = sum * Number(this.Table2.off2.val);
                        }
                    }

                    this.Table2.sum = sum;
                };
                return Obj;
            },
            Table3Obj_init() {
                let Obj = {};

                Obj['Table3'] = this.Table3;

                Obj['Update'] = () => {
                    this.Table3.off.show = false;
                    this.Table3.sum = Number(this.Table3.price) * Number(this.Table3.num);
                    if (this.IsNull(this.courseData['CA_GROUP_NUMBER']) != '') {
                        if (this.Table3.num >= Number(this.courseData['CA_GROUP_NUMBER'])) {
                            this.Table3.off.show = true;
                            this.Table3.sum = Number(this.Table3.off.val) * Number(this.Table3.num);
                        }
                    }
                };

                return Obj;
            },
            Table4Obj_init() {
                let Obj = {};
                Obj['Table4'] = this.Table4;

                Obj['Save'] = () => {
                    if (this.Table4.type != '2') {
                        return;
                    }

                    if (this.IsNull(this.Table4.NID_val.val) == '') {
                        alert('未填寫NID');
                        return;
                    }

                    this.Table4.nid = this.Table4['NID_val'].val;

                    let item = this.OnlyData['ID'].filter((x) => x.ID_ID == this.Table4['RF_IDID'].val);
                    item = this.IsNullKeys(item, [0]);
                    if (item['Identity'] == '3') {
                        this.FunctionToken(Obj['FunctionCheckFcuAuth'], {
                            TYPE: 'CHECK',
                            NID: '',
                            ID: this._Base64('str', this.Table4['NID_val'].val),
                            JSval: item,
                        });
                    } else {
                        this.FunctionToken(Obj['FunctionCheckFcuAuth'], {
                            TYPE: 'CHECK',
                            NID: this._Base64('str', this.Table4['NID_val'].val),
                            ID: '',
                            JSval: item,
                        });
                    }
                };

                //====================================
                //api
                Obj['FunctionCheckFcuAuth'] = (data_in) => {
                    this.apiCheckFcuAuth(data_in)
                        .then((res) => {
                            let json = JSON.parse(res.data);
                            if (json.Status) {
                                let data1 = JSON.parse(json.Data);
                                if (data1['Identity'] == data_in.JSval['Identity']) {
                                    this.Table4['RF_IDID'].msg = '';
                                    this.Table4.if1 = true;
                                    alert('驗證成功');
                                } else {
                                    alert('驗證失敗');
                                }
                            } else {
                                alert('驗證失敗');
                            }
                        })
                        .catch((err) => {
                            this.Elog(err);
                            alert('驗證失敗');
                        });
                };

                return Obj;
            },
            form1Obj_init() {
                let Obj = {};

                Obj['form1'] = this.form1;

                Obj['Clear'] = () => {
                    for (let i in this.form1.data) {
                        let item = this.form1.data[i];
                        if (i == 'RF_SEX') {
                            item.val = 0;
                        } else {
                            item.val = '';
                        }
                        item.msg = '';
                    }
                };

                Obj['Check'] = (id) => {
                    let msg = '';

                    if (['RF_CITYID', 'RF_TOWNID'].indexOf(id) != -1) {
                        if (this.viewObj['Filter']('RF_ADDRESS') == false) {
                            return msg;
                        }
                    } else {
                        if (this.viewObj['Filter'](id) == false && id != 'RF_MAIL') {
                            return msg;
                        }
                    }

                    if (id == 'RF_NAME') {
                        this.form1.data['RF_NAME'].msg = '';
                        if (this.IsNull(this.form1.data['RF_NAME'].val) == '') {
                            msg += '未填寫學員姓名\n';
                            this.form1.data['RF_NAME'].msg = {
                                type: false,
                                texts: ['未填寫學員姓名'],
                            };
                        }
                    } else if (id == 'RF_SEX') {
                        this.form1.data['RF_SEX'].msg = '';
                        if (this.IsNull(this.form1.data['RF_SEX'].val) == '' && this.form1.data['RF_SEX'].val !== 0) {
                            msg += '未填選性別\n';
                            this.form1.data['RF_SEX'].msg = {
                                type: false,
                                texts: ['未填選性別'],
                            };
                        }
                    } else if (id == 'RF_NID') {
                        this.form1.data['RF_NID'].msg = '';
                        if (this.IsNull(this.form1.data['RF_NID'].val) == '') {
                            msg += '未填寫身份證號\n';
                            this.form1.data['RF_NID'].msg = {
                                type: false,
                                texts: ['未填寫身份證號'],
                            };
                        } else if (this.VerifyModel()['nid'](this.form1.data['RF_NID'].val) == false) {
                            msg += '身分證號格式不符\n';
                            this.form1.data['RF_NID'].msg = {
                                type: false,
                                texts: ['身分證號格式不符'],
                            };
                        }
                    } else if (id == 'RF_PHONE') {
                        this.form1.data['RF_PHONE'].msg = '';
                        if (this.IsNull(this.form1.data['RF_PHONE'].val) == '') {
                            msg += '未填寫行動電話\n';
                            this.form1.data['RF_PHONE'].msg = {
                                type: false,
                                texts: ['未填寫行動電話'],
                            };
                        } else if (this.VerifyModel()['phone'](this.form1.data['RF_PHONE'].val) == false) {
                            msg += '行動電話格式不符\n';
                            this.form1.data['RF_PHONE'].msg = {
                                type: false,
                                texts: ['行動電話格式不符'],
                            };
                        }
                    } else if (id == 'RF_TEL') {
                        this.form1.data['RF_TEL'].msg = '';
                        if (this.IsNull(this.form1.data['RF_TEL'].val) == '') {
                            msg += '未填寫聯絡電話\n';
                            this.form1.data['RF_TEL'].msg = {
                                type: false,
                                texts: ['未填寫聯絡電話'],
                            };
                        } else if (this.VerifyModel()['tel'](this.form1.data['RF_TEL'].val) == false) {
                            msg += '聯絡電話格式不符\n';
                            this.form1.data['RF_TEL'].msg = {
                                type: false,
                                texts: ['聯絡電話格式不符'],
                            };
                        }
                    } else if (id == 'RF_MAIL') {
                        this.form1.data['RF_MAIL'].msg = '';
                        if (this.IsNull(this.form1.data['RF_MAIL'].val) == '') {
                            msg += '未填寫電子信箱\n';
                            this.form1.data['RF_MAIL'].msg = {
                                type: false,
                                texts: ['未填寫電子信箱'],
                            };
                        } else if (this.VerifyModel()['email'](this.form1.data['RF_MAIL'].val) == false) {
                            msg += '電子信箱格式不符\n';
                            this.form1.data['RF_MAIL'].msg = {
                                type: false,
                                texts: ['電子信箱格式不符'],
                            };
                        }
                    } else if (id == 'RF_BDATE') {
                        this.form1.data['RF_BDATE'].msg = '';
                        if (this.IsNull(this.form1.data['RF_BDATE'].val) == '') {
                            msg += '未填寫出生日期\n';
                            this.form1.data['RF_BDATE'].msg = {
                                type: false,
                                texts: ['未填寫出生日期'],
                            };
                        }
                    } else if (id == 'RF_ADDRESS') {
                        this.form1.data['RF_ADDRESS'].msg = '';
                        if (this.IsNull(this.form1.data['RF_ADDRESS'].val) == '') {
                            msg += '未填寫地址\n';
                            this.form1.data['RF_ADDRESS'].msg = {
                                type: false,
                                texts: ['未填寫地址'],
                            };
                        }
                    } else if (id == 'RF_CITYID') {
                        this.form1.data['RF_CITYID'].msg = '';
                        if (this.IsNull(this.form1.data['RF_CITYID'].val) == '') {
                            msg += '未填選縣市\n';
                            this.form1.data['RF_CITYID'].msg = {
                                type: false,
                                texts: ['未填選縣市'],
                            };
                        }
                    } else if (id == 'RF_TOWNID') {
                        this.form1.data['RF_TOWNID'].msg = '';
                        if (this.IsNull(this.form1.data['RF_TOWNID'].val) == '') {
                            msg += '未填選鄉鎮\n';
                            this.form1.data['RF_TOWNID'].msg = {
                                type: false,
                                texts: ['未填選鄉鎮'],
                            };
                        }
                    }
                    return msg;
                };
                return Obj;
            },
            form2Obj_init() {
                let Obj = {};

                Obj['form2'] = this.form2;

                Obj['Clear'] = () => {
                    for (let i in this.form2.data) {
                        let item = this.form2.data[i];
                        if (i == 'RF_SEX') {
                            item.val = 0;
                        } else {
                            item.val = '';
                        }
                        item.msg = '';
                        item.show = this.viewObj['Filter'](i);
                    }
                    this.form2.data2 = '';
                    this.form2.index = '';
                };

                Obj['Show'] = (data, index) => {
                    Obj['Clear']();
                    this.form2.index = index;
                    this.form2.data2 = data;

                    for (let i in this.form2.data) {
                        let item = this.form2.data[i];
                        if (this.viewObj['Filter'](i)) {
                            if (i == 'RF_ADDRESS') {
                                this.form2.data['RF_CITYID'].val = data['RF_CITYID'];
                                this.form2.data['RF_CITYID'].change(this.form2.data['RF_CITYID']);
                                this.form2.data['RF_TOWNID'].val = data['RF_TOWNID'];
                            }

                            if (i == 'RF_SEX') {
                                item.val = this.IsNull(data[i], 0);
                            } else if (i == 'RF_BDATE') {
                                item.val = this.Str_Replace(this.str2date3(data[i]), '/', '-');
                            } else {
                                item.val = this.IsNull(data[i]);
                            }
                        }
                    }
                    this.modal['form2'].show();
                };

                Obj['Check'] = (id, type = 'form2', val) => {
                    if (type == 'TablePage') {
                        if (this.IsNull(this.form2.data[id]) != '') {
                            this.form2.data[id].val = this.IsNull(val);
                            if (id == 'RF_SEX' && val === 0) {
                                this.form2.data[id].val = 0;
                            }
                        }
                    }

                    let msg = '';
                    if (['RF_CITYID', 'RF_TOWNID'].indexOf(id) != -1) {
                        if (this.viewObj['Filter']('RF_ADDRESS') == false) {
                            return msg;
                        }
                    } else {
                        if (this.viewObj['Filter'](id) == false && id != 'RF_MAIL') {
                            return msg;
                        }
                    }

                    if (id == 'RF_NAME') {
                        this.form2.data['RF_NAME'].msg = '';
                        if (this.IsNull(this.form2.data['RF_NAME'].val) == '') {
                            msg += '未填寫學員姓名\n';
                            this.form2.data['RF_NAME'].msg = {
                                type: false,
                                texts: ['未填寫學員姓名'],
                            };
                        }
                    } else if (id == 'RF_SEX') {
                        this.form2.data['RF_SEX'].msg = '';
                        if (this.IsNull(this.form2.data['RF_SEX'].val) == '' && this.form2.data['RF_SEX'].val !== 0) {
                            msg += '未填選性別\n';
                            this.form2.data['RF_SEX'].msg = {
                                type: false,
                                texts: ['未填選性別'],
                            };
                        }
                    } else if (id == 'RF_NID') {
                        this.form2.data['RF_NID'].msg = '';
                        if (this.IsNull(this.form2.data['RF_NID'].val) == '') {
                            msg += '未填寫身份證號\n';
                            this.form2.data[''].msg = {
                                type: false,
                                texts: ['未填寫身份證號'],
                            };
                        } else if (this.VerifyModel()['nid'](this.form2.data['RF_NID'].val) == false) {
                            msg += '身分證號格式不符\n';
                            this.form2.data['RF_NID'].msg = {
                                type: false,
                                texts: ['身分證號格式不符'],
                            };
                        }
                    } else if (id == 'RF_PHONE') {
                        this.form2.data['RF_PHONE'].msg = '';
                        if (this.IsNull(this.form2.data['RF_PHONE'].val) == '') {
                            msg += '未填寫行動電話\n';
                            this.form2.data['RF_PHONE'].msg = {
                                type: false,
                                texts: ['未填寫行動電話'],
                            };
                        } else if (this.VerifyModel()['phone'](this.form2.data['RF_PHONE'].val) == false) {
                            msg += '行動電話格式不符\n';
                            this.form2.data['RF_PHONE'].msg = {
                                type: false,
                                texts: ['行動電話格式不符'],
                            };
                        }
                    } else if (id == 'RF_TEL') {
                        this.form2.data['RF_TEL'].msg = '';
                        if (this.IsNull(this.form2.data['RF_TEL'].val) == '') {
                            msg += '未填寫聯絡電話\n';
                            this.form2.data['RF_TEL'].msg = {
                                type: false,
                                texts: ['未填寫聯絡電話'],
                            };
                        } else if (this.VerifyModel()['tel'](this.form2.data['RF_TEL'].val) == false) {
                            msg += '聯絡電話格式不符\n';
                            this.form2.data['RF_TEL'].msg = {
                                type: false,
                                texts: ['聯絡電話格式不符'],
                            };
                        }
                    } else if (id == 'RF_MAIL') {
                        this.form2.data['RF_MAIL'].msg = '';
                        if (this.IsNull(this.form2.data['RF_MAIL'].val) == '') {
                            msg += '未填寫電子信箱\n';
                            this.form2.data['RF_MAIL'].msg = {
                                type: false,
                                texts: ['未填寫電子信箱'],
                            };
                        } else if (this.VerifyModel()['email'](this.form2.data['RF_MAIL'].val) == false) {
                            msg += '電子信箱格式不符\n';
                            this.form2.data['RF_MAIL'].msg = {
                                type: false,
                                texts: ['電子信箱格式不符'],
                            };
                        }
                    } else if (id == 'RF_BDATE') {
                        this.form2.data['RF_BDATE'].msg = '';
                        if (this.IsNull(this.form2.data['RF_BDATE'].val) == '') {
                            msg += '未填寫出生日期\n';
                            this.form2.data['RF_BDATE'].msg = {
                                type: false,
                                texts: ['未填寫出生日期'],
                            };
                        }
                    } else if (id == 'RF_ADDRESS') {
                        this.form2.data['RF_ADDRESS'].msg = '';
                        if (this.IsNull(this.form2.data['RF_ADDRESS'].val) == '') {
                            msg += '未填寫地址\n';
                            this.form2.data['RF_ADDRESS'].msg = {
                                type: false,
                                texts: ['未填寫地址'],
                            };
                        }
                    } else if (id == 'RF_CITYID') {
                        this.form2.data['RF_CITYID'].msg = '';
                        if (this.IsNull(this.form2.data['RF_CITYID'].val) == '') {
                            msg += '未填選縣市\n';
                            this.form2.data['RF_CITYID'].msg = {
                                type: false,
                                texts: ['未填選縣市'],
                            };
                        }
                    } else if (id == 'RF_TOWNID') {
                        this.form2.data['RF_TOWNID'].msg = '';
                        if (this.IsNull(this.form2.data['RF_TOWNID'].val) == '') {
                            msg += '未填選鄉鎮\n';
                            this.form2.data['RF_TOWNID'].msg = {
                                type: false,
                                texts: ['未填選鄉鎮'],
                            };
                        }
                    }
                    return msg;
                };

                Obj['Save'] = () => {
                    let msg = '';
                    for (let i in this.form2.data) {
                        msg += Obj['Check'](i);
                    }
                    if (msg != '') {
                        this.Clog(msg);
                        alert('請確認報名欄位皆已填寫');
                        return;
                    }

                    for (let i in this.form2.data) {
                        let item = this.form2.data[i];
                        if (this.viewObj['Filter'](i)) {
                            this.form2.data2[i] = item.val;
                        }
                    }
                };

                Obj['Del'] = (data) => {
                    if (this.IsNull(data.RF_ID) == '') {
                        return;
                    }

                    if (confirm('確定要刪除?') == false) {
                        return;
                    }

                    this.FunctionToken(Obj['FunctionDelectRegistration'], { RF_ID: data.RF_ID });
                };

                //===================================================
                //api
                Obj['FunctionDelectRegistration'] = (data_in) => {
                    this.apiDelectRegistration(data_in)
                        .then((res) => {
                            let json = JSON.parse(res.data);
                            if (json.Status) {
                                alert('刪除成功');
                                this.FunctionToken(this.FunctionRegistrationAll, {
                                    RF_GROUP_ID: this.data_in.RF_GROUP_ID,
                                });
                            }
                        })
                        .catch((err) => {
                            this.Elog(err);
                        });
                };

                return Obj;
            },
            form3Obj_init() {
                let Obj = {};

                Obj['form3'] = this.form3;

                return Obj;
            },
            TablePageObj_init() {
                let Obj = {};
                Obj['TablePage'] = this.TablePage;

                return Obj;
            },

            viewObj_init() {
                let Obj = {};

                Obj['Filter'] = (id, type = 'id') => {
                    if (this.IsNull(this.courseData.CA_REGISTRATION_FORM) == '') {
                        return false;
                    }

                    if (type == 'id') {
                        let text = this.typeName[id];
                        if (this.IsNull(text) == '') {
                            return false;
                        } else if (this.courseData.CA_REGISTRATION_FORM.indexOf(text) == -1) {
                            return false;
                        } else {
                            return true;
                        }
                    } else if (type == 'text') {
                        let texts = this.courseData.CA_REGISTRATION_FORM.split(',');
                        if (texts.indexOf(id) == -1) {
                            return false;
                        } else {
                            return true;
                        }
                    }
                };

                Obj['Save'] = (pay = false) => {
                    if (
                        this.RegistrationData.RF_STATUS !== 0 ||
                        (this.PAYData.PAY_STATUS !== 0 && this.PAYData.PAY_STATUS !== 3)
                    ) {
                        return;
                    }

                    if (this.data_in.RF_IS_GROUP == '1') {
                        let msgs = [];
                        this.TablePage.items.forEach((i2, i1) => {
                            let msg = '';
                            for (let j in i2) {
                                msg += this.form2Obj['Check'](j, 'TablePage', i2[j]);
                            }
                            if (msg != '') {
                                msg = `第${Number(i1) + 1}筆學員[${this.IsNull(i2.RF_NAME)}]:\n` + msg;
                                msgs.push(msg);
                            }
                        });

                        if (msgs.length != 0) {
                            alert(msgs.join('===================================\n'));
                            return;
                        }
                    } else {
                        let msg = '';
                        for (let i in this.form1.data) {
                            msg += this.form1Obj['Check'](i);
                        }
                        if (msg != '') {
                            this.Clog(msg);
                            alert('請確認報名欄位皆已填寫');
                            return;
                        }

                        if (this.Table4.type == '2' && this.Table4.if1 == false) {
                            this.Table4['RF_IDID'].msg = { type: false, texts: [] };
                            this.Table4['NID_val'].msg = { type: false, texts: [] };
                            alert('身分優惠，NID未驗證');
                            return;
                        }
                    }

                    let json1 = {};
                    json1.RF_CP_ID = this.data_in.RF_CP_ID;
                    json1.RF_GROUP_ID = this.data_in.RF_GROUP_ID;
                    json1.RF_PAY_ID = this.data_in.RF_PAY_ID;
                    json1.RF_TYPE = this.data_in.RF_TYPE;
                    json1.RF_STATUS = this.data_in.RF_STATUS;
                    json1.RF_ACID = this.data_in.RF_ACID;
                    json1.RF_IS_GROUP = this.data_in.RF_IS_GROUP;

                    json1.RF_PAY_IDENTITY_PROOF = this.data_in.RF_PAY_IDENTITY_PROOF;

                    json1.PAY_TYPE = this.form3.data['PAY_TYPE'].val;
                    json1.PAY_RECEIPT_TITLE = this.form3.data['PAY_RECEIPT_TITLE'].val;
                    json1.PAY_UNICODE_NUMBER = this.form3.data['PAY_UNICODE_NUMBER'].val;

                    json1.PAY_ID = this.IsNull(this.data_in.PAY_ID);

                    json1['RF_IDID'] = this.IsNull(this.Table4['RF_IDID'].val);

                    json1.list = [];

                    if (this.data_in.RF_IS_GROUP == '1') {
                        json1['RF_IDID'] = '';
                        json1['PAY_TYPE'] = '1';

                        this.TablePage.items.forEach((x) => {
                            let json2 = { RF_ID: x['RF_ID'] };
                            for (let i in x) {
                                if (Obj['Filter'](i) == false) {
                                    continue;
                                }
                                if (i == 'RF_ADDRESS') {
                                    json2['RF_CITYID'] = x['RF_CITYID'];
                                    json2['RF_TOWNID'] = x['RF_TOWNID'];
                                }
                                json2[i] = x[i];
                            }
                            json1.list.push(json2);
                        });
                    } else {
                        let json2 = { RF_ID: this.form1.data2['RF_ID'] };
                        for (let i in this.form1.data) {
                            let item = this.form1.data[i];
                            if (Obj['Filter'](i) == false) {
                                continue;
                            }
                            if (i == 'RF_ADDRESS') {
                                json2['RF_CITYID'] = this.form1.data['RF_CITYID'].val;
                                json2['RF_TOWNID'] = this.form1.data['RF_TOWNID'].val;
                            }
                            json2[i] = item.val;
                        }
                        if (this.Table4.type == '2') {
                            json2['RF_NID2'] = this.Table4.nid;
                        }
                        json1.list.push(json2);
                    }

                    if (pay) {
                        json1['JSval'] = { pay };
                    }

                    this.Clog(json1);
                    this.FunctionToken(Obj['FunctionUpdateRegistration'], json1);
                };

                Obj['Del'] = () => {
                    if (
                        this.RegistrationData.RF_STATUS !== 0 ||
                        (this.PAYData.PAY_STATUS !== 0 && this.PAYData.PAY_STATUS !== 3)
                    ) {
                        return;
                    }

                    if (confirm('確定要取消報名?') == false) {
                        return;
                    }

                    this.FunctionToken(Obj['FunctionCancelRegistration'], {
                        RF_CP_ID: this.data_in.RF_CP_ID,
                        RF_GROUP_ID: this.data_in.RF_GROUP_ID,
                        RF_STATUS: '2',
                    });
                };

                Obj['Go'] = () => {
                    this.Clog(this.data_in);
                    if (this.data_in.RF_IS_GROUP == '0' && this.form3.data['PAY_TYPE'].val == '0') {
                        let url = this.SignUp_url + '?RF_ID=';
                        this._go1(url + this.data_in.RF_ID);
                    }
                };

                Obj['PayShow'] = () => {
                    let show = false;
                    if (
                        this.data_in.PAY_STATUS == '0' &&
                        this.data_in.RF_STATUS != '2' &&
                        this.data_in.RF_IS_GROUP == '0' &&
                        this.form3.data['PAY_TYPE'].val == '0'
                    ) {
                        show = true;
                    } else {
                        show = false;
                    }
                    console.log(show);
                    this.btns['pay'] = show;
                };

                //===================================
                //api
                Obj['FunctionUpdateRegistration'] = (data_in) => {
                    this.apiUpdateRegistration(data_in)
                        .then((res) => {
                            let json = JSON.parse(res.data);
                            console.log(json);
                            this.FunctionToken(Obj['FunctionUpdateStudentPayment'], data_in);
                        })
                        .catch((err) => {
                            this.Elog(err);
                            this.FunctionToken(Obj['FunctionUpdateStudentPayment'], data_in);
                        });
                };

                Obj['FunctionUpdateStudentPayment'] = (data_in) => {
                    this.apiUpdateStudentPayment(data_in)
                        .then((res) => {
                            let json = JSON.parse(res.data);
                            if (json.Status) {
                                alert('更新成功');
                                if (this.IsNullKeys(data_in, ['JSval', 'pay']) != '') {
                                    this.viewObj['Go']();
                                } else {
                                    this._go(-1);
                                }
                            }
                        })
                        .catch((err) => {
                            this.Elog(err);
                        });
                };

                Obj['FunctionCancelRegistration'] = (data_in) => {
                    this.apiCancelRegistration(data_in)
                        .then((res) => {
                            let json = JSON.parse(res.data);
                            if (json.Status) {
                                alert('更新成功');
                                this._go(-1);
                            }
                        })
                        .catch((err) => {
                            this.Elog(err);
                        });
                };

                return Obj;
            },
            //=================================================================
            //api
            FunctionRegistrationAll(data_in) {
                this.apiRegistrationAll(data_in)
                    .then((res) => {
                        let json = JSON.parse(res.data);
                        if (json.Status) {
                            let data1 = JSON.parse(json.Data);
                            this.Clog(data1);

                            if (data1.length != 0) {
                                this.RegistrationData = data1[0];
                                if (this.data_in.RF_IS_GROUP == '1') {
                                    this.Table1.data[0].s2 = data1[0].list.length;
                                    if (data1[0].list.length != 0) {
                                        this.TablePage.set(this.TablePage, data1[0].list);
                                        this.Table3.num = this.TablePage.items.length;
                                        this.Table3Obj['Update']();
                                    }
                                } else {
                                    //this.Table1.data[0].s2=1
                                    if (data1[0].list.length != 0) {
                                        let item = data1[0].list[0];
                                        this.form1.data2 = item;
                                        this.form1.data2['RF_IDID'] = this.IsNull(data1[0].RF_IDID);
                                        for (let i in this.form1.data) {
                                            let item2 = this.form1.data[i];
                                            if (i == 'RF_BDATE') {
                                                let date1 = this.Str_Replace(this.str2date3(item[i]), '/', '-');
                                                item2.val = date1;
                                            } else if (i == 'RF_ADDRESS') {
                                                if (this.IsNull(item['RF_CITYID']) != '') {
                                                    this.form1.data['RF_CITYID'].val = item['RF_CITYID'];
                                                    if (this.IsNull(this.townName) != '') {
                                                        let item3 = this.townName.filter(
                                                            (x) => x.TW_CITY_ID == item['RF_CITYID'],
                                                        );
                                                        let options = {};
                                                        for (let j in item3) {
                                                            let item4 = item3[j];
                                                            options[item4.TW_TOWN_ID] = {
                                                                text: this.IsNull(item4.TW_TOWN_NAME),
                                                                val: item4.TW_TOWN_ID,
                                                            };
                                                        }
                                                        this.form1.data['RF_TOWNID'].options = options;
                                                    }
                                                }
                                                this.form1.data['RF_TOWNID'].val = this.IsNull(item['RF_TOWNID']);

                                                item2.val = this.IsNull(item[i]);
                                            } else {
                                                item2.val = this.IsNull(item[i]);
                                            }
                                        }
                                        //this.form1.data['RF_IDID'].val=this.IsNull(data1[0].RF_IDID)

                                        if (this.IsNull(data1[0].RF_IDID) != '') {
                                            this.Table4['RF_IDID'].val = this.IsNull(data1[0].RF_IDID);
                                            this.Table4['RF_IDID'].change(this.Table4['RF_IDID']);
                                            this.Table4['NID_val'].val = this.IsNull(this.form1.data2['RF_NID2']);
                                            this.Table4.nid = this.IsNull(this.form1.data2['RF_NID2']);

                                            let data3 = Object.values(this.identityData);
                                            let item3 = data3.filter((x) => x.IDCA_IDID == data1[0].RF_IDID);
                                            if (item3.length != 0) {
                                                item3 = item3[0];
                                                if (item3.IDCA__ON_SPOT == 1) {
                                                    this.form3.data['PAY_TYPE'].val = 1;
                                                    this.form3.data['PAY_TYPE'].only = true;
                                                    this.form3.data['PAY_TYPE'].options[0].show = false;
                                                    this.viewObj['PayShow']();
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            this.FunctionToken(this.FunctionGetStudentPaymentList, {
                                TYPE: 'BATH',
                                PAY_IDS: [this.data_in.PAY_ID],
                            });
                        }
                    })
                    .catch((err) => {
                        this.Elog(err);
                    });
            },

            FunctionGetStudentPaymentList(data_in) {
                this.apiGetStudentPaymentList(data_in)
                    .then((res) => {
                        let json = JSON.parse(res.data);
                        if (json.Status) {
                            let data1 = JSON.parse(json.Data);
                            if (data1.length != 0) {
                                this.PAYData = data1[0];
                                this.Table1.sum = Number(this.IsNull(this.PAYData['PAY_AMOUNT']));
                                this.Table1.data[0].s3 = this.Table1.sum / this.Table1.data[0].s2;
                            }
                        }
                    })
                    .catch((err) => {
                        this.Elog(err);
                    });
            },

            FunctionGetCourseFeriodSingleByIndex(data_in) {
                console.log(data_in);
                this.apiGetCourseFeriodSingleByIndex(data_in)
                    .then((res) => {
                        let json = JSON.parse(res.data);
                        if (json.Status) {
                            let data1 = JSON.parse(json.Data);
                            this.Clog(data1);
                            if (this.IsNull(data1) == '') {
                                this._go('/');
                            }
                            this.CourseIf2(data1);
                            this.courseData = data1;
                            if (this.IsNull(this.courseData['CA_REGISTRATION_FORM']) != '') {
                                for (let i in this.form1.data) {
                                    let item = this.form1.data[i];
                                    item.show = this.viewObj['Filter'](i);
                                }

                                for (let i in this.form2.data) {
                                    let item = this.form2.data[i];
                                    item.show = this.viewObj['Filter'](i);
                                }

                                for (let i in this.TablePage.fields) {
                                    let item = this.TablePage.fields[i];
                                    if (this.viewObj['Filter'](item.key)) {
                                        item.thClass = '';
                                        item.tdClass = '';
                                    }
                                }

                                if (this.IsNull(this.courseData['IDENTITY_DISCOUNT_COURSE_ADMISSIONS']) != '') {
                                    let options = {};
                                    for (let i in this.courseData['IDENTITY_DISCOUNT_COURSE_ADMISSIONS']) {
                                        let item = this.courseData['IDENTITY_DISCOUNT_COURSE_ADMISSIONS'][i];
                                        this.identityData[item.IDCA_ID] = item;
                                        this.Table4.data[item.IDCA_IDID] = item;
                                        if (item.IDCA__ON_SPOT == 1) {
                                            options[item.IDCA_IDID] = {
                                                text: this.IsNull(item.ID_NAME) + ' [臨櫃驗證]',
                                                val: item.IDCA_IDID,
                                            };
                                        } else if (
                                            this.OnlyData['ID'].findIndex((x) => x.ID_ID == item.IDCA_IDID) != -1
                                        ) {
                                            options[item.IDCA_IDID] = {
                                                text: this.IsNull(item.ID_NAME) + ' [NID驗證]',
                                                val: item.IDCA_IDID,
                                            };
                                        } else {
                                            options[item.IDCA_IDID] = {
                                                text: this.IsNull(item.ID_NAME),
                                                val: item.IDCA_IDID,
                                            };
                                        }
                                    }
                                    this.Table4['RF_IDID'].options = options;
                                    //this.Table4['RF_IDID'].change(this.Table4['RF_IDID'])
                                }

                                this.Table2.course = this.IsNull(this.courseData['CS_TITLE']);
                                this.Table2.price = this.IsNull2(this.courseData['CA_TUITION']);
                                if (
                                    this.courseData['CA_IS_EARLY'] &&
                                    this.courseData['courseStatus'].indexOf('早鳥優惠') != -1
                                ) {
                                    this.Table2.off.val = this.IsNull2(this.courseData['CA_EARLY_TUITION']);
                                    this.Table2.off.show = true;
                                }
                                this.Table2Obj['Update']();

                                this.Table3.course = this.IsNull(this.courseData['CS_TITLE']);
                                this.Table3.price = this.IsNull2(this.courseData['CA_TUITION']);
                                if (this.courseData['CA_IS_GROUP']) {
                                    this.Table3.off.val = this.IsNull2(this.courseData['CA_GROUP_TUITION']);
                                }

                                this.FunctionToken(this.FunctionRegistrationAll, {
                                    RF_GROUP_ID: this.data_in.RF_GROUP_ID,
                                });
                            }

                            if (
                                this.courseData['courseStatus'].indexOf('早鳥優惠') != -1 &&
                                this.courseData['CA_EARLY_SHARED'] != 1
                            ) {
                                console.log('未啟用優惠共用');
                                this.Table4.show = false;
                            }
                        }
                    })
                    .catch((err) => {
                        this.Elog(err);
                    });
            },
            FunctionSelectCity(data_in) {
                this.apiSelectCity(data_in).then((res) => {
                    let json = JSON.parse(res.data);
                    if (json.Status) {
                        let data1 = JSON.parse(json.Data);
                        this.Clog(data1);
                        let options = {};
                        for (let i in data1) {
                            let item = data1[i];
                            this.cityName[item.CT_CITY_ID] = item;
                            options[item.CT_CITY_ID] = {
                                text: this.IsNull(item.CT_NAME),
                                val: item.CT_CITY_ID,
                            };
                        }
                        this.form1.data['RF_CITYID'].options = options;
                        this.form2.data['RF_CITYID'].options = options;
                    }
                });
            },
            FunctionSelectTown(data_in) {
                this.apiSelectTown(data_in)
                    .then((res) => {
                        let json = JSON.parse(res.data);
                        if (json.Status) {
                            let data1 = JSON.parse(json.Data);
                            this.Clog(data1);
                            this.townName = data1;
                            if (this.form1.data['RF_CITYID'].val != '') {
                                let data1 = this.townName.filter(
                                    (x) => x.TW_CITY_ID == this.form1.data['RF_CITYID'].val,
                                );
                                let options = {};
                                for (let i in data1) {
                                    let item = data1[i];
                                    options[item.TW_TOWN_ID] = {
                                        text: this.IsNull(item.TW_TOWN_NAME),
                                        val: item.TW_TOWN_ID,
                                    };
                                }
                                this.form1.data['RF_TOWNID'].options = options;
                            }
                        }
                    })
                    .catch((err) => {
                        this.Elog(err);
                    });
            },
        },
    };
</script>
<style lang="scss" scoped>
    .test {
        min-height: 100vh;
    }
    .payment {
        border-top: 1px solid #ccc;
        .item {
            border-bottom: 1px solid #ccc;
            padding-top: 1rem;
            padding-bottom: 1rem;
        }
    }
</style>
